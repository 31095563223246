import React, { useState } from "react";
import UserAccount from "../cms/UserAccount";
import InfluencerAccount from "../cms/InfluencerAccount";
import PaymentAccount from "../cms/PaymentAccount";

const InfluencerSetting = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState("PaymentAccount");

  // Handlers for menu, image, and form submission
  const handleMenuClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
  };

  return (
    <div className="advertiser-setting-container">
      {/* First Container: Settings Menu */}
      <div className="setting-menu-container bg-white p-4 rounded-3">
        <div
          className={`rounded-3 mb-2 setting-menu-item ${
            selectedMenuItem === "PaymentAccount" ? "active" : ""
          }`}
          onClick={() => handleMenuClick("PaymentAccount")}
        >
          <i className="icon-Influencer"></i>
          <div>
            <h5>Payment Account</h5>
            <p>Manage Payment Account settings</p>
          </div>
        </div>
        <div
          className={`rounded-3 mb-2 setting-menu-item ${
            selectedMenuItem === "UserAccount" ? "active" : ""
          }`}
          onClick={() => handleMenuClick("UserAccount")}
        >
          <i className="icon-user"></i>
          <div>
            <h5>User Account</h5>
            <p>Manage your personal information</p>
          </div>
        </div>

        <div
          className={`rounded-3 mb-2 setting-menu-item ${
            selectedMenuItem === "InfluencerAccount" ? "active" : ""
          }`}
          onClick={() => handleMenuClick("InfluencerAccount")}
        >
          <i className="icon-Influencer"></i>
          <div>
            <h5>Influencer Account</h5>
            <p>Manage Influencer Additional settings</p>
          </div>
        </div>

        

        {/* <div
          className={`rounded-3 mb-2 setting-menu-item ${
            selectedMenuItem === "Security" ? "active" : ""
          }`}
          onClick={() => handleMenuClick("Security")}
        >
          <i className="icon-security"></i>
          <div>
            <h5>Security</h5>
            <p>Adjust your security preferences</p>
          </div>
        </div> */}

        <div
          className={`rounded-3 mb-2 setting-menu-item ${
            selectedMenuItem === "PrivacyPolicy" ? "active" : ""
          }`}
          onClick={() => handleMenuClick("PrivacyPolicy")}
        >
          <i className="icon-privacy"></i>
          <div>
            <h5>Privacy Policy</h5>
            <p>View privacy policy details</p>
          </div>
        </div>
      </div>

      {/* Second Container: Content changes based on MenuItem */}
      <div className="setting-content-container bg-white p-4 rounded-3">
        {/* UserAccount Section */}
        {selectedMenuItem === "UserAccount" && <UserAccount />}

        {/* InfluencerAccount Section */}
        {selectedMenuItem === "InfluencerAccount" && <InfluencerAccount />}

        {/* PaymentAccount Section */}
        {selectedMenuItem === "PaymentAccount" && <PaymentAccount />}

        {/* Security Section */}
        {selectedMenuItem === "Security" && (
          <div className="security-section">
            <h2 className="text-title fw-bold">
              <strong>Security</strong>
            </h2>
            <p>Update your password from here</p>
            <form className="user-info-form my-4">
              <div className="form-group w-500px-inputField">
                <label>Old Password</label>
                <input
                  className="p-3"
                  type="password"
                  defaultValue="dummyOldPassword"
                />
              </div>
              <div className="form-group w-500px-inputField">
                <label>New Password</label>
                <input
                  className="p-3"
                  type="password"
                  defaultValue="dummyNewPassword"
                />
              </div>
              <div className="form-group w-500px-inputField">
                <label>Confirm Password</label>
                <input
                  className="p-3"
                  type="password"
                  defaultValue="dummyConfirmPassword"
                />
              </div>
              <button type="button" className="btn btn-primary">
                Change Password
              </button>
            </form>
          </div>
        )}

        {/* Privacy Policy Section */}
        {selectedMenuItem === "PrivacyPolicy" && (
          <div className="privacy-policy-section">
            <h2 className="text-title fw-bold">Privacy</h2>
            <div className="my-4">
              <div className="privacy-item">
                <h5 className="text-title fw-bold">
                  Two-factor Authentication
                </h5>
                <p>
                  We use your data for better services. Lorem ipsum dolor sit
                  amet consectetur, adipisicing elit. Fugit voluptatem porro
                  odit? Illum tempore dolor inventore fugiat alias mollitia
                  soluta ipsam modi. Repellendus dolorem repudiandae minus quas.
                  Alias, placeat nisi.
                </p>
              </div>
              <div className="privacy-item">
                <h5 className="text-title fw-bold">Secondary Verification</h5>
                <p>
                  Cookies help us personalize content and ads. Lorem ipsum dolor
                  sit amet consectetur adipisicing elit. Commodi eveniet
                  consequatur perspiciatis labore odio officia quisquam aliquid
                  autem! Exercitationem commodi quidem doloremque fuga,
                  necessitatibus suscipit enim et voluptas beatae temporibus.
                </p>
              </div>
              <div className="privacy-item">
                <h5 className="text-title fw-bold">Backup Codes</h5>
                <p>
                  Your data may be shared with trusted partners. Lorem ipsum
                  dolor sit amet consectetur adipisicing elit. Cumque,
                  doloremque facere? Dolorum atque quas eligendi ducimus
                  sapiente aliquam aliquid quos, eveniet nihil inventore at ab
                  quidem perspiciatis iusto odit minus.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InfluencerSetting;
