// ServiceCard.js
import React, { useState } from "react";
import { BiSolidCategoryAlt } from "react-icons/bi";
import { GiDuration } from "react-icons/gi";
import {
  SocialIcons,
  SocialPlatformColors,
  PackageTypeImage,
} from "../../../utils/Socials";
import useAddToCartItem from "../../../hooks/data-hooks/advertiser/cart/useAddToCartItem";



const ServiceCard = ({ data, influencerId }) => {

  const [isExpanded, setIsExpanded] = useState(false);

  const backgroundClass =
    SocialPlatformColors[data?.socialPlatformName?.toLowerCase()] ||
    "bg-default";
  const image = PackageTypeImage[data?.typeOfPackage]?.toLowerCase() || "";
  const icon = SocialIcons[data?.socialPlatformName?.toLowerCase()] || "";

  const toggleDescription = () => setIsExpanded(!isExpanded);

  const descriptionPreview = data?.description.slice(0, 50);
  const isLongDescription = data?.description.length > 100;

  const { addToCartItems, isLoading, isAddingToCart } = useAddToCartItem(); // Use the hook

  const packageId = data?._id;

  const handleAddToCart = () => {
    addToCartItems({ influencerId, packageId }); 
  };

  return (
    <div
      className={`border rounded shadow-sm d-flex flex-column justify-content-between h-100 p-2 ${backgroundClass} w-100`}
    >
      {/* Card Content Wrapper */}
      <div className="d-flex flex-column flex-grow-1 justify-content-between bg-secondary-color rounded p-2">
        {/* Top Section */}
        <div>
          {/* Image */}
          <div className="service-image-container1">
            <img
              src={image}
              alt={data?.typeOfPackage}
              className="img-fluid object-fit-cover"
            />
          </div>

          {/* Title */}
          <h6 className="mt-3 fw-bold ">{data?.packageName}</h6>

          {/* Description with 'See More'/'See Less' */}
          <p className="flex-grow-1">
            {isExpanded ? data?.description : descriptionPreview}{" "}
            {isLongDescription && (
              <button
                className="btn btn-link p-0 link-style"
                onClick={toggleDescription}
              >
                {isExpanded ? "See Less" : "More..."}
              </button>
            )}
          </p>
        </div>

        {/* Spacer to push the content below */}
        <div className="flex-grow-1"></div>

        {/* Bottom Section - Price and Book Now */}
        <div className="mt-3">
          <div className="d-flex align-items-center gap-2">
            <span>{icon}</span>
            <span className="fw-medium">
              <BiSolidCategoryAlt /> {data?.typeOfPackage}
            </span>
            <span className="fw-medium">
              <GiDuration /> {data?.duration} Days
            </span>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-3">
            <span className="fw-medium fs-6">${data?.price}</span>
            <button
              onClick={handleAddToCart}
              className="btn bg-primary-color text-white"
              disabled={isAddingToCart} // Disable button while adding to cart
            >
              {isAddingToCart ? "Adding..." : "Add to Cart"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
