import React from "react";
import TransactionTable from "../transaction/TransactionTable";
import useGetTransactionList from "../../../../hooks/data-hooks/influencer/transaction/useGetTransactionList";
import { useEffect, useState } from "react";
import TransactionHistory from "../transaction/TransactionHistory";
import { Helmet } from "react-helmet";
import { influencerPageNames } from "../../../../routes/all-routes/influencerRoutes";

const Transaction = () => {
  const [selectedLab, setSelectedLab] = useState("");
  const [pageSize, setPageSize] = useState(10); // items per page
  const [currentPage, setCurrentPage] = useState(1);

  // Fetch transaction list with params
  const {
    fetchTransactionListWithParams,
    isLoadingTransactionList,
    setIsLoadingTransactionList,
    isErrorTransactionList,
    transactionList,
    transactionListMeta,
    transactionHistory,
    transactionListError,
    totalRows,
    setTotalRows,
  } = useGetTransactionList({
    pageNumber: currentPage,
    pageSize: pageSize,
    sortField: "createdAt",
    sortDirection: "desc",
  });

  // Trigger API call with labUserId
  useEffect(() => {
    fetchTransactionListWithParams({
      pageNumber: currentPage,
      pageSize: pageSize,
      sortField: "createdAt",
      sortDirection: "desc",
    });
  }, [pageSize, currentPage]);



  const handlePageChange = async (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPageSize(newPerPage);
    setCurrentPage(page);
  };




  return (
    <>
      <Helmet>
        <title>
          {influencerPageNames.TRANSACTION} | Influencer |{" "}
          {process.env.REACT_APP_NAME}
        </title>
      </Helmet>

      <div className="container">
        {/* transaction card */}
        <div className="transaction-top">
          <div className="py-3">
            <h5 className="text-black fs-4">Transaction Overview</h5>
          </div>
          <div className=" ">
            <TransactionHistory data={transactionHistory} />
          </div>
        </div>

        {/* transaction table */}
        <div className="transaction-bottom mt-5">
          <h4>Recent Transactions</h4>
          <TransactionTable
            data={transactionList}
            meta={transactionListMeta}
            isLoading={isLoadingTransactionList}
            setIsLoading={setIsLoadingTransactionList}
            error={transactionListError}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            fetchTransactionListWithParams={fetchTransactionListWithParams}
            pageSize={pageSize}
            setPageSize={setPageSize}
            selectedLab={selectedLab}
            totalRows={totalRows}
            setTotalRows={setTotalRows}
            handlePageChange={handlePageChange}
            handlePerRowsChange={handlePerRowsChange}
          />
        </div>
      </div>
    </>
  );
};
export default Transaction;


