import { MessageIcon, NotificationIcon } from "../../../../utils/MyIcons";
import influencerPageName from "../../../../hooks/ui-hooks/influencerPageName";
import { Link } from "react-router-dom";
import InfluencerPrefixedRoutes from "../../../../routes/all-routes/influencerRoutes";
import useGetPaymentAccount from "../../../../hooks/data-hooks/influencer/cms/useGetPaymentAccount";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useState } from "react";

const InfluencerTopBar = () => {
  const currentPageName = influencerPageName("influencer");
  const token = useSelector((state) => state.auth.user.accessToken);
  const { data1, isLoading1, isError1 } = useGetPaymentAccount();
  const [isVerifying, setIsVerifying] = useState(false); // Track verification progress

  const handleVerify = async () => {
    setIsVerifying(true); // Set verifying to true

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/influencer/payment-account/regenerate-active-account-url`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const text = await response.json();
        toast.error(text.message);
        return;
      }

      const data = await response.json();
      toast.success("Account verify request sent successfully.");

      const redirectUrl = data?.data;

      if (redirectUrl) {
        // Open the URL in a new tab
        window.open(redirectUrl, "_blank");
      }
    } catch (error) {
      toast.error("Verification failed, please try again.");
    } finally {
      setIsVerifying(false); // Reset verification state after request completes
    }
  };

  return (
    <div className="bg-white w-100 sticky-top top-0 ">
      {data1?.data?.stripeVerify === false ? (
        <div className="bg-overlay d-flex justify-content-center align-items-center">
          <button
            className="btn bg-primary-color text-white mb-4 p-4"
            onClick={handleVerify}
            disabled={isVerifying} // Disable when verifying
          >
            {isVerifying ? "Requesting..." : "Verify your account"}
          </button>
        </div>
      ) : null}

      <div className="container Influencer-topbar pt-4 pb-4 d-flex justify-content-between align-items-center">
        <span className="fs-1 fw-900 text-title ">{currentPageName}</span>
        <div className="d-flex align-items-center gap-2 ">
          <Link to={InfluencerPrefixedRoutes.MESSAGE}>
            <button className="d-flex align-items-center justify-content-center w-h-44px rounded-circle border bg-transparent">
              {/* <MessageIcon /> */}
              <NotificationIcon />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default InfluencerTopBar;
