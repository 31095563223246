import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import Button from "../../../web/components/button/Button";
import { Link, useNavigate } from "react-router-dom";
import useGetPackageList from "../../../../hooks/data-hooks/influencer/package/useGetPackageList";
import Loader from "../../../common/components/loader/Loader";
import { Helmet } from "react-helmet";
import { MdDeleteForever } from "react-icons/md";
import influencerPrefixedRoutes, { influencerPageNames } from "../../../../routes/all-routes/influencerRoutes";

const Packages = () => {
  // const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const {
    fetchPackage,
    packages,
    isLoading,
    updatePackageStatus,
    deletePackage,
    packageError,
    fetchPackageList,
    totalRows,
    setTotalRows,
  } = useGetPackageList(perPage, currentPage);

  useEffect(() => {
    fetchPackage(perPage, currentPage);
  }, [perPage, currentPage]);

  console.log(packages);

  const columns = [
    // #NO
    {
      name: "#No",
      selector: (row, index) =>
        (packages?.meta?.pagination?.current_page - 1) * perPage + index + 1,
      sortable: true,
    },

    {
      name: "Package Name",
      selector: (row) => (
        <Link
          to={`${influencerPrefixedRoutes.UPDATE_PACKAGE}/${row._id}`}
          className="link-style"
        >
          {row?.packageName}
        </Link>
      ),
      sortable: true,
    },
    {
      name: "Platform",
      selector: (row) => row?.socialPlatformName,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row?.typeOfPackage,
      sortable: true,
    },
    {
      name: "Duration",
      selector: (row) => row?.duration,
      sortable: true,
    },

    {
      name: "Price",
      selector: (row) => row?.price,
      sortable: true,
    },

    {
      name: "State",
      selector: (row) => (row?.isEnable === true ? "Active" : "Inactive"),
      sortable: true,
    },

    {
      name: "Action",
      cell: (row) => (
        <div className="form-switch">
          <input
            className="form-check-input cursor-pointer"
            type="checkbox"
            role="switch"
            checked={row?.isEnable === true}
            onChange={() => {
              // console.log(row._id);
              updatePackageStatus(row._id);
            }}
          />
        </div>
      ),
    },
    {
      name: "Delete",
      cell: (row) => (
        <span onClick={() => deletePackage(row._id)} className="cursor-pointer">
          <MdDeleteForever color={`var(--primary-color)`} size={20} />
        </span>
      ),
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // fetchcategory(perPage, page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    // setCurrentPage(page);
    // fetchcategory(newPerPage, page);
  };

  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "var(--secondary-color)",
        color: "#000",
      },
    },
  };

  //   const headerComponent = React.useMemo(() => {
  //     return (
  //       <div>
  //         Category List
  //         {/* <Button
  //           label={"Add New Category"}
  //           className="ms-3"
  //           onClick={() => navigate(adminPrefixedRoutes.CREATE_DISPUTES)}
  //         /> */}
  //       </div>
  //     );
  //   }, []);

  if (isLoading) return <Loader />;

  return (
    <>
      <Helmet>
        <title>
          {influencerPageNames.PACKAGES} | Influencer |{" "}
          {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <div className="container">
        <div className="my-4">
          <span className="fw-bold text-title fs-5">Package List</span>
          <Button
            label={"Add New Package"}
            className="ms-3"
            onClick={() => navigate(influencerPrefixedRoutes.CREATE_PACKAGE)}
          />
        </div>
        <div className="shadow   ">
          <DataTable
            // title={headerComponent}
            customStyles={tableHeaderstyle}
            columns={columns}
            data={packages?.data}
            progressPending={isLoading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            fixedHeader
            highlightOnHover
          />
        </div>
      </div>
    </>
  );
};
export default Packages;
