// useGetFooterSection.js
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { commonRoutes } from "../../../../../routes/all-routes/commonRoutes";
import { useSelector } from "react-redux";
import authPrefixedRoutes from "../../../../../routes/all-routes/authRoutes";

const useGetFooterSection = () => {
  const navigate = useNavigate(); // React Router v6's navigate function
  const token = useSelector((state) => state.auth.user.accessToken);
  const [footerContent, setFooterContent] = useState();
  const [footerPlaceholder, setFooterPlaceHolder] = useState();

  const { isLoading, isError, data } = useQuery({
    queryKey: ["footerSection"],
    queryFn: async () => {
      //  dispatch(setLoading(true));
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/admin/website-content/common-page/footer-link-section`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        const text = await response.json();
        if (text?.error?.status === 401) {
          navigate(authPrefixedRoutes.LOGIN);
        }
        navigate(commonRoutes.SERVER_ERROR); // Navigate to error500 page
        return;
      }
      const data = await response.json();
      setFooterContent(data?.data?.content);
      setFooterPlaceHolder(data?.data?.placeholder);
      return data; // Return the fetched data
    },
  });

  const handleUpdateFooterContent = () => setFooterContent(data?.data?.content);

  const handleRemovePair = (sectionIndex, labelIndex, label) => {
    setFooterContent({
      ...footerContent,
      [sectionIndex]: {
        ...footerContent[sectionIndex],
        links: footerContent[sectionIndex].links.filter(
          (item, index) => index !== labelIndex && item.label !== label
        ),
      },
    });
  };

  return {
    isLoading1: isLoading,
    isError1: isError,
    footerContent,
    footerPlaceholder,
    handleRemovePair,
    data,
    handleUpdateFooterContent,
  };
};

export default useGetFooterSection;
