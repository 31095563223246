import { useState, useEffect, useRef } from "react";
import useOrder from "../../../../hooks/data-hooks/admin/order/useOrder";
import Loader from "../../../common/components/loader/Loader";
import { debounce } from "lodash";
import { SearchIcon } from "../../../../utils/MyIcons";
import MessageItem from "./MessageItem";
import MessageBodyContent from "./MessageBodyContent";
import { useParams } from "react-router";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";

const Message = () => {
  const { id: orderId } = useParams();
  const [selectedOrder, setSelectedOrder] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [displayedMessages, setDisplayedMessages] = useState([]);
  const [ScrollToBottom, setScrollToBottom] = useState(true);
  const [nextMessageSize, setNextMessageSize] = useState(0);
  const [displayedInboxList, setDisplayedInboxList] = useState([]);
  const [isLoadedOnce, setIsLoadedOnce] = useState(false);
  const currentPage = 1;
  const pageSize = 0;
  const chatSize = 10; // items per chat
  const indexSize = 10; // items per index (set to minimum 10 for scroll to work)

  const chatContainerRef = useRef(null);
  const inboxContainerRef = useRef(null);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  // Fetch order list with params
  const {
    fetchChatMessage,
    orderList,
    isLoading,
    chatList,
    isChatLoading,
    // fetchOrder,
    fetchOrderListWithParams,
  } = useOrder(orderId, {
    pageNumber: currentPage,
    pageSize: pageSize,
    userName: searchTerm,
    // clinicName: searchTerm,
    sortField: "createdAt",
    sortDirection: "asc",
  });

  // Trigger API call with search term , when search term  changes
  useEffect(() => {
    fetchOrderListWithParams({
      pageNumber: currentPage,
      pageSize: pageSize,
      labName: searchTerm,
      // clinicName: searchTerm,
      sortField: "createdAt",
      sortDirection: "asc",
    });
  }, [searchTerm]);

  // Trigger API call with order id when order id changes
  useEffect(() => {
    fetchChatMessage();
  }, [orderId]);

  // Add message to selected order
  const handleAddMessage = (message) => {
    setIsLoadedOnce(true);
    setSelectedOrder(message);
    setScrollToBottom(true);
    setNextMessageSize(0);
  };

  // -----------------------------
  // Message Inbox start
  // Initially Display the latest inbox
  useEffect(() => {
    if (orderList && orderList?.length > 0) {
      const endIndex = Math.min(orderList.length, indexSize);
      const latestIndexList = orderList.slice(0, endIndex);
      setDisplayedInboxList(latestIndexList);
    }
  }, [orderList]);

  // Load more inbox function
  const handleLoadMoreInbox = () => {
    if (displayedInboxList?.length < orderList?.length) {
      const nextBatchStartIndex = displayedInboxList.length;
      const nextBatchEndIndex = Math.min(
        nextBatchStartIndex + indexSize,
        orderList.length
      );

      const nextInbox = orderList.slice(nextBatchStartIndex, nextBatchEndIndex);
      const hasMoreInbox = nextInbox.length > 0;
      if (hasMoreInbox) {
        setDisplayedInboxList((prevInbox) => [...prevInbox, ...nextInbox]);
      }
    }
  };

  // Scroll handler for Inbox
  const handleInboxScroll = () => {
    if (inboxContainerRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        inboxContainerRef?.current;
      if (scrollTop + clientHeight === scrollHeight) {
        handleLoadMoreInbox();
      }
    }
  };

  // end of Message Inbox
  // -----------------------------

  // ------------------------------
  // Chat Message Start
  // Initially Display the latest messages
  useEffect(() => {
    if (chatList && chatList?.length > 0) {
      const startIndex = Math.max(chatList.length - chatSize, 0);
      const latestMessages = chatList.slice(startIndex);
      setDisplayedMessages(latestMessages);
    }
  }, [chatList]);

  // keeping the chat scroll to the last seen Messages
  useEffect(() => {
    if (chatContainerRef?.current) {
      chatContainerRef.current.scrollTop = nextMessageSize * 140;
    }
  }, [displayedMessages]);

  // Scroll to the bottom of the chat container on load
  useEffect(() => {
    if (chatContainerRef.current && ScrollToBottom) {
      chatContainerRef.current.scrollTop =
        chatContainerRef?.current?.scrollHeight;
    }
  }, [displayedMessages]);

  // Load more messages function
  const handleLoadMore = () => {
    setScrollToBottom(false);
    const nextBatchStartIndex = Math.max(
      chatList.length - displayedMessages.length - chatSize,
      0
    );
    let nextBatchEndIndex = nextBatchStartIndex + chatSize;
    if (nextBatchStartIndex === 0) {
      nextBatchEndIndex = chatList.length - displayedMessages.length;
    }
    const nextMessagesLength = nextBatchEndIndex - nextBatchStartIndex;
    setNextMessageSize(nextMessagesLength);
    const nextMessages = chatList.slice(nextBatchStartIndex, nextBatchEndIndex);
    const hasMoreMessages = nextMessages.length > 0;
    if (hasMoreMessages) {
      setDisplayedMessages((prevMessages) => [
        ...nextMessages,
        ...prevMessages,
      ]);

    }
  };

  // Scroll handler
  const handleScroll = () => {
    if (chatContainerRef.current) {
      if (chatContainerRef.current.scrollTop === 0) {
        handleLoadMore();
      }
    }
  };

  // end of Chat Message
  // -----------------------------

  //  Form submit handler for search
  const onSubmit = (data) => {
    if (isSubmitting) {
      return;
    }

    // Trigger API call with search term and filter status
    const { searchTerm } = data;
    setSearchTerm(searchTerm);
    // setCurrentPage(1); // Reset page to 1 when searching
  };

  // Debounced form submission for input change
  const debouncedSubmit = debounce(onSubmit, 1000);

  if (isLoading && !isLoadedOnce) return <Loader />;

  return (
    <div className="container">
      <Helmet>
        <title>Message | Admin | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <div className=" rounded shadow bg-secondary-color">
        <div className="row g-1 ">
          {/* Message Inbox */}
          <div
            className=" col-6 col-lg-4   "
            onClick={() => {
              setIsLoadedOnce(true);
            }}
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="bg-primary-color p-2"
            >
              {/* table search */}
              <div className=" search border border-1 rounded-3 d-flex align-items-center bg-white  ">
                <span className="ps-1">
                  <SearchIcon />
                </span>
                <input
                  type="text"
                  className="rounded-3 p-2 w-100  border-0 bg-transparent  "
                  placeholder="Search By Influencer Name"
                  aria-label="Search"
                  name="searchTerm"
                  {...register("searchTerm")}
                  onChange={(event) =>
                    debouncedSubmit({
                      searchTerm: event.target.value,
                    })
                  }
                />
              </div>
            </form>
            {isLoading ? (
              <Loader />
            ) : (
              <div
                ref={inboxContainerRef}
                onScroll={handleInboxScroll}
                style={{ height: "calc(100vh - 270px)", overflowY: "auto" }}
                className="custom-scroll"
              >
                {displayedInboxList?.map((messageItem) => (
                  <MessageItem
                    key={messageItem?._id}
                    messageItem={messageItem}
                    handleAddMessage={handleAddMessage}
                    order={selectedOrder}
                  />
                ))}
              </div>
            )}
          </div>

          {/* Message Chat */}
          <div className="col-6 col-lg-8 border-start ">
            <div className="d-flex flex-column w-100 ">
              <h2 className="text-center bg-primary-color text-white py-2 ">
                Chat
              </h2>
              {isChatLoading ? (
                "Loading..."
              ) : chatList?.length === 0 ? (
                <div className="w-100 h-100 d-flex align-items-center justify-content-center ">
                  No Chat
                </div>
              ) : (
                <div
                  className="px-2 custom-scroll"
                  ref={chatContainerRef}
                  onScroll={handleScroll}
                  style={{ height: "calc(100vh - 270px)", overflowY: "auto" }}
                >
                  {displayedMessages?.map((message, index) => (
                    <MessageBodyContent
                      key={message?._id}
                      message={message}
                      previousMessage={chatList[index - 1]}
                      currentIndex={index}
                      count={chatList?.length}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Message;
