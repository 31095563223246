// PaymentAccount.js
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loader from "../../../common/components/loader/Loader";
import useGetPaymentAccount from "../../../../hooks/data-hooks/influencer/cms/useGetPaymentAccount";
import { getFormattedDate } from "../../../../helpers/getFormattedDate";
import { useSelector } from "react-redux";

const PaymentAccount = () => {
  const token = useSelector((state) => state.auth.user.accessToken);
  const { data1, isLoading1, isError1 } = useGetPaymentAccount();
  const [isVerifying, setIsVerifying] = useState(false); // Track verification progress

  useEffect(() => {
    if (isError1) {
      toast.error(isError1);
    }
  }, [isError1]);

  const handleVerify = async () => {
    setIsVerifying(true); // Set verifying to true

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/influencer/payment-account/regenerate-active-account-url`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const text = await response.json();
        toast.error(text.message);
        return;
      }

      const data = await response.json();
      toast.success("Account verify request sent successfully.");

      const redirectUrl = data?.data;

      if (redirectUrl) {
        // Open the URL in a new tab
        window.open(redirectUrl);
      }
    } catch (error) {
      toast.error("Verification failed, please try again.");
    } finally {
      setIsVerifying(false); // Reset verification state after request completes
    }
  };

  if (isLoading1) return <Loader />;
  return (
    <div className="cms-section ">
      <div className="cms-card  ">
        <div className="fs-18px fw-bold ">Payment Account Information</div>

        <div className="cms-form cms-content custom-scroll row align-items-start ">
          <div className="col-12 col-lg-6">
            <div className="form-item">
              <label className="">Stripe Email</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  value={data1?.data?.stripeEmail}
                  readOnly
                />
              </div>
            </div>
            <div className="form-item">
              <label className="">Stripe Account Number</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  value={data1?.data?.stripeAccountNumber}
                  readOnly
                />
              </div>
            </div>
            <div className="form-item">
              <label className="">Card Payments Status</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  value={data1?.data?.cardPayments}
                  readOnly
                />
              </div>
            </div>
            <div className="form-item">
              <label className="">Stripe Verify Status</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  value={
                    data1?.data?.stripeVerify ? "Verified" : "Not Verified"
                  }
                  readOnly
                />
              </div>
            </div>
            {data1?.data?.stripeVerify === false ? (
              <button
                className="btn bg-primary-color text-white mb-4 "
                onClick={handleVerify}
                disabled={isVerifying} // Disable when verifying
              >
                {isVerifying ? "Requesting..." : "Verify"}
              </button>
            ) : null}
          </div>

          <div className="col-12 col-lg-6">
            <div className="form-item">
              <label className="">Created At</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  value={getFormattedDate(data1?.data?.createdAt)}
                  readOnly
                />
              </div>
            </div>
            <div className="form-item">
              <label className="">Country</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  value={data1?.data?.countryCode}
                  readOnly
                />
              </div>
            </div>
            <div className="form-item">
              <label className="">Default Currency</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  value={data1?.data?.defaultCurrency}
                  readOnly
                />
              </div>
            </div>
            <div className="form-item">
              <label className="">Transfers Status</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  value={data1?.data?.transfers}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PaymentAccount;
