import { useQuery } from "@tanstack/react-query";
import axios from "axios";

// Custom hook for fetching paginated data from an API
const useFetchData = (url, queryKey, page = 1, perPage = 10, filters = {}) => {
  const fetchData = async () => {
    const { gender, categoryId, tagId, search } = filters;
    const params = new URLSearchParams();

    if (gender) params.append("gender", gender);
    if (categoryId) params.append("categoryId", categoryId);
    if (tagId) params.append("tagId", tagId);
    if (search) params.append("name", search); // Adjusted 'search' param to 'name'

    const response = await axios.get(
      `${
        process.env.REACT_APP_API_BASE_URL
      }${url}?page=${page}&limit=${perPage}&${params.toString()}`
    );

    return response.data;
  };

  const { data, isLoading, error } = useQuery({
    queryKey: [queryKey, page, filters],
    queryFn: fetchData,
    keepPreviousData: true,
  });

  return { data, isLoading, error };
};

export default useFetchData;
