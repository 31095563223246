import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import useCreateCategory from "../../../../hooks/data-hooks/admin/category/useCreateCategory";
import BackButton from "../../../../helpers/backButton";

const CreateCategory = () => {
  const {
    updateContent,
    isLoading,
    isError,
    error,
    submitSuccess,
    isSubmitting,
  } = useCreateCategory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    // Show error toast if isError is true
    if (isError) {
      toast.error(error.message);
    }
  }, [isError, error]);

  useEffect(() => {
    // Show success toast if submitSuccess is true
    if (submitSuccess) {
      toast.success("Form Submit Successful");
    }
  }, [submitSuccess]);

  const onSubmit = async (data) => {

    console.log(data);
    // Prevent form submission if there is an ongoing login process
    if (isSubmitting) return;

    try {
      // Call the custom hook for API call
      await updateContent(data);
    } catch (error) {
      // Error handling is done in useEffect
    }
  };

  return (
    <div className="container">
      <div className=" col-lg-6">
        <div className="cms-card  ">
          <BackButton />
          <form className="cms-form   mb-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="fs-24 fw-bold ">Category Form</div>

            <div className="mt-5">
              <div className="form-item">
                <label className="">Category Name</label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    className=""
                    placeholder="Enter category name"
                    {...register("categoryName", {
                      required: "Category name is required",
                    })}
                  />
                </div>
              </div>
            </div>

            <button type="submit" className="w-100 button-primary h-64px">
              {isSubmitting ? "Adding..." : "Add Category"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
export default CreateCategory;
