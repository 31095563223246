const advertiserPrefix = "advertiser"; // Prefix for routes

// Define routes without prefix
const advertiserRoutes = {
  DASHBOARD: "/dashboard",
  INFLUENCER_LIST: "/influencer-list",
  ORDER_PLACEMENT: "/order-placement",
  // ORDER_PLACEMENT_DETAILS: "/order-placement-details",
  ORDER_TRACKING: "/order-tracking",
  ORDER_TRACKING_DETAILS: "/order-tracking-details",
  MESSAGE: "/message",
  FEEDBACK: "/feedback",
  CART: "/cart",
  NOTIFICATIONS: "/notifications",
  ADVERTISER_SETTINGS: "/Advertiser-settings",
  CREATE_FEEDBACK: "/create-feedback",
  TRANSACTION: "/transaction",

  // ADVERTISER: "/users/advertiser",
  // ADVERTISER_DETAILS: "/users/advertiser/details",
  // advertiser: "/users/advertiser",
  // advertiser_DETAILS: "/users/advertiser/details",
  // DISPUTES: "/booking/disputes",
  // CREATE_DISPUTES: "/products/add-product-category",
  // ALL_BOOKINGS: "/booking/all-bookings",
  // ORDERS: "/orders",
  // MESSAGE: "/message",
  // SETTINGS: "/settings",
  // WEB_CONTENT_SETTINGS: "/settings/web-content",
  // GENERAL_SETTINGS: "/settings/general-settings",
  // PAYMENT: "/payment",
  // REPORT: "/report",
};

// Define page names
const advertiserPageNames = {
  DASHBOARD: "Advertiser Dashboard",
  INFLUENCER_LIST: "Influencer List",
  ORDER_PLACEMENT: "Order Placement",
  // ORDER_PLACEMENT_DETAILS: "Order Placement Details",
  ORDER_TRACKING: "Order Tracking",
  ORDER_TRACKING_DETAILS: "Order Tracking Details",
  MESSAGE: "Message",
  FEEDBACK: "feedback",
  CART: "Cart",
  NOTIFICATIONS: "Notifications",
  ADVERTISER_SETTINGS: "Advertiser Settings",
  CREATE_FEEDBACK: "Create Feedback",
  TRANSACTION: "Transaction",

  // ADVERTISER: "User Management",
  // ADVERTISER_DETAILS: "Advertiser Details",
  // advertiser: "User Management",
  // advertiser_DETAILS: "Advertiser Details", // Ensure consistency in casing
  // CREATE_DISPUTES: "Add New Product Category",
  // DISPUTES: "Booking Oversight",
  // ALL_BOOKINGS: "Booking Oversight",
  // ORDERS: "Orders",
  // TRANSACTION: "Payment",
  // MESSAGE: "Message",
  // SETTINGS: "Settings",
  // WEB_CONTENT_SETTINGS: "Web Content Settings",
  // GENERAL_SETTINGS: "General Settings",
  // PAYMENT: "Payment",
  // REPORT: "Report",
};

// Dynamically add the prefix to each route
const advertiserPrefixedRoutes = {};

// Add the prefix to each route
for (const key in advertiserRoutes) {
  if (advertiserRoutes.hasOwnProperty(key)) {
    advertiserPrefixedRoutes[key] = `/${advertiserPrefix}${advertiserRoutes[key]}`;
  }
}

// Default export of prefixed routes
export default advertiserPrefixedRoutes;

// Named exports
export { advertiserPrefix, advertiserRoutes, advertiserPrefixedRoutes, advertiserPageNames };
