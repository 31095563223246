import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import useUpdateLabContent from "../../../../hooks/data-hooks/admin/homepage/lab-content/useUpdateLabContent"; // Importing custom hook for API call
import useGetLabContent from "../../../../hooks/data-hooks/admin/homepage/lab-content/useGetLabContent";
import MyImage from "../../../../custom-components/MyImage";
import useSingleImageUpload from "../../../../hooks/file-uplod-hooks/useSingleImageUpload";
import Loader from "../../../common/components/loader/Loader";
import Button from "../../../web/components/button/Button";

const LabContent = () => {
  const { data1, isLoading1, isError1 } = useGetLabContent();
  console.log(data1?.data?.content);
  const [files, setFiles] = useState({});
  const handleFileChanges = (event) => {
    // Convert FileList to array
    // const fileList = Array.from(event.target.file);
    setFiles({ ...files, [event.target.name]: event.target.files[0] });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
    setValue,
  } = useForm();

  const { handleUpload } = useSingleImageUpload();

  useEffect(() => {
    // Set default values if data1 is available
    if (data1) {
      Object.entries(data1?.data?.content).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [data1]);

  const { updateContent, isLoading, isError, error, submitSuccess } =
    useUpdateLabContent();

  useEffect(() => {
    // Show error toast if isError is true
    const errorMessage = isError || error || isError;
    if (isError) {
      toast.error(errorMessage);
    }
  }, [isError, error, isError]);

  // useEffect(() => {
  //   // Show success toast if submitSuccess is true
  //   if (submitSuccess) {
  //     toast.success("Form Submit Successful");
  //   }
  // }, [submitSuccess]);

  const onSubmit = async (data) => {
    // Prevent form submission if there is an ongoing login process
    if (isSubmitting) return;

    try {
      const urls = await Promise.all([
        files.image_one
          ? handleUpload(files.image_one)
          : getValues("image_one"),
        files.image_two
          ? handleUpload(files.image_two)
          : getValues("image_two"),
      ]);

      // Call the custom hook for API call
      updateContent({ ...data, image_one: urls[0], image_two: urls[1] });
      // updateContent({
      //   ...data,
      //   image_one: "https://example.com/",
      //   image_two: "https://example.com/",
      // });
      // reset();
    } catch (error) {
      // Error handling is done in useEffect
    }
  };

  if (isLoading1) return <Loader />;

  return (
    <div className="cms-section ">
      <div className="cms-card  ">
        <form className="cms-form   mb-4  " onSubmit={handleSubmit(onSubmit)}>
          <div className="fs-18px fw-bold mb-3">Influencer Section</div>

          <div className="cms-content custom-scroll">
            <div className="form-item">
              <label className="">Title</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  placeholder="Enter title name"
                  {...register("title", {
                    required: "Field is required",
                  })}
                />
              </div>
            </div>
            <div className="form-item">
              <label className="">Paragraph</label>
              <div className="input-wrapper">
                <textarea
                  type="text"
                  className=""
                  placeholder="Enter paragraph content"
                  {...register("paragraph", {
                    required: "Field is required",
                  })}
                />
              </div>
            </div>
            <div className="form-item">
              <label className="">Button text</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  placeholder="Enter button text"
                  {...register("button_text", {
                    required: "Field is required",
                  })}
                />
              </div>
            </div>
            <div className="form-item">
              <label className="">Button URL</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  placeholder="Enter button URL content"
                  {...register("button_url", {
                    required: "Field is required",
                  })}
                />
              </div>
            </div>

            <div className="form-item">
              <label className="">
                Image One <span className="text-danger fs-10 ">(Max 5 MB)</span>
              </label>
              <div className="input-wrapper   ">
                <MyImage
                  src={
                    files?.image_one
                      ? URL.createObjectURL(files?.image_one)
                      : data1?.data?.content?.image_one
                  }
                  className="preview-img"
                />

                <input
                  type="file"
                  onChange={handleFileChanges}
                  name="image_one"
                />
              </div>
            </div>
            {/* <div className="form-item">
              <label className="">
                Image Two <span className="text-danger fs-10 ">(Max 5 MB)</span>
              </label>
              <div className="input-wrapper">
                <MyImage
                  src={
                    files?.image_two
                      ? URL.createObjectURL(files?.image_two)
                      : data1?.data?.content?.image_two
                  }
                  className="preview-img"
                />

                <input
                  type="file"
                  onChange={handleFileChanges}
                  name="image_two"
                />
              </div>
            </div> */}
          </div>

          <Button
            label={isLoading ? "Submitting" : "Submit"}
            type="submit"
            className={`w-100 ${isLoading ? "bg-disabled" : ""}`}
            disabled={isLoading}
          />
        </form>
      </div>
    </div>
  );
};
export default LabContent;
