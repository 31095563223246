import React, { useEffect, useState, useMemo } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import MessageBadge from "../message/MessageBadge";
import advertiserPrefixedRoutes from "../../../../routes/all-routes/advertiserRoutes";
import { Helmet } from "react-helmet-async";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";
import { getFormattedDate } from "../../../../helpers/getFormattedDate";

const OrderPlacement = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.user.accessToken);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const getOrder = async (page, size = perPage) => {
    try {
      setLoading(true);
      const req = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/user/order/list?pageSize=${size}&pageNumber=${page}&sortField=createdAt&sortDirection=desc`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const res = await req.json();
      if (res?.error?.status === 401) {
        navigate(authPrefixedRoutes.LOGIN);
      }
      setData(res);
      setTotalRows(res.meta.pagination.total_count);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTotalRows(data?.meta?.pagination?.total_count);
  }, [data]);

  useEffect(() => {
    getOrder(1);
  }, []);

  const columns = [
    {
      name: "#",
      selector: (row, index) =>
        (data?.meta?.pagination?.current_page - 1) * perPage + index + 1,
      sortable: true,
    },
    {
      name: "Order Id",
      selector: (row) => (
        <Link
          to={`${advertiserPrefixedRoutes.ORDER_PLACEMENT}/${row?._id}`}
          className="link-style"
        >
          {row?.orderNumber}
        </Link>
      ),
      sortable: true,
    },
    {
      name: "Order Quantity",
      selector: (row) => {
        return row?.orderItems?.length;
        // return row?.orderItems.length?.quantity;
      },

      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => {
        return getFormattedDate(row?.createdAt);
      },
      sortable: true,
    },
    {
      name: "Order Status",
      selector: (row) => {
        return row?.orderStatus;
      },
      sortable: true,
    },

    {
      name: "Payment Status",
      selector: (row) => {
        return row?.paymentStatus;
      },
      sortable: true,
    },
    // {
    //   name: "Delivery Status",
    //   selector: (row) => {
    //     return row.deliveryStatus;
    //   },
    //   sortable: true,
    // },
    {
      name: "Messages",
      cell: (row) => (
        <Link to={`${advertiserPrefixedRoutes.MESSAGE}/${row?._id}`} className="">
          <MessageBadge messageCount={row.totalMessages} />
        </Link>
      ),
    },
  ];

  const handlePageChange = (page) => {
    getOrder(page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    getOrder(page, newPerPage);
    setPerPage(newPerPage);
  };

  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "var(--primary-color)",
        color: "#fff",
      },
    },
  };


  return (
    <div className="container ">
      <Helmet>
        <title>
          Order Placement | Advertiser | {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <div className="border shadow  ">
        <DataTable
          title="Order Placement"
          customStyles={tableHeaderstyle}
          columns={columns}
          data={data.data}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          fixedHeader
          selectableRowsHighlight
          highlightOnHover
        />
      </div>
    </div>
  );
};
export default OrderPlacement;
