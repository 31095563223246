import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { debounce } from "lodash";
import useClinicList from "./useClinicList";
import useVerifyClinic from "./useVerifyClinic";
import useActiveDeactiveClinic from "./useActiveDeactiveClinic";
import { toast } from "react-toastify";

const useClinicListLogic = () => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm();

  // Function to handle page change
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const handlePageClick = ({ selected }) => {
    let page = selected + 1; // selected starts from 0
    //console.log("selected", page);
    if (page > 0) {
      setCurrentPage(page);
    }
  };
  const pageSize = 10; // items per page
  const {
    fetchClinicListWithParams,
    isLoading,
    isError,
    clinicList,
    setClinicList,
    clinicListMeta,
    clinicListError
  } = useClinicList({
    pageNumber: currentPage,
    pageSize: pageSize,
    searchTerm: searchTerm,
    status: filterStatus,
    sortField: "createdAt",
    sortDirection: "desc"
  });

  useEffect(() => {
    // console.log("-------------------\n");
    // console.log("isLoading:", isLoading);
    // console.log("clinicList:", clinicList);
    // console.log("clinicListMeta:", clinicListMeta);
    // console.log("clinicListError:", clinicListError);
    // console.log("-------------------\n");
  }, [isLoading, clinicList, clinicListError, clinicListMeta]);

  useEffect(() => {
    // console.log("-------------------\n");
    // console.log("currentPage:", currentPage);
    // console.log("searchTerm:", searchTerm);
    // console.log("filterStatus:", filterStatus);
    // console.log("-------------------\n");
    fetchClinicListWithParams({
      pageNumber: currentPage,
      pageSize: pageSize,
      searchTerm: searchTerm,
      status: filterStatus,
      sortField: "createdAt",
      sortDirection: "desc"
    });
  }, [currentPage, searchTerm, filterStatus]);

  const onSubmit = (data) => {
    if (isSubmitting) {
      return;
    }

    // Trigger API call with search term and filter status
    const { searchTerm, status } = data;
    setSearchTerm(searchTerm);
    setFilterStatus(status);
    setCurrentPage(1); // Reset page to 1 when searching
  };

  // Debounced form submission for input change
  const debouncedSubmit = debounce(onSubmit, 1000);

  //--------------------------------

  const {
    verifyClinic,
    verifyClinicLoading,
    verifyClinicError,
    verifyClinicSuccess
  } = useVerifyClinic();

  const {
    activeDeactiveClinic,
    activeDeactiveClinicLoading,
    activeDeactiveClinicError,
    activeDeactiveClinicSuccess
  } = useActiveDeactiveClinic();

  //  to manage actions like activate deactive and verify
  const [clinicStates, setClinicStates] = useState({});

  useEffect(() => {
    // Initialize state for each clinic item
    const initialClinicStates = clinicList.reduce((acc, clinic) => {
      acc[clinic._id] = {
        verifyClinicLoading: false,
        verifyClinicError: null,
        verifyClinicSuccess: false,
        activeDeactiveClinicLoading: false,
        activeDeactiveClinicError: null,
        activeDeactiveClinicSuccess: false
      };
      return acc;
    }, {});
    setClinicStates(initialClinicStates);
  }, [clinicList]);

  // Function to update clinic state
  const updateClinicState = (clinicId, newState) => {
    setClinicStates((prevStates) => ({
      ...prevStates,
      [clinicId]: { ...prevStates[clinicId], ...newState }
    }));
  };

  // Function to handle clinic verification
  const handleVerifyClinic = async (clinicId) => {
    try {
      updateClinicState(clinicId, { verifyClinicLoading: true });
      verifyClinic(clinicId);
      updateClinicState(clinicId, {
        verifyClinicLoading: false,
        verifyClinicSuccess: true
      });

      // Update the clinic status to 'active' in the clinic list
      setClinicList((prevClinicList) =>
        prevClinicList.map((clinic) =>
          clinic._id === clinicId ? { ...clinic, status: "active" } : clinic
        )
      );

      toast.success("Clinic verified successfully!");
    } catch (error) {
      updateClinicState(clinicId, {
        verifyClinicLoading: false,
        verifyClinicError: error.message
      });
    }
    toast.error("Failed to verify clinic.");
  };

  // Function to handle clinic status change
  const handleActiveDeactiveClinic = async (clinicId) => {
    try {
      updateClinicState(clinicId, { activeDeactiveClinicLoading: true });
      activeDeactiveClinic(clinicId);
      updateClinicState(clinicId, {
        activeDeactiveClinicLoading: false,
        activeDeactiveClinicSuccess: true
      });

      // Update the clinic status in the clinic list
      setClinicList((prevClinicList) =>
        prevClinicList.map((clinic) => {
          return clinic._id === clinicId
            ? {
                ...clinic,
                status: clinic.status === "active" ? "inactive" : "active"
              }
            : clinic;
        })
      );

      toast.success("Advertiser status updated successfully!");
    } catch (error) {
      updateClinicState(clinicId, {
        activeDeactiveClinicLoading: false,
        activeDeactiveClinicError: error.message
      });

      toast.error("Failed to update clinic status.");
    }
  };

  return {
    register,
    pageSize,
    handleSubmit,
    onSubmit,
    isSubmitting,
    currentPage,
    setCurrentPage,
    handlePageClick,
    searchTerm,
    filterStatus,
    setFilterStatus,
    clinicList,
    isLoading,
    clinicListError,
    clinicListMeta,
    debouncedSubmit,
    clinicStates,
    handleVerifyClinic,
    handleActiveDeactiveClinic
  };
};

export default useClinicListLogic;
