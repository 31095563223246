import {
  CartIcon,
  MessageIcon,
  NotificationIcon,
} from "../../../../utils/MyIcons";
import advertiserPageName from "../../../../hooks/ui-hooks/AdvertiserPageName.js";
import { Link } from "react-router-dom";
import AdvertiserPrefixedRoutes, {
  advertiserPrefixedRoutes,
} from "../../../../routes/all-routes/advertiserRoutes";
import CartBadge from "../cart/CartBadge.js";

const AdvertiserTopBar = () => {
  const currentPageName = advertiserPageName("Advertiser");

  return (
    <div className="bg-white w-100 sticky-top top-0 ">
      <div className="container Advertiser-topbar pt-4 pb-4 d-flex justify-content-between align-items-center">
        <span className="fs-1 fw-900 text-title ">{currentPageName}</span>
        {/* Notification - Cart */}
        <div className="d-flex align-items-center gap-3 ">
          <CartBadge />

          {/* Notification */}
          <Link to={AdvertiserPrefixedRoutes.NOTIFICATIONS}>
            <button className="d-flex align-items-center justify-content-center w-h-44px rounded-circle border bg-transparent">
              <NotificationIcon />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default AdvertiserTopBar;

/* ---------------------
----------Backup code------------
----------------------------------- */

// import { MessageIcon, NotificationIcon } from "../../../../utils/MyIcons";
// import advertiserPageName from "../../../../hooks/ui-hooks/AdvertiserPageName.js";
// import { Link } from "react-router-dom";
// import AdvertiserPrefixedRoutes from "../../../../routes/all-routes/advertiserRoutes";

// const AdvertiserTopBar = () => {
//   const currentPageName = advertiserPageName("Advertiser");

//   return (
//     <div className="bg-white w-100 sticky-top top-0 ">
//       <div className="container Advertiser-topbar pt-4 pb-4 d-flex justify-content-between align-items-center">
//         <span className="fs-1 fw-900 text-title ">{currentPageName}</span>
//         <div className="d-flex align-items-center gap-2 ">
//           <Link to={AdvertiserPrefixedRoutes.MESSAGE}>
//             <button className="d-flex align-items-center justify-content-center w-h-44px rounded-circle border bg-transparent">
//               <MessageIcon />

//             </button>
//           </Link>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default AdvertiserTopBar;
