import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import quotesComa1 from "../../../../assets/svg/quotes-coma1.svg";
import quotesComa2 from "../../../../assets/svg/quotes-coma2.svg";

import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";


const CustomSwiper = ({ testimonial, title }) => {
  return (
    <Swiper
      modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={200}
      slidesPerView={1}
      centeredSlides={true}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      navigation={false}
      //   pagination={{ clickable: true }}
      //   scrollbar={{ draggable: true }}
      //onSwiper={(swiper) => console.log(swiper)}
    >
      {testimonial?.map((item) => (
        <SwiperSlide key={JSON.stringify(item)}>
          <div className="row align-items-center">
            <div className="col-lg-5 d-flex justify-content-center align-items-center mb-3">
              <div className="testimonial-bg bg-primary-color position-relative"></div>
              <div className="bg-white position-absolute">
                <div className="card testimonial-inner-bg flex justify-content-center align-items-center">
                  <img
                    src={item?.image}
                    className="testimonial-img w-100 mt-4"
                    alt="testimonial"
                  />
                  <div className="card-body flex flex-column justify-content-center align-items-center color-black">
                    <h5 className="testimonial-title text-black">
                      {item?.name}
                    </h5>
                    <p className="testimonial-sub text-black">
                      {item?.designation}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-7">
              <div className="testimonial gap-5 text-black ">
                <h3 className="review-heading mb-4">{title}</h3>
                <div className="flex justify-content-start ">
                  <img src={quotesComa2} className="" alt="" />
                </div>

                <div className="">
                  <p className="review text-black ms-5 p-2 ">
                    <small>{item?.content}</small>
                  </p>
                </div>

                <div className="flex justify-content-end">
                  <img src={quotesComa1} className="" alt="" />
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
const Testimonial = ({ data }) => {
  return (
    <div className=" mt-5 mb-5 pt-5 pb-5">
      <div className="container container-fluid py-4 px-4 d-flex justify-content-center flex-column align-items-center ">
        <div className="d-flex justify-content-center align-items-center flex-column mb-5">
          <div className="title-40 fw-bold mb-2 text-center">
            {/* {data?.content?.title} */}
          </div>
          <div className="testimonial-sub color-title-color text-center">
            {/* {data?.content?.subtitle} */}
          </div>
        </div>
        <CustomSwiper testimonial={data?.content?.testimonial_items} title={data?.content?.title} />
      </div>
    </div>
  );
};

export default Testimonial;
