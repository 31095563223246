// Home.js
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import HeroSection from "../hero-section/HeroSection";
import Testimonial from "../testimonial/Testimonial";
import HeroSection1 from "../hero-section-1/HeroSection1";

import heroSectionImg from "./../../../../assets/image/hero-section1.jpg";
import heroSectionImg2a from "./../../../../assets/image/hero-section-2-a.jpg";
import heroSectionImg2b from "./../../../../assets/image/hero-section-2-b.jpg";
import MemberStats from "../member-stats/MemberStats";
import Loader from "../../../common/components/loader/Loader";
import useWebContent from "../../../../hooks/data-hooks/website/useWebContent";
import { authPrefixedRoutes } from "../../../../routes/all-routes/authRoutes";

import InfluencerContainer from "../influencer/InfluencerContainer";

import useFetchData from "../../../../hooks/data-hooks/common/useFetchData";

const Home = () => {
  const [currentPage, setCurrentPage] = useState(1); // Tracks the current page
  const perPage = 999999999999999;
  const { isLoading: isWebContentLoading, data: webContentData } =
    useWebContent();

  const {
    data: mainData,
    isLoading: isFetchingData,
  } = useFetchData(
    "/api/public/influencer-search",
    "influencerKeyHome",
    currentPage,
    perPage
    );
  
  const partialData = mainData?.data?.slice(0, 4) ;


  if (isWebContentLoading || isFetchingData) return <Loader />;
  return (
    <div className="w-100 bg-white">
      <Helmet>
        <title>Home | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <HeroSection data={webContentData?.data?.hero} />

      {/* advertiser Register Content Area */}
      <HeroSection1
        data={webContentData?.data?.clinic_content}
        img1={heroSectionImg}
        buttonUrl={authPrefixedRoutes.CLINIC_SIGNUP}
      />
      {/* Influencer Register Content Area */}
      <HeroSection1
        order={2}
        data={webContentData?.data?.lab_content}
        img1={heroSectionImg2a}
        img2={heroSectionImg2b}
        buttonUrl={authPrefixedRoutes.LAB_SIGNUP}
      />
      {/* <HeroSection2 data={data?.data?.service?.content} /> */}

      <section className="py-4">
        <div className="container my-4">
          <div className="row align-items-center mb-4">
            <div className="col">
              <h2 className="mb-0">Our Top Influencers</h2>
            </div>
            <div className="col text-end">
              <a href="/influencers" className="link-style fw-bold ">
                View All Influencers
              </a>
            </div>
          </div>
        </div>
        <InfluencerContainer
          data={partialData}
          
        />
      </section>

      <MemberStats
        data={webContentData?.data?.service_count}
        serviceCard={webContentData?.data?.service_card}
      />
      {/* <HealthClinics data={data?.data?.content} /> */}
      <Testimonial data={webContentData?.data?.testimonial} />
      {/* <Faq data={data?.data?.faq} /> */}
      {/* <Partner data={data?.data?.brand?.content} /> */}
    </div>
  );
};

export default Home;
