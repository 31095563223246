import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import useCreateCategory from "../../../../hooks/data-hooks/admin/feature/useCreateCategory";
import BackButton from "../../../../helpers/backButton";

const CreateFeature = () => {
  const {
    updateContent,
    isLoading,
    isError,
    error,
    submitSuccess,
    isSubmitting,
  } = useCreateCategory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    // Show error toast if isError is true
    if (isError) {
      toast.error(error.message);
    }
  }, [isError, error]);

  useEffect(() => {
    // Show success toast if submitSuccess is true
    if (submitSuccess) {
      toast.success("Form Submit Successful");
    }
  }, [submitSuccess]);

  const onSubmit = async (data) => {
    console.log(data);
    // Prevent form submission if there is an ongoing login process
    if (isSubmitting) return;

    try {
      // Call the custom hook for API call
      await updateContent(data);
    } catch (error) {
      // Error handling is done in useEffect
    }
  };

  return (
    <div className="container">
      <div className=" col-lg-6">
        <div className="cms-card  ">
          <BackButton />
          <form className="cms-form   mb-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="fs-24 fw-bold ">Create Featured Form</div>

            <div className="mt-5">
              <div className="form-item">
                <label className="">Featured Package Name</label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    className=""
                    placeholder="Enter feature name"
                    {...register("featuredPackageName", {
                      required: "Featured Package name is required",
                    })}
                  />
                  {errors.featuredPackageName && (
                    <p className="error-message">
                      {errors.featuredPackageName.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="form-item">
                <label className="">Validation In Days</label>
                <div className="input-wrapper">
                  <input
                    type="number"
                    className=""
                    placeholder="Enter validation in days"
                    {...register("validationInDay", {
                      required: "Validation in days is required",
                      min: {
                        value: 1,
                        message: "Validation in days must be greater than 0",
                      },
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Validation in days must be a number",
                      },
                    })}
                  />
                  {errors.validationInDay && (
                    <p className="error-message">
                      {errors.validationInDay.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="form-item">
                <label className="">Price</label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    className=""
                    placeholder="Enter price"
                    {...register("price", {
                      required: "Price is required",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Price must be a number more than 0 ",
                      },
                      validate: (value) => {
                        const isValid = /^[1-9]\d*$/.test(value);
                        return (
                          isValid || "Price must be a number and more than 0"
                        );
                      },
                    })}
                  />
                  {errors.price && (
                    <p className="error-message">{errors.price.message}</p>
                  )}
                </div>
              </div>
            </div>

            <button type="submit" className="w-100 button-primary h-64px">
              {isSubmitting ? "Adding..." : "Add Featured"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
export default CreateFeature;
