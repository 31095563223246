import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import BackButton from "../../../../helpers/backButton";
import useCreatePackage from "../../../../hooks/data-hooks/influencer/package/useCreatePackage";
import { platformList, typeList } from "../../../../utils/Socials";

const CreatePackage = () => {
  const {
    updateContent,
    isLoading,
    isError,
    error,
    submitSuccess,
    isSubmitting,
  } = useCreatePackage();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    if (isError) {
      toast.error(error.message);
    }
  }, [isError, error]);

  useEffect(() => {
    if (submitSuccess) {
      toast.success("Form Submit Successful");
    }
  }, [submitSuccess]);

  const onSubmit = async (data) => {
    console.log(data);
    if (isSubmitting) return;
    try {
      await updateContent(data);
    } catch (error) {
      // Error handling is done in useEffect
    }
  };

  return (
    <div className="container">
      <div className="col-lg-6">
        <div className="cms-card">
          <BackButton />
          <form className="cms-form mb-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="fs-24 fw-bold">Create New Package</div>

            <div className="mt-5">
              {/* Package Name */}
              <div className="form-item">
                <label className="">Package Name</label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    className=""
                    placeholder="Enter Package name"
                    {...register("packageName", {
                      required: "Package name is required",
                    })}
                  />
                  {errors.packageName && (
                    <span className="error">{errors.packageName.message}</span>
                  )}
                </div>
              </div>

              {/* Description */}
              <div className="form-item">
                <label className="">Description</label>
                <div className="input-wrapper">
                  <textarea
                    rows={5}
                    type="text"
                    className=""
                    placeholder="Enter description"
                    {...register("description", {
                      required: "Description is required",
                    })}
                  />
                  {errors.description && (
                    <span className="error">{errors.description.message}</span>
                  )}
                </div>
              </div>

              {/* social Platform Name */}
              <div className="form-item">
                <label className="">Platform Name</label>
                <div className="input-wrapper">
                  <select
                    {...register("socialPlatformName", {
                      required: "Platform Name is required",
                    })}
                  >
                    <option value="">Select Platform</option>
                    {platformList.map((platform, index) => (
                      <option key={index} value={platform}>
                        {platform}
                      </option>
                    ))}
                  </select>
                </div>
                {errors.socialPlatformName && (
                  <p className="text-danger">
                    {errors.socialPlatformName.message}
                  </p>
                )}
              </div>

              {/* Duration */}
              <div className="form-item">
                <label className="">Duration</label>
                <div className="input-wrapper">
                  <input
                    type="number"
                    className=""
                    placeholder="Enter Duration"
                    {...register("duration", {
                      required: "Duration is required",
                      min: {
                        value: 1,
                        message: "Duration must be at least 1 day",
                      },
                    })}
                  />
                  {errors.duration && (
                    <span className="error text-danger ">
                      {errors.duration.message}
                    </span>
                  )}
                </div>
              </div>

              {/* type Of Package */}
              <div className="form-item">
                <label className="">Package Type</label>
                <div className="input-wrapper">
                  <select
                    {...register("typeOfPackage", {
                      required: "Package Type is required",
                    })}
                  >
                    <option value="">Select Type</option>
                    {typeList.map((type, index) => (
                      <option key={index} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
                {errors.typeOfPackage && (
                  <p className="text-danger">{errors.typeOfPackage.message}</p>
                )}
              </div>

              {/* price */}
              <div className="form-item">
                <label className="">Price</label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    className=""
                    placeholder="Enter Price"
                    {...register("price", {
                      required: "Price is required",
                      validate: (value) => {
                        const isValid = /^[1-9]\d*$/.test(value);
                        return (
                          isValid || "Price must be a number and more than 0"
                        );
                      },
                    })}
                  />
                  {errors.price && (
                    <p className="text-danger">{errors.price.message}</p>
                  )}
                </div>
              </div>
            </div>

            <button type="submit" className="w-100 button-primary h-64px">
              {isSubmitting ? "Adding..." : "Add Package"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreatePackage;
