// Cart.js
import React, { useEffect } from "react";
import useGetcartItems from "../../../../hooks/data-hooks/advertiser/cart/useGetcartItems";
import DataTable from "react-data-table-component";
import { Helmet } from "react-helmet";
import { advertiserPageNames } from "../../../../routes/all-routes/advertiserRoutes";
import useUpdateCartItem from "../../../../hooks/data-hooks/advertiser/cart/useUpdateCartItem";
import usePlaceOrder from "../../../../hooks/data-hooks/advertiser/cart/usePlaceOrder";
import { MdRemoveShoppingCart } from "react-icons/md";

const Cart = () => {
  const { isLoading, data, cartSummary, refetchData, deleteCartItem } =
    useGetcartItems();

  useEffect(() => {
    refetchData();
  }, []);

  const { updateCartItem } = useUpdateCartItem(refetchData); // Pass refetchData to the hook

  const { placeOrder, isPlacingOrder } = usePlaceOrder(refetchData);

  const handleDecreaseQuantity = (cartItemId, quantity) => {
    if (quantity <= 1) {
      deleteCartItem(cartItemId, refetchData);
      return;
    }
    updateCartItem({ cartItemId, quantity: quantity - 1 });
  };

  const handleIncreaseQuantity = (cartItemId, quantity) => {
    updateCartItem({ cartItemId, quantity: quantity + 1 });
  };

  const columns = [
    {
      name: "#No",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Package Name",
      selector: (row) => row?.packageId?.packageName,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => {
        return (
          <div className="d-flex gap-2 align-items-center ">
            <span
              className="cursor-pointer px-1 rounded-5 bg-top text-white "
              onClick={() => handleDecreaseQuantity(row?._id, row?.quantity)}
            >
              -
            </span>
            {row?.quantity}
            <span
              className="cursor-pointer px-1 rounded-5 bg-top text-white "
              onClick={() => handleIncreaseQuantity(row?._id, row?.quantity)}
            >
              +
            </span>
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => row?.packageId?.price,
      sortable: true,
    },
    {
      name: "Total Amount",
      selector: (row) => row?.quantity * row?.packageId?.price,
      sortable: true,
    },
    {
      name: "Remove",
      cell: (row) => (
        <span
          onClick={() => deleteCartItem(row._id, refetchData)}
          className="cursor-pointer"
        >
          <MdRemoveShoppingCart color={`var(--primary-color)`} size={20} />
        </span>
      ),
    },
  ];

  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "var(--secondary-color)",
        color: "#000",
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>
          {advertiserPageNames.CART} | Advertiser | {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <div className="container">
        <div className="my-4">
          <span className="fw-bold text-title fs-5">Your Cart</span>
        </div>
        <div className="row row-gap-4 align-items-start">
          <div className="col-12 col-lg-8">
            <DataTable
              customStyles={tableHeaderstyle}
              columns={columns}
              data={data?.data}
              progressPending={isLoading}
              fixedHeader
              highlightOnHover
              className="shadow rounded-3"
            />
          </div>
          <div className="col-12 col-lg-4">
            <div className="shadow bg-secondary-color rounded-3 p-3">
              <h5>Order Summary</h5>
              <hr />
              <div className="d-flex justify-content-between fw-bold mt-3">
                <span>Total items</span>
                <span>{cartSummary?.data?.totalItems}</span>
              </div>
              <div className="d-flex justify-content-between fw-bold mt-3">
                <span>Total Amount</span>
                <span>{cartSummary?.data?.totalPrice}</span>
              </div>
              <div>
                <button
                  className="btn bg-primary-color text-white w-100 mt-3"
                  disabled={isPlacingOrder}
                  onClick={placeOrder}
                >
                  {isPlacingOrder ? "Placing Order..." : "Place Order"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;
