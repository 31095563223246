import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import Button from "../../../web/components/button/Button";
import { Link, useNavigate } from "react-router-dom";
import adminPrefixedRoutes, {
  adminPageNames,
} from "../../../../routes/all-routes/adminRoutes";

import useGetCategoryList from "../../../../hooks/data-hooks/admin/category/useGetCategoryList";
import Loader from "../../../common/components/loader/Loader";
import { Helmet } from "react-helmet";
import { MdDeleteForever } from "react-icons/md";

const Category = () => {
  // const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const {
    fetchCategory,
    category,
    isLoading,
    updateCategoryStatus,
    deleteCategory,
    categoryError,
    fetchCategoryList,
    totalRows,
    setTotalRows,
  } = useGetCategoryList(perPage, currentPage);

  useEffect(() => {
    fetchCategory(perPage, currentPage);
  }, [perPage, currentPage]);

  const columns = [
    // #NO
    {
      name: "#No",
      selector: (row, index) =>
        (category?.meta?.current_page - 1) * perPage + index + 1,
      sortable: true,
    },

    {
      name: "Category Name",
      selector: (row) => (
        <Link
          to={`${adminPrefixedRoutes.UPDATE_CATEGORY}/${row._id}`}
          className="link-style"
        >
          {row.categoryName}
        </Link>
      ),
      sortable: true,
    },

    {
      name: "State",
      selector: (row) => (row.status === "enable" ? "Active" : "Inactive"),
      sortable: true,
    },

    // --------------Extra Columns-------------
    {
      name: "Action",
      cell: (row) => (
        <div className="form-switch">
          <input
            className="form-check-input cursor-pointer"
            type="checkbox"
            role="switch"
            checked={row.status === "enable"}
            onChange={() => {
              // console.log(row._id);
              updateCategoryStatus(row._id);
            }}
          />
        </div>
      ),
    },
    {
      name: "Delete",
      cell: (row) => (
        <span onClick={() => deleteCategory(row._id)} className="cursor-pointer" >
          <MdDeleteForever color={`var(--primary-color)`} size={20} />
        </span>
      ),
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // fetchcategory(perPage, page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    // setCurrentPage(page);
    // fetchcategory(newPerPage, page);
  };

  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "var(--secondary-color)",
        color: "#000",
      },
    },
  };

  //   const headerComponent = React.useMemo(() => {
  //     return (
  //       <div>
  //         Category List
  //         {/* <Button
  //           label={"Add New Category"}
  //           className="ms-3"
  //           onClick={() => navigate(adminPrefixedRoutes.CREATE_DISPUTES)}
  //         /> */}
  //       </div>
  //     );
  //   }, []);

  if (isLoading) return <Loader />;

  return (
    <>
      <Helmet>
        <title>
          {adminPageNames.CATEGORY} | Admin | {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <div className="container">
        <div className="my-4">
          <span className="fw-bold text-title fs-5">Category List</span>
          <Button
            label={"Add New Category"}
            className="ms-3"
            onClick={() => navigate(adminPrefixedRoutes.CREATE_CATEGORY)}
          />
        </div>
        <div className="shadow   ">
          <DataTable
            // title={headerComponent}
            customStyles={tableHeaderstyle}
            columns={columns}
            data={category?.data}
            progressPending={isLoading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            fixedHeader
            highlightOnHover
          />
        </div>
      </div>
    </>
  );
};
export default Category;
