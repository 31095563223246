import { useLocation } from 'react-router-dom';
import { advertiserPageNames, advertiserPrefixedRoutes } from '../../routes/all-routes/advertiserRoutes';


const useAdvertiserPageName = (panelName = "Advertiser") => {
  const location = useLocation();
  let currentPageName = "Advertiser Dashboard"; // Default page name

  //console.log("Current location:", location.pathname);

  try {
    const matchedRoute = Object.entries(advertiserPrefixedRoutes).find(([key, value]) => {
      const regex = new RegExp(`^${value}(\/|$)`);
      //console.log("Checking route:", key, value, regex.test(location.pathname));
      return regex.test(location.pathname);
    });

    //console.log("Matched route:", matchedRoute);

    if (matchedRoute) {
      const currentPageKey = matchedRoute[0];
      currentPageName = advertiserPageNames[currentPageKey];
      //console.log("Current page name:", currentPageName);
    }
  } catch (error) {
    //console.error("An error occurred while determining the current page name:", error);
  }

  return currentPageName;
};

export default useAdvertiserPageName;
