import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";
import { toast } from "react-toastify";
import { useState } from "react";
import { setCartData } from "../../../../redux/slices/cartSlice";

const useAddToCartItem = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.user.accessToken);
  const [isAddingToCart, setIsAddingToCart] = useState(false);

  const {
    mutate: addToCartItems,
    isLoading,
    isError,
    data,
  } = useMutation({
    mutationKey: "addToCartItems",
    mutationFn: async ({ influencerId, packageId, quantity = 1 }) => {
      setIsAddingToCart(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/advertiser/cart/add-item`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ influencerId, packageId, quantity }),
        }
      );

      if (!response.ok) {
        const text = await response.json();
        if (text?.error?.status === 401) {
          navigate(authPrefixedRoutes.LOGIN);
        }
        throw new Error(text.message || "Failed to add item to cart");
      }

      const data = await response.json();
      toast.success("Added to cart successfully");

      // Dispatch action to set cart Item Count
      dispatch(setCartData({ cartItemCount: data.cartItemCount }));

      setIsAddingToCart(false);

      return data;
    },
    onError: (error) => {
      toast.error(error.message || "Something went wrong");
    },
  });

  return {
    addToCartItems,
    isLoading,
    isError,
    data,
    isAddingToCart,
  };
};

export default useAddToCartItem;
