import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import useUpdateServiceCardSection from "../../../../hooks/data-hooks/admin/homepage/service-card-section/useUpdateServiceCardSection"; // Importing custom hook for API call
import useGetServiceCardSection from "../../../../hooks/data-hooks/admin/homepage/service-card-section/useGetServiceCardSection";
import useSingleImageUpload from "../../../../hooks/file-uplod-hooks/useSingleImageUpload";
import MyImage from "../../../../custom-components/MyImage";
import Loader from "../../../common/components/loader/Loader";
import Button from "../../../web/components/button/Button";

const ServiceCardSection = () => {
  const { data1, isLoading1, isError1, serviceCardData } =
    useGetServiceCardSection();
  const [files, setFiles] = useState({});
  // console.log(serviceCardData, data1, isLoading1);

  const { handleUpload, isLoading } = useSingleImageUpload();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    getValues,
  } = useForm();

  useEffect(() => {
    // Set default values if data1 is available
    if (data1) {
      let listFile = {};
      data1?.data?.content?.service_card_items.forEach((item, index) => {
        listFile = {
          ...listFile,
          [`icon-${index}`]: item.icon,
        };
        setValue(`service_card_items[${index}].icon`, item?.icon);
        setValue(`service_card_items[${index}].title`, item?.title);
        setValue(`service_card_items[${index}].paragraph`, item?.paragraph);
        setValue(`service_card_items[${index}].button_text`, item?.button_text);
        setValue(`service_card_items[${index}].button_url`, item?.button_url);
      });
    }
  }, [data1, serviceCardData]);

  const { updateContent, isLoadingUpdate, isError, error, submitSuccess } =
    useUpdateServiceCardSection();

  useEffect(() => {
    // Show error toast if isError is true
    if (isError) {
      toast.error(error.message);
    }
  }, [isError, error]);

  // useEffect(() => {
  //   // Show success toast if submitSuccess is true
  //   if (submitSuccess) {
  //     toast.success("Form Submit Successful");
  //   }
  // }, [submitSuccess]);

  const onSubmit = async (data) => {
    // Prevent form submission if there is an ongoing login process
    if (isSubmitting) return;
    // console.log(data);
    const newData = await Promise.all(
      data?.service_card_items?.map(async (item, index) => {
        // Check if icon is an object and needs to be uploaded
        const icon =
          typeof files[`icon-${index}`] === "object"
            ? await handleUpload(files[`icon-${index}`])
            : item.icon;

        return {
          ...item,
          icon: icon,
          // icon: "https://example.com/",
        };
      })
    );

    try {
      // Call the custom hook for API call
      updateContent({ service_card_items: newData });
    } catch (error) {
      // Error handling is done in useEffect
    }
  };
  const handleFileChanges = (event) => {
    setFiles({ ...files, [event.target.name]: event.target.files[0] });
  };

  if (isLoading1) return <Loader />;

  return (
    <div className="cms-section ">
      <div className="cms-card  ">
        <form className="cms-form   mb-4  " onSubmit={handleSubmit(onSubmit)}>
          <div className="fs-18px fw-bold mb-3">Service Card Section</div>

          {/* Dynamic form input */}
          <div className="cms-content custom-scroll">
            {data1?.data?.content?.service_card_items?.map((_, index) => (
              <div key={index}>
                <div className="d-flex justify-content-between">
                  <span className="fs-5 fw-bold">
                    Service Card {index + 1} Info
                  </span>
                </div>
                <div className="form-item">
                  <div className="d-flex justify-content-between">
                    <label>
                      Icon {index + 1}
                      <span className="text-danger fs-10 ">(Max 5 MB)</span>
                    </label>
                  </div>
                  <div className="input-wrapper">
                    <div className="form-item">
                      <MyImage
                        src={
                          typeof files[`icon-${index}`] === "undefined"
                            ? data1?.data?.content?.service_card_items[index]
                                ?.icon
                            : URL?.createObjectURL(files[`icon-${index}`])
                        }
                        className="preview-img"
                      />
                    </div>

                    <input
                      type="file"
                      onChange={handleFileChanges}
                      name={`icon-${index}`}
                    />
                  </div>
                </div>
                <div className="form-item">
                  <label>Title {index + 1}</label>
                  <div className="input-wrapper">
                    <div className="form-item"></div>

                    <input
                      type="text"
                      placeholder={`Enter title ${index + 1}`}
                      {...register(`service_card_items[${index}].title`, {
                        required: "Field is required",
                      })}
                    />
                  </div>
                </div>
                <div className="form-item">
                  <label>Paragraph {index + 1}</label>
                  <div className="input-wrapper">
                    <div className="form-item"></div>

                    <input
                      type="text"
                      placeholder={`Enter title ${index + 1}`}
                      {...register(`service_card_items[${index}].paragraph`, {
                        required: "Field is required",
                      })}
                    />
                  </div>
                </div>
                <div className="form-item">
                  <label>Button Text {index + 1}</label>
                  <div className="input-wrapper">
                    <div className="form-item"></div>

                    <input
                      type="text"
                      placeholder={`Enter button text ${index + 1}`}
                      {...register(`service_card_items[${index}].button_text`, {
                        required: "Field is required",
                      })}
                    />
                  </div>
                </div>
                <div className="form-item">
                  <label>Button URL {index + 1}</label>
                  <div className="input-wrapper">
                    <div className="form-item"></div>

                    <input
                      type="text"
                      placeholder={`Enter button url ${index + 1}`}
                      {...register(`service_card_items[${index}].button_url`, {
                        required: "Field is required",
                      })}
                    />
                  </div>
                </div>

                <hr />
              </div>
            ))}
          </div>

          <Button
            label={isLoadingUpdate || isLoading ? "Submitting" : "Submit"}
            type="submit"
            className={`w-100 ${
              isLoadingUpdate || isLoading ? "bg-disabled" : ""
            }`}
            disabled={isLoadingUpdate || isLoading}
          />
        </form>
      </div>
    </div>
  );
};
export default ServiceCardSection;
