import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";
import { useNavigate } from "react-router-dom";

const useClinicDetails = (initialClinicId) => {
  const token = useSelector((state) => state.auth.user.accessToken);

  const [clinicDetails, setClinicDetails] = useState({});
  const [clinicDetailsError, setClinicDetailsError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [orderList, setOrderList] = useState();
  const navigate = useNavigate();

  const fetchClinicDetails = useQuery({
    queryKey: ["clinicDetails", initialClinicId],
    queryFn: async () => {
      try {
        setIsLoading(true);
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/admin/advertiser/${initialClinicId}`;
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          const text = await response.json();
          if (text?.error?.status === 401) {
            navigate(authPrefixedRoutes.LOGIN);
          }
          const text1 = await response.text();
          setClinicDetailsError(text1);
          setIsLoading(false);
          throw new Error("Failed to fetch clinic details");
        }

        const responseData = await response.json();
        setClinicDetails(responseData); // Assuming responseData contains clinic details
        setIsLoading(false);

        return responseData;
      } catch (error) {
        setClinicDetailsError(error);
        setIsLoading(false);
        throw error;
      }
    },
    onError: (error) => {
      setClinicDetailsError(error);
      setIsLoading(false);
      throw error;
    },
  });

  const fetchOrdersOfClinic = useQuery({
    queryKey: ["orderssofclinics", initialClinicId],
    queryFn: async () => {
      try {
        setIsLoading(true);
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/admin/order-management/orders?advertiserId=${initialClinicId}`;
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          const text = await response.json();
          if (text?.error?.status === 401) {
            navigate(authPrefixedRoutes.LOGIN);
          }
          const text1 = await response.text();
          setClinicDetailsError(text1);
          setIsLoading(false);
          throw new Error("Failed to fetch clinic details");
        }

        const responseData = await response.json();
        console.log(" orders responseData:", responseData);
        setOrderList(responseData?.data); // Assuming responseData contains clinic details
        setIsLoading(false);

        return responseData;
      } catch (error) {
        setClinicDetailsError(error);
        setIsLoading(false);
        throw error;
      }
    },
    onError: (error) => {
      setClinicDetailsError(error);
      setIsLoading(false);
      throw error;
    },
  });

  const fetchClinicDetailsWithId = (clinicId) => {
    fetchClinicDetails.refetch(clinicId);
  };

  return {
    fetchClinicDetailsWithId,
    isLoading,
    isError: fetchClinicDetails.isError,
    clinicDetails,
    clinicDetailsError,
    orderList,
    fetchOrdersOfClinic,
  };
};

export default useClinicDetails;
