// Message.js for advertiser
import { useState, useEffect, useRef } from "react";
import useOrder from "../../../../hooks/data-hooks/advertiser/order/useOrder";
import Loader from "../../../common/components/loader/Loader";
import { debounce } from "lodash";
import { SearchIcon } from "../../../../utils/MyIcons";
import MessageItem from "./MessageItem";
import MessageBodyContent from "./MessageBodyContent";
import { useParams } from "react-router";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import useGetOrderDetailsById from "../../../../hooks/data-hooks/advertiser/order/useGetOrderDetailsById";

const Message = () => {
  const token = useSelector((state) => {
    return state.auth.user.accessToken;
  });
  // WebSocket server URL
  const WEBSOCKET_URL = `wss://l9wz3s3wz3.execute-api.us-east-2.amazonaws.com/development/?token=${token}`;
  const { id: orderId } = useParams();
  const [selectedOrder, setSelectedOrder] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [displayedMessages, setDisplayedMessages] = useState([]);
  const [ScrollToBottom, setScrollToBottom] = useState(true);
  const [newMessageSend, setNewMessageSend] = useState(false);
  const [nextMessageSize, setNextMessageSize] = useState(0);
  const [displayedInboxList, setDisplayedInboxList] = useState([]);
  const [isLoadedOnce, setIsLoadedOnce] = useState(false);
  const currentPage = 1;
  const pageSize = 0;
  const chatSize = 10; // items per chat
  const indexSize = 10; // items per index (set to minimum 10 for scroll to work)

  const chatContainerRef = useRef(null);
  const inboxContainerRef = useRef(null);

  const { data, refetchData } = useGetOrderDetailsById(orderId);
  const receiver = data?.documents?.influencerId?._id;
  const sender = data?.documents?.advertiserId?._id;
;
  useEffect(() => {
    refetchData();
  }, [orderId]);

  const [socket, setSocket] = useState(null); // WebSocket state
  const [messageInput, setMessageInput] = useState(""); // State for message input

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  // Fetch order list with params
  const {
    fetchChatMessage,
    orderList,
    isLoading,
    chatList,
    isChatLoading,
    fetchOrderListWithParams,
  } = useOrder(orderId, {
    pageNumber: currentPage,
    pageSize: pageSize,
    influencerName: searchTerm,
    sortField: "createdAt",
    sortDirection: "asc",
  });

  // ....................................................................

  // Handle message input change
  const handleInputChange = (event) => {
    setMessageInput(event.target.value);
  };
  // Handle message form submission
  const handleSubmitMessage = (event) => {
    event.preventDefault();
    setNewMessageSend(true);

    // If the input is empty, do nothing
    if (!messageInput.trim()) return;

    // Send message via WebSocket
    sendMessage(messageInput); // Use messageInput here

    // const newMessage = {
    //   content: messageInput,
    //   createdAt: new Date(),
    //   sender: { panel: "user", profileImageUrl: "" }, // Example user data
    // };

    // Add the message to the displayed messages
    // setDisplayedMessages([...displayedMessages, newMessage]);

    // Clear the input field
    setMessageInput("");

    // Scroll to bottom
    scrollToBottom();
  };

  // Scroll to the bottom of the chat
  const scrollToBottom = () => {
    if (chatContainerRef.current && newMessageSend) {
      setNewMessageSend(false);
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  // Call scrollToBottom whenever displayedMessages changes
  useEffect(() => {
    scrollToBottom();
  }, [displayedMessages]);

  // Set up WebSocket connection
  useEffect(() => {
    const ws = new WebSocket(WEBSOCKET_URL); // Replace with your WebSocket URL
    setSocket(ws);

    // Handle incoming messages
    ws.onmessage = (event) => {
      const newMessage = JSON.parse(event.data);
      setDisplayedMessages((prevMessages) => [...prevMessages, newMessage]);
    };

    // Clean up WebSocket connection on unmount
    return () => {
      ws.close();
    };
  }, []);

  // Send message via WebSocket
  const sendMessage = (messageContent) => {
    if (socket && sender && receiver && orderId) {
      const formattedMessage = {
        action: "sendMessage",
        sender: sender, // Influencer ID from data
        receiver: receiver, // Advertiser ID from data
        orderId: orderId, // Current order ID
        message: messageContent, // Actual message text
      };

      socket.send(JSON.stringify(formattedMessage));
    }
  };

  
  // ................................................................

  // Trigger API call with search term , when search term  changes
  useEffect(() => {
    fetchOrderListWithParams({
      pageNumber: currentPage,
      pageSize: pageSize,
      influencerName: searchTerm,
      sortField: "createdAt",
      sortDirection: "asc",
    });
  }, [searchTerm]);

  // Trigger API call with order id when order id changes
  useEffect(() => {
    fetchChatMessage();
  }, [orderId]);

  // Add message to selected order
  const handleAddMessage = (message) => {
    setIsLoadedOnce(true);
    setSelectedOrder(message);
    setScrollToBottom(true);
    setNextMessageSize(0);
  };

  // -----------------------------
  // Message Inbox start
  // Initially Display the latest inbox
  useEffect(() => {
    if (orderList && orderList?.length > 0) {
      const endIndex = Math.min(orderList?.length, indexSize);
      const latestIndexList = orderList?.slice(0, endIndex);
      setDisplayedInboxList(latestIndexList);
    }
  }, [orderList]);

  // Load more inbox function
  const handleLoadMoreInbox = () => {
    if (displayedInboxList?.length < orderList?.length) {
      const nextBatchStartIndex = displayedInboxList?.length;
      const nextBatchEndIndex = Math.min(
        nextBatchStartIndex + indexSize,
        orderList?.length
      );

      const nextInbox = orderList?.slice(nextBatchStartIndex, nextBatchEndIndex);
      const hasMoreInbox = nextInbox?.length > 0;
      if (hasMoreInbox) {
        setDisplayedInboxList((prevInbox) => [...prevInbox, ...nextInbox]);
      }
    }
  };

  // Scroll handler for Inbox
  const handleInboxScroll = () => {
    if (inboxContainerRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        inboxContainerRef?.current;
      if (scrollTop + clientHeight === scrollHeight) {
        handleLoadMoreInbox();
      }
    }
  };

  // end of Message Inbox
  // -----------------------------

  // ------------------------------
  // Chat Message Start
  // Initially Display the latest messages
  useEffect(() => {
    if (chatList && chatList?.length > 0) {
      const startIndex = Math.max(chatList?.length - chatSize, 0);
      const latestMessages = chatList?.slice(startIndex);
      setDisplayedMessages(latestMessages);
    }
  }, [chatList]);

  // keeping the chat scroll to the last seen Messages
  useEffect(() => {
    if (chatContainerRef?.current && newMessageSend === false) {
      chatContainerRef.current.scrollTop = nextMessageSize * 140;
    }
  }, [displayedMessages]);

  // Scroll to the bottom of the chat container on load
  useEffect(() => {
    if (chatContainerRef.current && ScrollToBottom) {
      chatContainerRef.current.scrollTop =
        chatContainerRef?.current?.scrollHeight;
    }
  }, [displayedMessages]);

  // Load more messages function
  const handleLoadMore = () => {
    setScrollToBottom(false);
    const nextBatchStartIndex = Math.max(
      chatList?.length - displayedMessages?.length - chatSize,
      0
    );
    let nextBatchEndIndex = nextBatchStartIndex + chatSize;
    if (nextBatchStartIndex === 0) {
      nextBatchEndIndex = chatList?.length - displayedMessages?.length;
    }
    const nextMessagesLength = nextBatchEndIndex - nextBatchStartIndex;
    setNextMessageSize(nextMessagesLength);
    const nextMessages = chatList?.slice(nextBatchStartIndex, nextBatchEndIndex);
    const hasMoreMessages = nextMessages?.length > 0;
    if (hasMoreMessages) {
      setDisplayedMessages((prevMessages) => [
        ...nextMessages,
        ...prevMessages,
      ]);
    }
  };

  // Scroll handler
  const handleScroll = () => {
    if (chatContainerRef?.current) {
      if (chatContainerRef?.current?.scrollTop === 0) {
        handleLoadMore();
      }
    }
  };

  // end of Chat Message
  // -----------------------------

  //  Form submit handler for search
  const onSubmitSearch = (data) => {
    if (isSubmitting) {
      return;
    }

    // Trigger API call with search term and filter status
    const { searchTerm } = data;
    setSearchTerm(searchTerm);
  };

  // Debounced form submission for input change
  const debouncedSubmit = debounce(onSubmitSearch, 1000);

  if (isLoading && !isLoadedOnce) return <Loader />;

  return (
    <div className="container">
      <Helmet>
        <title>Message | Advertiser | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <div className=" rounded shadow bg-secondary-color">
        <div className="row g-1 align-items-start ">
          {/* Message Inbox */}
          <div
            className=" col-6 col-lg-4   "
            onClick={() => {
              setIsLoadedOnce(true);
            }}
          >
            <form
              onSubmit={handleSubmit(onSubmitSearch)}
              className="bg-primary-color p-2"
            >
              {/* Inbox search */}
              <div className=" search border border-1 rounded-3 d-flex align-items-center bg-white  ">
                <span className="ps-1">
                  <SearchIcon />
                </span>
                <input
                  type="text"
                  className="rounded-3 p-2 w-100  border-0 bg-transparent  "
                  placeholder="Search By Influencer Name"
                  aria-label="Search"
                  name="searchTerm"
                  {...register("searchTerm")}
                  onChange={(event) =>
                    debouncedSubmit({
                      searchTerm: event.target.value,
                    })
                  }
                />
              </div>
            </form>
            {isLoading ? (
              <Loader />
            ) : (
              <div
                ref={inboxContainerRef}
                onScroll={handleInboxScroll}
                style={{ height: "calc(100vh - 270px)", overflowY: "auto" }}
                className="custom-scroll"
              >
                {displayedInboxList?.map((messageItem) => (
                  <MessageItem
                    key={messageItem?._id}
                    messageItem={messageItem}
                    handleAddMessage={handleAddMessage}
                    order={selectedOrder}
                    setDisplayedMessages={setDisplayedMessages}
                  />
                ))}
              </div>
            )}
          </div>

          {/* Chat container */}
          {orderId !== undefined && (
            <div className="col-6 col-lg-8 border-start ">
              <div className="d-flex flex-column w-100 justify-content-start">
                <h2 className="text-center bg-primary-color text-white py-2 ">
                  Chat
                </h2>
                {isChatLoading ? (
                  "Loading..."
                ) : chatList?.length === 0 ? (
                  <>
                    <div className="w-100 h-100 d-flex align-items-center justify-content-center ">
                      No Chat
                    </div>
                    <div
                      className="px-2 custom-scroll"
                      ref={chatContainerRef}
                      onScroll={handleScroll}
                      style={{
                        height: "calc(100vh - 270px)",
                        overflowY: "auto",
                      }}
                    >
                      {displayedMessages?.map((message, index) => (
                        <MessageBodyContent
                          key={message?._id}
                          message={message}
                          previousMessage={chatList[index - 1]}
                          currentIndex={index}
                          count={chatList?.length}
                        />
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    {/* Chat messages */}
                    <div
                      className="px-2 custom-scroll"
                      ref={chatContainerRef}
                      onScroll={handleScroll}
                      style={{
                        height: "calc(100vh - 270px)",
                        overflowY: "auto",
                      }}
                    >
                      {displayedMessages?.map((message, index) => (
                        <MessageBodyContent
                          key={message?._id}
                          message={message}
                          previousMessage={chatList[index - 1]}
                          currentIndex={index}
                          count={chatList?.length}
                        />
                      ))}
                    </div>
                  </>
                )}

                {/* Message input form */}
                <form
                  onSubmit={handleSubmitMessage}
                  className="mt-2 d-flex p-2 border-top"
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type your message..."
                    value={messageInput}
                    onChange={handleInputChange}
                  />
                  <button type="submit" className="btn btn-primary ms-2">
                    Send
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Message;
