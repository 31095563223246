import { useState } from "react";
import { bestLabSellersData } from "../../../../dummy-data/admin/pages/dashboard/dummy-data";
import { ArrowDownBlackIcon } from "../../../../utils/MyIcons";
import BestLabSellerItem from "./BestLabSellerItem";
import useLabListLogic from "../../../../hooks/data-hooks/admin/lab-management/useLabListLogic";
import { useNavigate } from "react-router";
import adminPrefixedRoutes from "../../../../routes/all-routes/adminRoutes";
import { FadeLoader } from "react-spinners";

const BestLabSeller = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleViewAll = () => {
    setOpen(!open);
        return navigate(adminPrefixedRoutes.INFLUENCER);

  };

  const {

    labList,
    isLoading,

  } = useLabListLogic();

if (isLoading) {
  return (
    <div className="d-flex align-items-center justify-content-center gap-4 bg-white w-100 h-100">
      <FadeLoader size={60} color="#df3459" />
    </div>
  );
}

  return (
    <div className="bg-white border rounded-4 vstack gap-0 h-100">
      <div className="d-flex justify-content-between align-items-center p-3 text-title">
        <span className="text-title fw-bold">Influencer List</span>
        <span onClick={() => handleViewAll()} className="fs-12 cursor-pointer">
          View all
          {/* <span
            className={`ms-2 ${open ? "arrow-collapsed" : "arrow-expanded"}`}
          >
            <ArrowDownBlackIcon />
          </span> */}
        </span>
      </div>
      <hr className="m-0 p-0" />
      <div className="d-flex justify-content-between align-items-center p-3 pb-0 fs-14 text-title">
        {/* <span>Influencer name</span> */}
        {/* <span>Total Expense</span> */}
      </div>
      <div className="dashboard-card-list custom-scroll vstack gap-2 p-3 fs-14 fw-lighter ">
        {labList.map((data) => {
          return <BestLabSellerItem key={data.id} data={data} />;
        })}
      </div>
    </div>
  );
};
export default BestLabSeller;
