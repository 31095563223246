import React from "react";
import { useNavigate } from "react-router";
import { webRoutes } from "../../../../routes/all-routes/webRoutes";
import { SocialIcons } from "../../../../utils/Socials";
import advertiserPrefixedRoutes from "../../../../routes/all-routes/advertiserRoutes";

const predefinedTags = {
  Technique: "#FF5F5F",
  Perfumes: "#5FAFFF",
  "Man's necessities": "#A95FFF",
  Fashion: "#FFC107", // Yellow
  Marketing: "#2196F3", // Blue
  Fitness: "#E91E63", // Pink
  Travel: "#9C27B0", // Purple
};

const InfluencerCard = ({
  id,
  image,
  name,
  categories = [],
  follower,
  tags = [],
  socials = [],
  featured ,
  advertiser,
}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    // navigate(`${webRoutes.INFLUENCERS}/${id}`);
    if (advertiser) {
      navigate(`${advertiserPrefixedRoutes.INFLUENCER_LIST}/${id}`);
    } else {
      navigate(`${webRoutes.INFLUENCERS}/${id}`);
    }
  };

  // Function to ensure the URL starts with https://
  const ensureCorrectUrl = (url) => {
    if (url?.startsWith("https//:")) {
      return url.replace("https//:", "https://");
    }
    return url;
  };

  return (
    <div className=" influencer-card p-4 mb-4 text-center  w-100   ">
      {featured && <div className="featured-badge">Featured</div>}

      <div className="image-container ">
        <img
          src={image}
          alt={name}
          className="influencer-img p-1 cursor-pointer"
          onClick={handleClick}
        />
      </div>

      <h3 className="fw-bold fs-5 mt-2 ">
        <span className="cursor-pointer" onClick={handleClick}>
          {name}
        </span>
      </h3>
      <div className="d-flex justify-content-center flex-wrap gap-2">
        {categories?.map((item, index) => {
          // const backgroundColor = predefinedTags[item] || "#007bff";
          return (
            <span
              key={index}
              className=" rounded-1 fw-semibold p-2 fs-14 "
              // style={{ backgroundColor }}
            >
              {item?.categoryName}
            </span>
          );
        })}
      </div>

      <p className="mb-4">
        <span className="fw-semibold ">{follower}</span>{" "}
        <span className="fs-12">Followers</span>
      </p>

      <div className="d-flex justify-content-center flex-wrap gap-2">
        {tags?.map((item, index) => {
          const backgroundColor = predefinedTags[item] || "#007bff";
          return (
            <span
              key={index}
              className="rounded-1 fw-medium px-2 py-1 fs-12 text-white "
              style={{ backgroundColor }}
            >
              {item?.tagName}
            </span>
          );
        })}
      </div>

      {/* Social Links with Icons */}
      <div className="d-flex justify-content-center gap-3 flex-wrap mt-4">
        {socials?.map((profile, index) => {
          const IconComponent =
            SocialIcons[profile?.socialPlatformName.toLowerCase()];
          const correctedUrl = ensureCorrectUrl(profile?.socialLink); // Ensure the URL is correct
          return (
            <a
              key={index}
              className="d-flex align-items-center gap-2 cursor-pointer"
              // href={profile?.socialLink}
              href={correctedUrl}
              
              rel="noopener noreferrer"
            >
              {IconComponent}
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default InfluencerCard;
