import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import authPrefixedRoutes from "../../../routes/all-routes/authRoutes";
import { useNavigate } from "react-router-dom";

const useOrderStatusChange = (orderId, refetchData) => {
  const token = useSelector((state) => state.auth.user.accessToken);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleResponseError = async (response) => {
    const data = await response.json();

    if (response.status === 401) {
      // Handle unauthorized (permission) errors

      toast.error(data.error.message);
    } else {
      // Default error message
      toast.error("Something went wrong!");
    }

    throw new Error(data.error.message || "Request failed");
  };

  const { mutate: orderStatusChangeToComplete } = useMutation({
    mutationKey: ["orderStatusChangeToComplete", orderId],
    mutationFn: async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/advertiser/order/complete`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ orderId }),
          }
        );

        if (!response.ok) {
          await handleResponseError(response);
        }

        const data = await response.json();
        toast.success("Order status changed to completed successfully!");
        setSubmitSuccess(true);
        return data;
      } catch (error) {
        setError(error);
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    onSuccess: () => {
      // Refetch data when the mutation is successful
      refetchData();
    },
    onError: (error) => {},
  });

  const { mutate: orderStatusChangeToCancel } = useMutation({
    mutationKey: ["orderStatusChangeToCancel", orderId],
    mutationFn: async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/advertiser/order/cancel`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ orderId }),
          }
        );

        if (!response.ok) {
          await handleResponseError(response);
        }

        const data = await response.json();
        toast.success("Order status changed to cancelled successfully!");
        setSubmitSuccess(true);
        return data;
      } catch (error) {
        setError(error);
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    onSuccess: () => {
      // Refetch data when the mutation is successful
      refetchData();
    },
    onError: (error) => {},
  });

  const { mutate: orderStatusChangeToProcessing } = useMutation({
    mutationKey: ["orderStatusChangeToProcessing", orderId],
    mutationFn: async () => {
      console.log("orderId", orderId);
      setIsLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/influencer/order-status-management`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              orderId,
              orderStatus: "confirmed",
            }),
          }
        );

        if (!response.ok) {
          await handleResponseError(response);
        }

        const data = await response.json();
        toast.success("Order status changed to confirmed successfully!");
        setSubmitSuccess(true);
        return data;
      } catch (error) {
        setError(error);
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    onSuccess: () => {
      // Refetch data when the mutation is successful
      refetchData();
    },
    onError: (error) => {},
  });


  return {
    orderStatusChangeToComplete,
    orderStatusChangeToCancel,
    orderStatusChangeToProcessing,
    submitSuccess,
    error,
    isLoading,
  };
};

export default useOrderStatusChange;
