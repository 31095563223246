import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";
import { toast } from "react-toastify";
import { useState } from "react";
import { setCartData } from "../../../../redux/slices/cartSlice";

const useUpdateCartItem = (refetchData) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.user.accessToken);
  const [isUpdatingCart, setIsUpdatingCart] = useState(false);

  const {
    mutate: updateCartItem,
    isLoading,
    isError,
    data,
  } = useMutation({
    mutationKey: "updateCartItem",
    mutationFn: async ({ cartItemId, quantity }) => {
      setIsUpdatingCart(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/advertiser/cart/update-item`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ cartItemId, quantity }),
        }
      );

      if (!response.ok) {
        const text = await response.json();
        if (text?.error?.status === 401) {
          navigate(authPrefixedRoutes.LOGIN);
        }
        throw new Error(
          text.message || "Failed to update item quantity in cart"
        );
      }

      const data = await response.json();
      toast.success("Item quantity updated successfully");
      // Dispatch action to set cart Item Count
      dispatch(setCartData({ cartItemCount: data.cartItemCount }));

      return data;
    },
    onSuccess: () => {
      // Refetch data when the mutation is successful
      refetchData();
      setIsUpdatingCart(false);
    },
    onError: (error) => {
      toast.error(error.message || "Something went wrong");
      setIsUpdatingCart(false);
    },
  });

  return {
    updateCartItem,
    isLoadingUpdate: isLoading,
    isErrorUpdate: isError,
    dataUpdated: data,
    isUpdatingCart,
  };
};

export default useUpdateCartItem;
