import React, { useEffect, useState } from "react";
import TransactionTable from "../../transaction/TransactionTable";
import useGetTransactionList from "../../../../../hooks/data-hooks/common/transaction/useGetTransactionList";

const UserInfo = () => {
  const [selectedLab, setSelectedLab] = useState("");
    const [pageSize, setPageSize] = useState(10); // items per page
    const [currentPage, setCurrentPage] = useState(1);
    const {
      fetchTransactionListWithParams,
      isLoadingTransactionList,
      setIsLoadingTransactionList,
      isErrorTransactionList,
      transactionList,
      transactionListMeta,
      transactionHistory,
      transactionListError,
      totalRows,
      setTotalRows,
    } = useGetTransactionList({
      pageNumber: currentPage,
      pageSize: pageSize,
      labId: selectedLab,
      sortField: "createdAt",
      sortDirection: "desc",
    });
  
    const handlePageChange = async (newPage) => {
      setCurrentPage(newPage);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
      setPageSize(newPerPage);
      setCurrentPage(page);
  };

  useEffect(() => {
    fetchTransactionListWithParams({
      pageNumber: currentPage,
      pageSize: pageSize,
      labId: selectedLab,
      sortField: "createdAt",
      sortDirection: "desc",
    });
  }, []);
  

  return (
    <div className="bg-white border rounded-4 vstack gap-2 px-0 py-3 h-100 table-responsive">
      <div className="">
        <div className="p-3">
          <h4>Recent Transactions</h4>
          <TransactionTable
            data={transactionList}
            meta={transactionListMeta}
            isLoading={isLoadingTransactionList}
            setIsLoading={setIsLoadingTransactionList}
            error={transactionListError}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            fetchTransactionListWithParams={fetchTransactionListWithParams}
            pageSize={pageSize}
            setPageSize={setPageSize}
            selectedLab={selectedLab}
            totalRows={totalRows}
            setTotalRows={setTotalRows}
            handlePageChange={handlePageChange}
            handlePerRowsChange={handlePerRowsChange}
          />
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
