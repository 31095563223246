import React from "react";
import { Logo, LogoInflux, LogoInfluxWhite } from "../../../../utils/WebIcons";

function SiteLogoContainer({ logoColor }) {
  return (
    <div className="d-flex h-40px align-items-center">
      <span className="icon-96-60 icon-primary">
        {logoColor === "white" ? <LogoInfluxWhite /> : <LogoInflux />}
      </span>
      <div className="px-1">
        <div
          className={`fs-32px font-family-squada   ${
            logoColor === "white" ? "font-color-white" : "font-color-title"
          }`}
        >
          {process.env.REACT_APP_NAME}
        </div>
        <div
          className={`fs-12px fw-semibold ${
            logoColor === "white" ? "font-color-white" : ""
          }`}
        >
          {process.env.REACT_APP_MOTTO}
        </div>
      </div>
    </div>
  );
}

export default SiteLogoContainer;
