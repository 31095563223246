// slices/cartSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Defaults to localStorage for web
import encryptionConfig from "../encryptionConfig";

const persistConfig = {
  key: "cart",
  storage,
  blacklist: [], // Add any state properties that you don't want to persist here
  ...encryptionConfig, // Integrate encryption configuration, if needed
};

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartItemCount: 0,
  },
  reducers: {
    setCartData: (state, action) => {
      const { cartItemCount } = action.payload;
      state.cartItemCount = cartItemCount;
    },
    clearCartData: (state) => {
      state.cartItemCount = 0;
    },
  },
});

const persistedCartReducer = persistReducer(persistConfig, cartSlice.reducer);

export const { setCartData, clearCartData } = cartSlice.actions;

export default persistedCartReducer;
