import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import useUpdateCategory from "../../../../hooks/data-hooks/admin/category/useUpdateCategory";
import useGetCategoryById from "../../../../hooks/data-hooks/admin/category/useGetCategoryById.js";
import Loader from "../../../common/components/loader/Loader";
import BackButton from "../../../../helpers/backButton.js";

const UpdateCategory = () => {
  const params = useParams();
  const { fetchCategoryWithId, categoryDetails, isLoading, isError } =
    useGetCategoryById(params.id);

  const {
    updateContent,
    isLoadingUpdate,
    isErrorUpdate,
    error,
    submitSuccess,
    isSubmitting,
  } = useUpdateCategory(params.id);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    // Set default values if data1 is available
    if (categoryDetails) {
      setValue("categoryName", categoryDetails?.data?.categoryName);
    }
  }, [categoryDetails]);

  useEffect(() => {
    // Show error toast if isError is true
    if (isError) {
      toast.error(error.message);
    } else if (isErrorUpdate) {
      toast.error(isErrorUpdate);
    }
  }, [isError, error, isErrorUpdate]);

  useEffect(() => {
    // Show success toast if submitSuccess is true
    if (submitSuccess) {
      toast.success("Form Submit Successful");
    }
  }, [submitSuccess]);

  const onSubmit = async (data) => {
    // Prevent form submission if there is an ongoing login process
    if (isSubmitting) return;

    try {
      // Call the custom hook for API call
      updateContent(data);
    } catch (error) {
      // Error handling is done in useEffect
    }
  };

  useEffect(() => {
    fetchCategoryWithId(params.id); // Fetch clinic details when clinicId changes
  }, [params.id]);

  if (isLoading) return <Loader />;
  return (
    <div className="container">
      <div className=" col-lg-6">
        <div className="cms-card ">
          <BackButton />
          <form className="cms-form mb-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="fs-24 fw-bold ">Update Category Form</div>

            <div className="mt-5">
              <div className="form-item">
                <label className="">Category Name</label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    className=""
                    placeholder="Enter category name"
                    {...register("categoryName", {
                      required: "Category name is required",
                    })}
                  />
                </div>
              </div>
            </div>

            <button type="submit" className="w-100 button-primary h-64px">
              {isSubmitting ? "Updating..." : "Update Category"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
export default UpdateCategory;
