// MessageBodyContent.js for influencer
import React from "react";
import { getFormattedTime } from "../../../../helpers/getFormattedTime";
import user from "./../../../../dummy-img/user.jpg";
import MyImage from "../../../../custom-components/MyImage";

const MessageBodyContent = ({
  message,
  previousMessage,
  count,
  currentIndex,
}) => {
  const { createdAt, content, sender } = message;

  const previousMessageCreatedAt = previousMessage
    ? previousMessage.createdAt
    : null;
  const timeDifference = previousMessageCreatedAt
    ? new Date(createdAt) - new Date(previousMessageCreatedAt)
    : null;
  const shouldDisplayImageAndTime =
    count === currentIndex + 1 ||
    timeDifference === null ||
    timeDifference > 10 * 60 * 1000;

  return (
    <div
      className={`d-flex align-items-center ${
        sender?.role === "influencer"
          ? "justify-content-start"
          : "justify-content-end"
      } ${shouldDisplayImageAndTime && "py-3 "}`}
    >
      <div
        className={`d-flex ${
          sender?.role === "influencer" ? "" : "flex-row-reverse"
        }`}
      >
        {shouldDisplayImageAndTime && (
          <MyImage
            className="rounded-circle mx-2"
            src={
              sender?.profileImageUrl !== null && sender?.profileImageUrl !== ""
                ? sender?.profileImageUrl
                : user
            }
            addBaseUrl={false}
            style={{ width: "50px", height: "50px" }}
            alt=""
          />
        )}
        <div className="pb-3 ms-1">
          <p className="border rounded-top rounded-end  p-3 mb-0">{content}</p>
          {shouldDisplayImageAndTime && (
            <div className="">
              <p>
                <small>{getFormattedTime(createdAt)}</small>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default MessageBodyContent;
