import { useState } from "react";
import BestLabSellerItem from "./BestLabSellerItem";
import { useNavigate } from "react-router";
import useFetchData from "../../../../../hooks/data-hooks/common/useFetchData";
import advertiserPrefixedRoutes from "../../../../../routes/all-routes/advertiserRoutes";
import { RiseLoader } from "react-spinners";

const BestLabSeller = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleViewAll = () => {
    setOpen(!open);
    return navigate(advertiserPrefixedRoutes.INFLUENCER_LIST);
  };

  const [currentPage, setCurrentPage] = useState(1); // Initial page
  // const influencersPerPage = 4; // Number of influencers to display per page
  const perPage = 10;
  const [filters, setFilters] = useState({
    search: "",
  });

  // Fetch Influencer data
  const { data: mainData, isLoading: isFetchingData } = useFetchData(
    "/api/public/influencer-search",
    "influencerKeyBest",
    currentPage,
    perPage,
    filters
  );

  if (isFetchingData) {
    return (
      <div className="d-flex align-items-center justify-content-center gap-4 bg-white w-100 h-100">
        <RiseLoader size={10} color="#df3459" />
      </div>
    );
  }

  return (
    <div className="bg-white border rounded-4 vstack gap-0 h-100">
      <div className="d-flex justify-content-between align-items-center p-3 text-title">
        <span className="text-title fw-bold">Influencer List</span>
        <span onClick={() => handleViewAll()} className="fs-12 cursor-pointer">
          View all
          {/* <span
            className={`ms-2 ${open ? "arrow-collapsed" : "arrow-expanded"}`}
          >
            <ArrowDownBlackIcon />
          </span> */}
        </span>
      </div>
      <hr className="m-0 p-0" />
      <div className="d-flex justify-content-between align-items-center p-3 pb-0 fs-14 text-title">
        {/* <span>Influencer name</span> */}
        {/* <span>Total Expense</span> */}
      </div>
      <div className="dashboard-card-list custom-scroll vstack gap-2 p-3 fs-14 fw-lighter ">
        {mainData?.data?.map((data) => {
          return <BestLabSellerItem key={data.id} data={data} />;
        })}
      </div>
    </div>
  );
};
export default BestLabSeller;
