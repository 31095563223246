// AdvertiserRouter.js
import React from "react";
import { Routes, Route } from "react-router-dom";

// Import Advertiser panel components

import AdvertiserLayout from "../modules/advertiser/components/layout/AdvertiserLayout";
import AdvertiserDashboard from "../modules/advertiser/components/pages/Dashboard";
import AdvertiserReports from "../modules/advertiser/components/pages/Reports";
import AdvertiserAuthGuard from "../hocs/layout-guards/AdvertiserAuthGuard";
import { advertiserRoutes } from "./all-routes/advertiserRoutes";
import InfluencerList from "../modules/advertiser/components/pages/InfluencerList";
import OrderPlacement from "../modules/advertiser/components/pages/OrderPlacement";
import OrderTracking from "../modules/advertiser/components/pages/OrderTracking";
import Message from "../modules/advertiser/components/message/Message";
import Rating from "../modules/advertiser/components/pages/Rating";
import AdvertiserSettings from "../modules/advertiser/components/pages/AdvertiserSettings";
import OrderPlacementDetails from "../modules/advertiser/components/pages/OrderPlacementDetails";
import InfluencerListDetails from "../modules/advertiser/components/pages/InfluencerListDetails";
import OrderTrackingDetails from "../modules/advertiser/components/pages/OrderTrackingDetails";
import Notifications from "../modules/advertiser/components/notifications/Notifications";
import Cart from "../modules/advertiser/components/cart/Cart";
import Feedback from "../modules/advertiser/components/feedback/Feedback";
import CreateFeedback from "../modules/advertiser/components/pages/CreateFeedback";
import TransactionDetails from "../modules/advertiser/components/pages/TransactionDetails";
import Transaction from "../modules/advertiser/components/pages/Transaction";

const AdvertiserRouter = () => {
  return (
    <AdvertiserAuthGuard>
      <AdvertiserLayout>
        <Routes>
          <Route
            path={advertiserRoutes.DASHBOARD}
            element={<AdvertiserDashboard />}
          />
          <Route
            path={advertiserRoutes.INFLUENCER_LIST}
            element={<InfluencerList />}
          />
          <Route
            path={`${advertiserRoutes.INFLUENCER_LIST}/:id`}
            element={<InfluencerListDetails />}
          />

          <Route
            path={`${advertiserRoutes.CREATE_FEEDBACK}/:id`}
            element={<CreateFeedback />}
          />

          <Route
            path={advertiserRoutes.ORDER_PLACEMENT}
            element={<OrderPlacement />}
          />
          <Route
            path={`${advertiserRoutes.ORDER_PLACEMENT}/:id`}
            element={<OrderPlacementDetails />}
          />

          <Route
            path={advertiserRoutes.ORDER_TRACKING}
            element={<OrderTracking />}
          />
          <Route
            path={`${advertiserRoutes.ORDER_TRACKING}/:id`}
            element={<OrderTrackingDetails />}
          />

          {/* Notifications - Cart */}
          <Route
            path={`${advertiserRoutes.NOTIFICATIONS}/:id`}
            element={<Notifications />}
          />
          <Route path={advertiserRoutes.CART} element={<Cart />} />

          <Route path={advertiserRoutes.MESSAGE} element={<Message />} />
          <Route
            path={`${advertiserRoutes.MESSAGE}/:id`}
            element={<Message />}
          />

          <Route path={advertiserRoutes.FEEDBACK} element={<Feedback />} />
          <Route
            path={advertiserRoutes.ADVERTISER_SETTINGS}
            element={<AdvertiserSettings />}
          />
          <Route
            path={advertiserRoutes.TRANSACTION}
            element={<Transaction />}
          />
          <Route
            path={`${advertiserRoutes.TRANSACTION}/:id`}
            element={<TransactionDetails />}
          />
        </Routes>
      </AdvertiserLayout>
    </AdvertiserAuthGuard>
  );
};

export default AdvertiserRouter;
