import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { EyeOpenIcon, EyeCloseIcon } from "../../../../utils/WebIcons";
import usePasswordToggle from "../../../../hooks/ui-hooks/usePasswordToggle";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import useClinicSignUp from "../../../../hooks/data-hooks/auth/useClinicSignUp";
import useGetCountryCode from "../../../../hooks/data-hooks/auth/useGetCountryCode";
import { useForm, Controller } from "react-hook-form";
import { ArrowDownBlackIcon } from "../../../../utils/MyIcons";
import Loader from "../../../common/components/loader/Loader";
import Select from "react-select";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";
import { DotLoader } from "react-spinners";

function ClinicSignUp() {
  const [getPasswordInputType, getPasswordIcon, togglePasswordVisibility] =
    usePasswordToggle();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    reset,
    control,
  } = useForm();

  const {
    signUpClinic,
    isError,
    signupError,
    submitSuccess,
    signupSuccessResponse,
    isLoadingSignup,
  } = useClinicSignUp();

  const {
    isCountryCodeLoading,
    isCountryCodeError,
    countryCodeData,
    sortedCountryCode,
  } = useGetCountryCode();

  useEffect(() => {
    // Show error toast if isError is true
    if (isError) {
      toast.error(signupError.message);
    }
  }, [isError, signupError]);

  useEffect(() => {
    // Show success toast if submitSuccess is true
    if (submitSuccess) {
      reset();
      toast.success(signupSuccessResponse.message);
    }
  }, [submitSuccess, signupSuccessResponse]);

  const onSubmit = async (data) => {
    // console.log(data);
    // Prevent form submission if there is an ongoing sign-up process
    if (isSubmitting) return;

    // // country code validation
    // const countryCode = data.countryCode.value;
    // // Validate the extracted country code
    // if (!sortedCountryCode.includes(countryCode)) {
    //   toast.error("Please select a valid country code from the list");
    //   return;
    // }
    // const formData = { ...data, countryCode };
    // console.log(formData);
    try {
      // Call the custom hook for clinic sign-up
      signUpClinic(data);
    } catch (error) {
      // Error handling is done in useEffect
    }
  };

  // Watch the password field to get its value
  const password = watch("password", ""); // Provide a default value in case the field is not found

  // Custom validation function to check if passwords match
  const validatePasswordMatch = (value) => {
    return value === password || "Passwords do not match";
  };

  if (isCountryCodeLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>Sign Up | Advertiser | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <div className="auth-card px-4 px-sm-5 py-5">
        <div className="mb-2">
          <div className="fs-24px fw-light mb-1">Welcome !</div>
          <div className="title-32 fw-bold mb-2">
            Sign up{" "}
            <span className="fs-18px fw-normal">
              as <span className="text-primary-color">Advertiser</span>
            </span>{" "}
          </div>
          {/* <div className="fs-18px">
            as <span className="text-primary-color">Advertiser</span>
          </div> */}
        </div>
        <form
          className="web-form sign-up-form mb-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* Full Name */}
          <div className="form-item">
            <label htmlFor="inputName" className="">
              Full Name
            </label>
            <div className="input-wrapper">
              <input
                type="text"
                className=""
                placeholder="Enter your Full Name"
                {...register("fullName", {
                  required: "Full Name is required",
                })}
              />
            </div>
            {errors.fullName && (
              <div className="text-danger">{errors.fullName.message}</div>
            )}
          </div>

          {/* Email */}
          <div className="form-item">
            <label htmlFor="inputEmail" className="">
              Email address
            </label>
            <div className="input-wrapper">
              <input
                type="email"
                className=""
                placeholder="Enter email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Invalid email address",
                  },
                })}
              />
            </div>
            {errors.email && (
              <div className="text-danger">{errors.email.message}</div>
            )}
          </div>

          {/* Country Code */}
          {/* <div className="form-item">
            <label htmlFor="countryCode" className="">
              Country Code
            </label>
            <Controller
              name="countryCode"
              control={control}
              rules={{ required: "Country code is required" }}
              render={({ field }) => (
                <Select
                  {...field}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "gray",
                      paddingLeft: "20px",
                      fontWeight: "400",
                      boxShadow: "none",
                    }),
                    option: (baseStyles) => ({
                      ...baseStyles,
                      fontWeight: "400",
                    }),
                  }}
                  defaultValue={{
                    label: "Select...",
                    value: "Select...",
                  }}
                  isClearable
                  isSearchable
                  options={sortedCountryCode.map((code) => ({
                    label: code,
                    value: code,
                  }))}
                />
              )}
            />
            {errors.countryCode && (
              <div className="text-danger">{errors.countryCode.message}</div>
            )}
          </div> */}

          {/* Phone Number */}
          <div className="form-item">
            <label htmlFor="inputPhone" className="">
              Phone Number
            </label>
            <div className="input-wrapper">
              <input
                type="text"
                className=""
                placeholder="Enter phone number"
                {...register("phoneNumber", {
                  required: "Phone number is required",
                  minLength: {
                    value: 6,
                    message: "Phone number must be at least 6 digits long",
                  },
                  maxLength: {
                    value: 22,
                    message: "Phone number must be at most 22 digits long",
                  },
                  pattern: {
                    value: /^[0-9]*$/,
                    message: "Invalid phone number",
                  },
                })}
              />
            </div>
            {errors.phoneNumber && (
              <div className="text-danger">{errors.phoneNumber.message}</div>
            )}
          </div>

          {/* Password */}
          <div className="form-item">
            <label htmlFor="inputPassword" className="">
              Password
            </label>
            <div className="input-wrapper position-relative">
              <input
                type={getPasswordInputType()}
                className=""
                placeholder="Enter password"
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters long",
                  },
                })}
              />
              <span
                onClick={togglePasswordVisibility}
                className="cursor-pointer position-absolute top-50 icon-end translate-middle-y"
              >
                {getPasswordIcon() === "eye-open" ? (
                  <EyeOpenIcon />
                ) : (
                  <EyeCloseIcon />
                )}
              </span>
            </div>
            {errors.password && (
              <div className="text-danger">{errors.password.message}</div>
            )}
          </div>
          {/* Confirm Password */}
          <div className="form-item">
            <label htmlFor="inputPassword" className="">
              Confirm Password
            </label>
            <div className="input-wrapper position-relative">
              <input
                type={getPasswordInputType()}
                className=""
                placeholder="Confirm password"
                {...register("confirmPassword", {
                  required: "Confirm password is required",
                  validate: validatePasswordMatch, // Add custom validation function
                })}
              />
              <span
                onClick={togglePasswordVisibility}
                className="cursor-pointer position-absolute top-50 icon-end translate-middle-y"
              >
                {getPasswordIcon() === "eye-open" ? (
                  <EyeOpenIcon />
                ) : (
                  <EyeCloseIcon />
                )}
              </span>
            </div>
            {errors.confirmPassword && (
              <div className="text-danger">
                {errors.confirmPassword.message}
              </div>
            )}
          </div>

          {/* Submit button */}
          <button
            type="submit"
            className="w-100 button-primary h-64px"
            disabled={isLoadingSignup}
          >
            {isLoadingSignup ? (
              <div className="d-flex align-items-center justify-content-center gap-4">
                <DotLoader size={20} color="#fff" />
                <span>Singing Up...</span>
              </div>
            ) : (
              "Sign Up"
            )}
       
          </button>
        </form>

        {/* Footer */}
        <div className="text-center">
          <div>
            Already have an Account ?{" "}
            <NavLink
              to={authPrefixedRoutes.LOGIN}
              className="text-decoration-none font-color-primary font-bold"
            >
              Log In
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClinicSignUp;
