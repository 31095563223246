import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Loader from "../../../common/components/loader/Loader";
import BackButton from "../../../../helpers/backButton.js";
import useUpdatePackage from "../../../../hooks/data-hooks/influencer/package/useUpdatePackage.js";
import useGetPackageById from "../../../../hooks/data-hooks/influencer/package/useGetPackageById.js";
import { platformList, typeList } from "../../../../utils/Socials";


const UpdatePackage = () => {
  const params = useParams();
  const { fetchPackageWithId, packageDetails, isLoading, isError } =
    useGetPackageById(params.id);

  const {
    updateContent,
    isLoadingUpdate,
    isErrorUpdate,
    error,
    submitSuccess,
    isSubmitting,
  } = useUpdatePackage(params.id);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    } = useForm();
    

  useEffect(() => {
    // Set default values if data1 is available
    if (packageDetails) {
        setValue("packageName", packageDetails?.packageName);
        setValue("description", packageDetails?.description);
        setValue("socialPlatformName", packageDetails?.socialPlatformName);
        setValue("duration", packageDetails?.duration);
        setValue("typeOfPackage", packageDetails?.typeOfPackage);
        setValue("price", packageDetails?.price);
    }
  }, [packageDetails]);

  useEffect(() => {
    // Show error toast if isError is true
    if (isError) {
      toast.error(error.message);
    } else if (isErrorUpdate) {
      toast.error(isErrorUpdate);
    }
  }, [isError, error, isErrorUpdate]);

  useEffect(() => {
    // Show success toast if submitSuccess is true
    if (submitSuccess) {
      toast.success("Form Submit Successful");
    }
  }, [submitSuccess]);

  const onSubmit = async (data) => {
    // Prevent form submission if there is an ongoing login process
    if (isSubmitting) return;

    try {
      // Call the custom hook for API call
      updateContent(data);
    } catch (error) {
      // Error handling is done in useEffect
    }
  };

  useEffect(() => {
    fetchPackageWithId(params.id); // Fetch clinic details when clinicId changes
  }, [params.id]);

  if (isLoading) return <Loader />;
  return (
    <div className="container">
      <div className=" col-lg-6">
        <div className="cms-card ">
          <BackButton />
          <form className="cms-form mb-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="fs-24 fw-bold">Update Package Details</div>

            <div className="mt-5">
              {/* Package Name */}
              <div className="form-item">
                <label className="">Package Name</label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    className=""
                    placeholder="Enter Package name"
                    {...register("packageName", {
                      required: "Package name is required",
                    })}
                  />
                  {errors.packageName && (
                    <span className="error">{errors.packageName.message}</span>
                  )}
                </div>
              </div>

              {/* Description */}
              <div className="form-item">
                <label className="">Description</label>
                <div className="input-wrapper">
                  <textarea
                    rows={5}
                    type="text"
                    className=""
                    placeholder="Enter description"
                    {...register("description", {
                      required: "Description is required",
                    })}
                  />
                  {errors.description && (
                    <span className="error">{errors.description.message}</span>
                  )}
                </div>
              </div>

              {/* social Platform Name */}
              <div className="form-item">
                <label className="">Platform Name</label>
                <div className="input-wrapper">
                  <select
                    {...register("socialPlatformName", {
                      required: "Platform Name is required",
                    })}
                  >
                    <option value="">Select Platform</option>
                    {platformList.map((platform, index) => (
                      <option key={index} value={platform}>
                        {platform}
                      </option>
                    ))}
                  </select>
                </div>
                {errors.socialPlatformName && (
                  <p className="text-danger">
                    {errors.socialPlatformName.message}
                  </p>
                )}
              </div>

              {/* Duration */}
              <div className="form-item">
                <label className="">Duration</label>
                <div className="input-wrapper">
                  <input
                    type="number"
                    className=""
                    placeholder="Enter Duration"
                    {...register("duration", {
                      required: "Duration is required",
                      min: {
                        value: 1,
                        message: "Duration must be at least 1 day",
                      },
                    })}
                  />
                  {errors.duration && (
                    <span className="error text-danger ">
                      {errors.duration.message}
                    </span>
                  )}
                </div>
              </div>

              {/* type Of Package */}
              <div className="form-item">
                <label className="">Package Type</label>
                <div className="input-wrapper">
                  <select
                    {...register("typeOfPackage", {
                      required: "Package Type is required",
                    })}
                  >
                    <option value="">Select Type</option>
                    {typeList.map((type, index) => (
                      <option key={index} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
                {errors.typeOfPackage && (
                  <p className="text-danger">{errors.typeOfPackage.message}</p>
                )}
              </div>

              {/* price */}
              <div className="form-item">
                <label className="">Price</label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    className=""
                    placeholder="Enter Price"
                    {...register("price", {
                      required: "Price is required",
                      validate: (value) => {
                        const isValid = /^[1-9]\d*$/.test(value);
                        return (
                          isValid || "Price must be a number and more than 0"
                        );
                      },
                    })}
                  />
                  {errors.price && (
                    <p className="text-danger">{errors.price.message}</p>
                  )}
                </div>
              </div>
            </div>

            <button type="submit" className="w-100 button-primary h-64px">
              {isSubmitting ? "Updating..." : "Update Package"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
export default UpdatePackage;
