import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom"; //for routing
import { ProgressTracker } from "@atlaskit/progress-tracker"; //for progress bar
import useGetOrderDetailsById from "../../../../hooks/data-hooks/admin/order/useGetOrderDetailsById"; //custom hook to call api
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import MyImage from "../../../../custom-components/MyImage";
import Loader from "../../../common/components/loader/Loader";
import adminPrefixedRoutes from "../../../../routes/all-routes/adminRoutes";
import MessageBadge from "../message/MessageBadge";
import { StlViewer } from "react-stl-viewer";
import { Document, Page, pdfjs } from "react-pdf";
import SinglePagePDFViewer from "../orderDetails/SinglePagePDFViewer";
import pdf from "../../../../assets/pdf/lorem_ipsum.pdf";
import { getFormattedDate } from "../../../../helpers/getFormattedDate";
import BackButton from "../../../../helpers/backButton";
import OrderProgressStepper from "../../../common/components/OrderProgressStepper";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const pdfUrl =
  "https://mena-medica-file-bucket.s3.me-central-1.amazonaws.com/1714482478663-8vvbb2udddyt.pdf";
const url =
  "https://storage.googleapis.com/ucloud-v3/ccab50f18fb14c91ccca300a.stl";

// const CustomSwiperMini = ({ data, setIndex }) => {
//   return (
//     <Swiper
//       modules={[Navigation, Pagination, Scrollbar, A11y]}
//       spaceBetween={10}
//       slidesPerView={1}
//       centeredSlides={true}
//       autoplay={{
//         delay: 1000,
//         disableOnInteraction: false,
//       }}
//       // navigation={true}
//       // pagination={{ clickable: true }}
//       scrollbar={{ draggable: true }}
//       //onSwiper={(swiper) => console.log(swiper)}
//       // onSlideChange={() => console.log("slide change")}
//     >
//       {data?.map((item, i) => (
//         <SwiperSlide key={JSON.stringify(item)}>
//           <div className=" d-flex justify-content-center align-items-center my-2">
//             {/* --------------------------[   2   ] */}
//             <MyImage
//               src={item?.productId?.images[0]}
//               addBaseUrl={true}
//               className=" w-h-50px-max-min border rounded-3 cursor-pointer"
//               alt="Product_image"
//               onClick={() => setIndex(i)}
//             />
//             {/* --------------------------*/}
//           </div>
//         </SwiperSlide>
//       ))}
//     </Swiper>
//   );
// };

const style = {
  top: 0,
  left: 0,
  width: "200px",
  height: "100%",
};


const OrderDetails = () => {
  const { id } = useParams();
  //  console.log(id);
  const { data1, isLoading1, isError1, fetchOrderDetailsById } =
    useGetOrderDetailsById(id);
  useEffect(() => {
    fetchOrderDetailsById();
  }, [id]);



  const columns = useMemo(
    () => [
      {
        name: "Package Name",
        selector: (row) => (
          <Link to={`${adminPrefixedRoutes.PACKAGES}/${row?.packageId?._id}`} className="link-style">{row?.packageId?.packageName}</Link>
        ),
        sortable: true,
      },

      {
        name: "Platform",
        selector: (row) => <>{row?.packageId?.socialPlatformName}</>,
        sortable: true,
      },
      {
        name: "Type",
        selector: (row) => <>{row?.packageId?.typeOfPackage}</>,
        sortable: true,
      },
      {
        name: "Item Price",
        selector: (row) => <>{row?.packageId?.price}</>,
        sortable: true,
      },
      {
        name: "Quantity",
        selector: (row) => <>{row?.quantity}</>,
        sortable: true,
      },
      {
        name: "Total Price",
        selector: (row) => <>{row?.totalPrice}</>,
        sortable: true,
      },
    ],
    []
  );

  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "var(--primary-color)",
        color: "#fff",
      },
    },
  };



  console.log(data1?.data);

  if (isLoading1) return <Loader />;

  return (
    <div className="container">
      <BackButton />

      {/* second part */}
      <div className="my-3  shadow bg-white p-3 border rounded-3 vstack  ">
        <h4 className="color-title-color fw-bold mb-3 ">Advertiser Details</h4>

        <div className="row p-3 ">
          <div className="col pe-3  ">
            <p>
              <span className="fw-bold">Name : </span>
              {data1?.data?.advertiserId?.fullName}
            </p>
            <p>
              <span className="fw-bold">Email : </span>
              {data1?.data?.advertiserId?.email}
            </p>
          </div>
          <div className=" col pe-3">
            <p>
              <span className="fw-bold  ">Order ID: </span>
              {data1?.data?.orderNumber}
            </p>
            <p>
              <span className="fw-bold"> Order Placed: </span>
              {getFormattedDate(data1?.data?.createdAt)}
            </p>
          </div>
        </div>
        {/* Order Progress Stepper */}
        <OrderProgressStepper
          orderId={data1?.data?._id}
          orderStatus={data1?.data?.orderStatus}
          paymentStatus={data1?.data?.paymentStatus}

        />
        <Link
          to={`${adminPrefixedRoutes.MESSAGE}/${data1?.data?._id}`}
          className="link-style"
        >
          <div className="bg-highlight rounded-3 p-3 border fs-16 text-center mt-2">
            <span>View messages : </span>
            <MessageBadge messageCount={data1?.data?.totalMessages} />{" "}
          </div>
        </Link>

        <div className="mt-4">
          {/* <h4>Order Detail Documents </h4> */}
          {/* <StlViewer style={style} orbitControls shadows url={url} /> */}

          <div>
            {/* <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} />
            </Document>
            <p>
              Page {pageNumber} of {numPages}
            </p> */}
            {/* <SinglePagePDFViewer /> */}
          </div>
        </div>
      </div>

      {/* third part */}
      <div className="my-3  shadow bg-white p-3 border rounded-3">
        <DataTable
          title="List of Packages"
          customStyles={tableHeaderstyle}
          columns={columns}
          data={data1?.data?.orderItems}
          progressPending={isLoading1}
          fixedHeader
          selectableRowsHighlight
          highlightOnHover
          // onSelectedRowsChange={({ selectedRows }) => console.log(selectedRows)}
        />
      </div>
    </div>
  );
};
export default OrderDetails;
