import React from "react";
import {
  HashLoader,
  RingLoader,
  GridLoader,
  ClimbingBoxLoader,
  CircleLoader,
} from "react-spinners";

const Loader = ({ transparent = false }) => {
  return (
    <div className="w-100 vh-100 d-flex align-items-center justify-content-center">
      <GridLoader color="#DF3458" size={30} />
    </div>
  );
};

export default Loader;
