import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";

const useGetTopPackages = () => {
  const token = useSelector((state) => state.auth.user.accessToken);

  const { isLoading, isError, data } = useQuery({
    queryKey: ["topPackages"],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/admin//package/all?pageSize=10&pageNumber=1`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        return;
      }
      const data = await response.json();
      return data;
    },
  });

  useEffect(() => {}, []);

  return { isLoading, isError, data };
};

export default useGetTopPackages;
