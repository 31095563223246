import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom"; //for routing
import { ProgressTracker } from "@atlaskit/progress-tracker"; //for progress bar
import useGetOrderDetailsById from "../../../../hooks/data-hooks/advertiser/order/useGetOrderDetailsById"; //custom hook to call api
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import MyImage from "../../../../custom-components/MyImage";
import Loader from "../../../common/components/loader/Loader";
import advertiserPrefixedRoutes from "../../../../routes/all-routes/advertiserRoutes";
// import MessageBadge from "../message/MessageBadge";
import { StlViewer } from "react-stl-viewer";
import { Document, Page, pdfjs } from "react-pdf";
// import SinglePagePDFViewer from "../orderDetails/SinglePagePDFViewer";
// import pdf from "../../../../assets/pdf/lorem_ipsum.pdf";
import { getFormattedDate } from "../../../../helpers/getFormattedDate";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

// const pdfUrl =
//   "https://mena-medica-file-bucket.s3.me-central-1.amazonaws.com/1714482478663-8vvbb2udddyt.pdf";
// const url =
//   "https://storage.googleapis.com/ucloud-v3/ccab50f18fb14c91ccca300a.stl";

// const CustomSwiperMini = ({ data, setIndex }) => {
//   return (
//     <Swiper
//       modules={[Navigation, Pagination, Scrollbar, A11y]}
//       spaceBetween={10}
//       slidesPerView={1}
//       centeredSlides={true}
//       autoplay={{
//         delay: 1000,
//         disableOnInteraction: false,
//       }}
//       // navigation={true}
//       // pagination={{ clickable: true }}
//       scrollbar={{ draggable: true }}
//       //onSwiper={(swiper) => console.log(swiper)}
//       // onSlideChange={() => console.log("slide change")}
//     >
//       {data?.map((item, i) => (
//         <SwiperSlide key={JSON.stringify(item)}>
//           <div className=" d-flex justify-content-center align-items-center my-2">
//             {/* --------------------------[   2   ] */}
//             <MyImage
//               src={item?.productId?.images[0]}
//               addBaseUrl={true}
//               className=" w-h-50px-max-min border rounded-3 cursor-pointer"
//               alt="Product_image"
//               onClick={() => setIndex(i)}
//             />
//             {/* --------------------------*/}
//           </div>
//         </SwiperSlide>
//       ))}
//     </Swiper>
//   );
// };

const style = {
  top: 0,
  left: 0,
  width: "200px",
  height: "100%",
};

const OrderTrackingDetails = () => {
  const [index, setIndex] = useState(0);
  const { id } = useParams();
  //  console.log(id);
  const { data1, isLoading1, isError1, fetchOrderDetailsById } =
    useGetOrderDetailsById(id);
  useEffect(() => {
    fetchOrderDetailsById();
  }, [id]);

  const items = [
    {
      id: "1",
      label: `Order : ${data1?.data?.orderStatus}`,
      percentageComplete: data1?.data?.orderStatus === "confirmed" ? 100 : 0,
    },
    {
      id: "2",
      label: `Payment : ${data1?.data?.paymentStatus}`,
      percentageComplete: data1?.data?.paymentStatus === "confirmed" ? 100 : 0,
    },
    // {
    //   id: "3",
    //   label: `Delivery : ${data1?.data?.deliveryStatus}`,
    //   percentageComplete: data1?.data?.deliveryStatus === "confirmed" ? 100 : 0,
    // },
  ];

  const columns = useMemo(
    () => [
      {
        name: "Package Name",
        selector: (row) => (
          <>
            {/* <MyImage
              src={row?.packageId.images[0]}
              addBaseUrl={true}
              className="rounded-4 p-2 w-h-50px  "
              alt=""
            /> */}

            {row?.packageId?.packageName}
          </>
        ),
        sortable: true,
      },

      {
        name: "Platform",
        selector: (row) => <>{row?.packageId?.socialPlatformName}</>,
        sortable: true,
      },
      {
        name: "Type",
        selector: (row) => <>{row?.packageId?.typeOfPackage}</>,
        sortable: true,
      },
      {
        name: "Item Price",
        selector: (row) => <>{row?.packageId?.price}</>,
        sortable: true,
      },
      {
        name: "Quantity",
        selector: (row) => <>{row?.quantity}</>,
        sortable: true,
      },
      {
        name: "Total Price",
        selector: (row) => <>{row?.totalPrice}</>,
        sortable: true,
      },
    ],
    []
  );

  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "var(--primary-color)",
        color: "#fff",
      },
    },
  };

  // const headerComponent = React.useMemo(() => {
  //   return (
  //     <div className="d-flex align-items-center gap-4 ">
  //       Order ID : {Math.floor(Math.random() * 100000 + 1)}
  //     </div>
  //   );
  // }, []);
  // const shippingAddress = JSON.parse(data1?.data?.shippingAddress || "{}");

  console.log(data1?.data);

  if (isLoading1) return <Loader />;

  return (
    <div className="container">
      {/* first part */}
      {/* <div className="my-3 shadow bg-white p-3 border rounded-3 d-flex ">

        <div className=" py-4 px-4 d-flex justify-content-center flex-column align-items-center w-300px ">
          <MyImage
            src={data1?.data?.orderItems[index]?.productId?.images[0]}
            addBaseUrl={true}
            className="w-h-250px rounded-3 border "
            alt=""
          />
          <CustomSwiperMini
            data={data1?.data?.orderItems}
            setIndex={setIndex}
          />
        </div>

        
        <div className=" py-4 px-4">
          <h5 className="color-title-color fw-bold mb-3 ">
            {data1?.data?.orderItems[index]?.productId?.name}
          </h5>

          <div className="">
            <p>
              Quantity:
              <span className="fw-bold ms-2">
                {data1?.data?.orderItems[index]?.quantity}
              </span>
            </p>
          </div>
          <div className="">
            <p>
              Price:
              <span className="fw-bold ms-2">
                {data1?.data?.orderItems[index]?.price}
              </span>
            </p>
          </div>
          <div>
            <Link
              to={`${adminPrefixedRoutes.USERS_LAB_DETAILS}/${data1?.data?.labId?._id}/${data1?.data?.labId?.labName}`}
              className="link-style"
            >
              <button className="btn btn-1">View Lab</button>
            </Link>
            <Link
              to={`/admin/products/product-list/${data1?.data?.orderItems[index]?.productId?._id}`}
              className="link-style"
            >
              <button className="btn btn-2">View Product</button>
            </Link>
          </div>
          <div className="bg-highlight rounded-3 p-2 border  fs-16 text-center   ">
            <span className="fw-bold  ">Lab Name : </span>
            {data1?.data?.labId?.labName}
          </div>

          <Link
            to={`${adminPrefixedRoutes.MESSAGE}/${data1?.data?._id}`}
            className="link-style"
          >
            <div className="bg-highlight rounded-3 p-3 border fs-16 text-center mt-2">
              <span>View messages : </span>
              <MessageBadge messageCount={data1?.data?.totalMessages} />{" "}
            </div>
          </Link>
        </div>
      </div> */}

      {/* second part */}
      <div className="my-3  shadow bg-white p-3 border rounded-3 vstack  ">
        <h4 className="color-title-color fw-bold mb-3 ">Advertiser Details</h4>
        <div className="row p-3 ">
          <div className="col pe-3  ">
            <p>
              <span className="fw-bold">Name : </span>
              {data1?.data?.advertiserId?.fullName}
            </p>
            <p>
              <span className="fw-bold">Email : </span>
              {data1?.data?.advertiserId?.email}
            </p>
            {/* <div>
              <p className="fw-bold m-0 ">Shipping Details </p>
              <p className="m-0 ">
                <span className="fw-bold">Name :</span> {shippingAddress.name}
              </p>
              <p className="m-0 ">
                <span className="fw-bold">Phone Number :</span>{" "}
                {shippingAddress.phoneNumber}
              </p>
              <p className="m-0 ">
                <span className="fw-bold">Address :</span>{" "}
                {shippingAddress.address}
              </p>
            </div> */}
          </div>
          <div className=" col pe-3">
            <p>
              <span className="fw-bold  ">Order ID: </span>
              {data1?.data?.orderNumber}
            </p>
            <p>
              <span className="fw-bold"> Order Placed: </span>
              {getFormattedDate(data1?.data?.createdAt)}
            </p>
            {/* <p>
              <span className="fw-bold"> Delivery Date: </span> 25 Feb 2024
              (Dummy)
            </p> */}
          </div>
        </div>
        <ProgressTracker items={items} />
        <div className="mt-4">
          <h4>Order Detail Documents </h4>
          {/* <StlViewer style={style} orbitControls shadows url={url} /> */}

          <div>
            {/* <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} />
            </Document>
            <p>
              Page {pageNumber} of {numPages}
            </p> */}
            {/* <SinglePagePDFViewer /> */}
          </div>
        </div>
      </div>

      {/* third part */}
      <div className="my-3  shadow bg-white p-3 border rounded-3">
        <DataTable
          title="List of Packages"
          customStyles={tableHeaderstyle}
          columns={columns}
          data={data1?.data?.orderItems}
          progressPending={isLoading1}
          fixedHeader
          selectableRowsHighlight
          highlightOnHover
          // onSelectedRowsChange={({ selectedRows }) => console.log(selectedRows)}
        />
      </div>
    </div>
  );
};
export default OrderTrackingDetails;
