import React from "react";
import { WatchVideo } from "../../../../utils/WebIcons";

const HeroSection = ({ data }) => {
  const {
    title,
    paragraph,
    button_text,
    button_url,
    video_button_text,
    video_url,
    image_url,
  } = data?.content;

  return (
    <div className="bg-hero-section">
      <div className="max-width mx-auto  px-4 d-flex justify-content-between align-items-end ">
        <div className="d-flex justify-content-start flex-column my-5 w-75">
          <div className="hero-title fw-bold">{title}</div>
          <div className="hero-description">{paragraph}</div>
          <div className="d-flex align-items-center">
            <a
              className="btn bg-primary-color color-white-cream me-3 "
              href={button_url}
            >
              {button_text}
            </a>
            <a
              href={video_url}
              className="cursor-pointer text-decoration-none text-black "
            >
              <WatchVideo />
              <span className="fs-16 ps-2"> {video_button_text}</span>
            </a>
          </div>
        </div>
        <div className="desktop-view d-flex justify-content-center align-items-end h-100 ps-5 pt-3">
          <img src={image_url} className="img-fluid " alt="hero" />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
