import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";
import influencerPrefixedRoutes from "../../../../routes/all-routes/influencerRoutes";
import { toast } from "react-toastify";
import { getFormattedDate } from "../../../../helpers/getFormattedDate";
import Loader from "../../../common/components/loader/Loader";
const Feature = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.user.accessToken);
  const [data, setData] = useState([]);
  const [dataActive, setDataActive] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingActive, setLoadingActive] = useState(false);
  const [loadingBook, setLoadingBook] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const getFeature = async (page, size = perPage) => {
    try {
      setLoading(true);
      const req = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/influencer/featured/all?pageSize=${size}&pageNumber=${page}&sortField=createdAt&sortDirection=desc`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const res = await req.json();
      if (res?.error?.status === 401) {
        navigate(authPrefixedRoutes.LOGIN);
      }
      setData(res);
      setTotalRows(res.meta.pagination.total_count);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getActiveFeature = async () => {
    try {
      setLoadingActive(true);
      const req = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/influencer/featured-booking/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const res = await req.json();
      if (res?.error?.status === 401) {
        navigate(authPrefixedRoutes.LOGIN);
      }
      setDataActive(res);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingActive(false);
    }
  };

  const bookingfeatured = async (id) => {
    try {
      setLoadingBook(true);
      const req = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/influencer/featured-booking/create`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            featureId: id,
          }),
        }
      );
      const res = await req.json();
      if (res?.error?.status === 401) {
        navigate(authPrefixedRoutes.LOGIN);
      }
      setData(res);
      toast.success("Booked Successfully. Redirecting...");

      setTimeout(() => {
        window.open(res?.url);
      }, 1000);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingBook(false);
    }
  };

  useEffect(() => {
    setTotalRows(data?.meta?.pagination?.total_count);
  }, [data]);

  useEffect(() => {
    getFeature(1);
  }, []);

  useEffect(() => {
    getActiveFeature();
  }, []);

  const handleBookFeature = (id) => {
    bookingfeatured(id);
  };

  const columns = [
    {
      name: "#",
      selector: (row, index) =>
        (data?.pagination?.current_page - 1) * perPage + index + 1,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => (
        <Link
          to={`${influencerPrefixedRoutes.ORDERS_DETAILS}/${row._id}`}
          className="link-style"
        >
          {row?.featuredPackageName}
        </Link>
      ),
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => {
        return row?.price || "info not available";
      },

      sortable: true,
    },
    {
      name: "Duration (in days)",
      selector: (row) => {
        return row?.validationInDay || "info not available";
      },

      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <button
            disabled={loadingBook}
            onClick={() => {
              handleBookFeature(row?._id);
            }}
            className="btn bg-primary-color text-white"
          >
            Get
          </button>
        );
      },
    },
  ];

  const handlePageChange = (page) => {
    getFeature(page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    getFeature(page, newPerPage);
    setPerPage(newPerPage);
  };

  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "var(--primary-color)",
        color: "#fff",
      },
    },
  };

  console.log("dataActive", dataActive);

  if (loadingActive) {
    return <Loader />;
  }

  return (
    <div className="container ">
      <Helmet>
        <title>Order | Admin | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <div className="my-3 shadow bg-white p-3 border rounded-3 vstack">
        <h4 className="color-title-color fw-bold mb-3">
          Active Featured Plan Details
        </h4>
        {dataActive?.error?.status === 404 ? (
          <h6 className=" fw-bold mb-3">
            No Active Featured Plan
          </h6>
        ) : (
          <div className="row p-3 align-items-start">
            <div className="col pe-3">
              <p>
                <span className="fw-bold">Status: </span>
                {dataActive?.data?.endDate < Date.now() ? (
                  <span className="text-danger fw-bold">Expired</span>
                ) : (
                  <span className="text-success fw-bold">Featured</span>
                )}
              </p>
            </div>
            <div className="col pe-3">
              <p>
                <span className="fw-bold">End Date: </span>
                {getFormattedDate(dataActive?.data?.endDate)}
              </p>
            </div>
          </div>
        )}
      </div>

      <div className="border shadow  ">
        <DataTable
          title="Featured List"
          customStyles={tableHeaderstyle}
          columns={columns}
          data={data.data}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          fixedHeader
          selectableRowsHighlight
          highlightOnHover
        />
      </div>
    </div>
  );
};
export default Feature;
