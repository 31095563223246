import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";
import { toast } from "react-toastify";
import { useState } from "react";
import useGetPaymentUrl from "./useGetPaymentUrl";
import { clearCartData } from "../../../../redux/slices/cartSlice";

const usePlaceOrder = (refetchData) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.user.accessToken);
  const [isPlacingOrder, setIsPlacingOrder] = useState(false);

  const { getPaymentUrl, isLoadingUrl, isErrorUrl, dataUrl } =
    useGetPaymentUrl();

  const {
    mutate: placeOrder,
    isLoading,
    isError,
    data,
  } = useMutation({
    mutationKey: "usePlaceOrder",
    mutationFn: async () => {
      setIsPlacingOrder(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/advertiser/order/place-order`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        const text = await response.json();
        if (text?.error?.status === 401) {
          navigate(authPrefixedRoutes.LOGIN);
        }
        setIsPlacingOrder(false);
        throw new Error(text.message || "Failed to place order");
      }

      const data = await response.json();
      toast.success("Order placed successfully");
      // Dispatch action to reset cart Item Count
      dispatch(clearCartData({ cartItemCount: 0 }));

      // Correctly pass the orderId as an object
      getPaymentUrl({ orderId: data?.data?._id });

      setIsPlacingOrder(false);

      return data;
    },
    onSuccess: () => {
      // Refetch data when the mutation is successful
      refetchData();
      setIsPlacingOrder(false);
    },
    onError: (error) => {
      toast.error(error.message || "Something went wrong");
    },
  });

  return {
    placeOrder,
    orderPlacedData: data,
    isPlacingOrder,
  };
};

export default usePlaceOrder;
