import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";

const useGetTransactionDetailsById = (id) => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.user.accessToken);

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ["TransactionDetailsByIdForAdvertiser", id],
    queryFn: async () => {
      if (!id) return; // Prevent query from running if `id` is undefined
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/transaction/list/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        const text = await response.json();
        if (text?.error?.status === 401) {
          navigate(authPrefixedRoutes.LOGIN);
        }
        throw new Error(text.message || "Failed to fetch Transaction details");
      }
      return response.json(); // Return the fetched data
    },
    enabled: !!id, // Ensure the query runs only if `id` is available
    refetchOnWindowFocus: false, // Prevent refetching on window focus (optional)
    retry: 1, // Retry once if the request fails
    staleTime: 30000, // Cache data for 30 seconds before considering it stale
  });

  // Refetch function to manually refresh data
  const refetchData = () => {
    refetch();
  };

  return {
    isLoadingTransaction: isLoading,
    isErrorTransaction: isError,
    dataTransaction: data,
    refetchDataTransaction: refetchData,
  };
};

export default useGetTransactionDetailsById;
