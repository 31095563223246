import React, { useState } from "react";
import TopPackageItem from "./TopPackageItem";
import { useNavigate } from "react-router";
import useGetPackageList from "../../../../../hooks/data-hooks/influencer/package/useGetPackageList";
import influencerPrefixedRoutes from "../../../../../routes/all-routes/influencerRoutes";
import { DotLoader } from "react-spinners";

const TopPackage = () => {
  const navigate = useNavigate();
  const {

    packages,
    isLoading,

  } = useGetPackageList(5, 1);

  // const { data, isLoading, isError } = useGetTopPackages();
  const [open, setOpen] = useState(false);
  const handleViewAll = () => {
    setOpen(!open);
    return navigate(influencerPrefixedRoutes.PACKAGES);
  };




  if (isLoading) {
    return (
      <div className="d-flex align-items-center justify-content-center gap-4 bg-white w-100 h-100">
        <DotLoader size={60} color="#df3459" />
      </div>
    );
  }

  return (
    <div className="bg-white rounded-4 border vstack gap-2 fixed-height h-100">
      <div className="d-flex justify-content-between align-items-center p-3 pb-0">
        <span className="text-title fw-bold">Package List</span>
        <span onClick={() => handleViewAll()} className="fs-12 cursor-pointer">
          View all
          {/* <span
            className={`ms-2 ${open ? "arrow-collapsed" : "arrow-expanded"}`}
          >
            <ArrowDownBlackIcon />
          </span> */}
        </span>
      </div>
      <hr className="p-0 m-0" />

      {/* Add a scrollable container with scrollbar */}
      {packages && packages?.data?.length > 0 && (
        <div className="dashboard-card-list custom-scroll scrollbar p-3 vstack gap-2 ">
          {packages?.data?.map((data) => {
            return (
              <TopPackageItem key={data._id} data={data} />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TopPackage;
