// InfluencerRouter.js
import React from "react";
import { Routes, Route } from "react-router-dom";

// Import Influencer panel components

import InfluencerLayout from "../modules/influencer/components/layout/InfluencerLayout";
import InfluencerDashboard from "../modules/influencer/components/pages/Dashboard";
import InfluencerAuthGuard from "../hocs/layout-guards/InfluencerAuthGuard";
import { influencerRoutes } from "./all-routes/influencerRoutes";
import Calender from "../modules/influencer/components/pages/Calender";
import EarningsOverview from "../modules/influencer/components/pages/EarningsOverview";
import InfluencerSetting from "../modules/influencer/components/pages/InfluencerSettings";
import Packages from "../modules/influencer/components/pages/Packages";
import CreatePackage from "../modules/influencer/components/pages/CreatePackage";
import UpdatePackage from "../modules/influencer/components/pages/UpdatePackage";
import SocialProfiles from "../modules/influencer/components/pages/SocialProfiles";
import CreateSocialProfile from "../modules/influencer/components/pages/CreateSocialProfile";
import UpdateSocialProfile from "../modules/influencer/components/pages/UpdateSocialProfile";
import Orders from "../modules/influencer/components/pages/Orders";
import OrderDetails from "../modules/influencer/components/pages/OrderDetails";
import Message from "../modules/influencer/components/message/Message";
import Feature from "../modules/influencer/components/pages/Feature";
import Transaction from "../modules/influencer/components/pages/Transaction.js";
import TransactionDetails from "../modules/influencer/components/pages/TransactionDetails.js";

const InfluencerRouter = () => {
  return (
    <InfluencerAuthGuard>
      <InfluencerLayout>
        <Routes>
          <Route
            path={influencerRoutes.DASHBOARD}
            element={<InfluencerDashboard />}
          />
          <Route path={influencerRoutes.PACKAGES} element={<Packages />} />
          <Route
            path={influencerRoutes.CREATE_PACKAGE}
            element={<CreatePackage />}
          />
          <Route
            path={`${influencerRoutes.UPDATE_PACKAGE}/:id`}
            element={<UpdatePackage />}
          />
          <Route
            path={influencerRoutes.SOCIAL_PROFILES}
            element={<SocialProfiles />}
          />

          <Route
            path={influencerRoutes.CREATE_SOCIAL_PROFILE}
            element={<CreateSocialProfile />}
          />

          <Route
            path={`${influencerRoutes.UPDATE_SOCIAL_PROFILE}/:id`}
            element={<UpdateSocialProfile />}
          />

          <Route path={influencerRoutes.CALENDER} element={<Calender />} />

          <Route
            path={influencerRoutes.EARNINGS_OVERVIEW}
            element={<EarningsOverview />}
          />

          <Route path={influencerRoutes.ORDERS} element={<Orders />} />

          <Route
            path={`${influencerRoutes.ORDERS_DETAILS}/:id`}
            element={<OrderDetails />}
          />

          {/* Transactions */}
          <Route
            path={influencerRoutes.TRANSACTION}
            element={<Transaction />}
          />
          <Route
            path={`${influencerRoutes.TRANSACTION}/:id`}
            element={<TransactionDetails />}
          />

          <Route
            path={influencerRoutes.INFLUENCER_SETTINGS}
            element={<InfluencerSetting />}
          />
          <Route path={influencerRoutes.MESSAGE} element={<Message />} />
          <Route
            path={`${influencerRoutes.MESSAGE}/:id`}
            element={<Message />}
          />
          <Route path={influencerRoutes.FEATURE} element={<Feature />} />
        </Routes>
      </InfluencerLayout>
    </InfluencerAuthGuard>
  );
};

export default InfluencerRouter;
