import { DotLoader } from "react-spinners";
import useGetTransactionList from "../../../../../hooks/data-hooks/common/transaction/useGetTransactionList";
import StatsCard from "./StatsCard";
import { useEffect } from "react";

const Stats = () => {
  // Fetch transaction list with params
  const {
    transactionHistory,
    isLoadingTransactionList,
    fetchTransactionListWithParams,
  } = useGetTransactionList({});

  useEffect(() => {
    fetchTransactionListWithParams({});
  }, []);

  if (isLoadingTransactionList) {
    return (
      <div className="d-flex align-items-center justify-content-center gap-4 bg-white w-100 h-100">
        <DotLoader size={60} color="#df3459" />
      </div>
    );
  }

  return (
    <div className="d-flex flex-wrap  bg-white rounded-4 border h-100   ">
      <StatsCard
        title="Transactions"
        sales={transactionHistory?.totalTransaction}
      />

      <StatsCard title="Due" sales={transactionHistory?.totalDue} />
      <StatsCard
        title="Received"
        sales={transactionHistory?.totalReceivedAmount}
      />

      <StatsCard title="Total" sales={transactionHistory?.totalTransaction} />
    </div>
  );
};
export default Stats;
