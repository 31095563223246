import TransactionTable from "../transaction/TransactionTable";
import useGetLab from "../../../../hooks/data-hooks/admin/transaction/useGetLab";
import useGetTransactionList from "../../../../hooks/data-hooks/admin/transaction/useGetTransactionList";
import Loader from "../../../common/components/loader/Loader";
import { ArrowDownBlackIcon } from "../../../../utils/MyIcons";
import { useEffect, useState } from "react";
import {  useForm } from "react-hook-form";
import TransactionHistory from "../transaction/TransactionHistory";
import { Helmet } from "react-helmet";
import { adminPageNames } from "../../../../routes/all-routes/adminRoutes";

const Transaction = () => {
  const [selectedLab, setSelectedLab] = useState("");
  const [pageSize, setPageSize] = useState(10); // items per page
  const [currentPage, setCurrentPage] = useState(1);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  // Fetch transaction list with params
  const {
    fetchTransactionListWithParams,
    isLoadingTransactionList,
    setIsLoadingTransactionList,
    transactionList,
    transactionListMeta,
    transactionHistory,
    transactionListError,
    totalRows,
    setTotalRows,
  } = useGetTransactionList({
    pageNumber: currentPage,
    pageSize: pageSize,
    influencerId: selectedLab,
    sortField: "createdAt",
    sortDirection: "desc",
  });

  // Trigger API call with labUserId
  useEffect(() => {
    fetchTransactionListWithParams({
      pageNumber: currentPage,
      pageSize: pageSize,
      influencerId: selectedLab,
      sortField: "createdAt",
      sortDirection: "desc",
    });
  }, [pageSize, currentPage, selectedLab]);

  // Fetch lab list with params
  const { isLoading, labList } = useGetLab({

  });

  const handlePageChange = async (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPageSize(newPerPage);
    setCurrentPage(page);
  };

  const onSubmit = async (data) => {
    if (isSubmitting) {
      return;
    }
    if (data.influencerId === "See All") {
      setSelectedLab("");
      return;
    }
    const { influencerId } = data;

    setSelectedLab(influencerId);
    setCurrentPage(1);
  };


  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>
          {adminPageNames.TRANSACTION} | Admin | {process.env.REACT_APP_NAME}
        </title>
      </Helmet>

      <div className="container">
        {/* select Influencer */}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="web-form sign-up-form mb-4"
        >
          <div className="form-item w-50 ">
            <label htmlFor="labList" className="">
              Select Influencer
            </label>
            <div className="input-wrapper position-relative ">
              <select
                className="form-control"
                {...register("searchTerm")}
                onChange={(event) => {
                  const { value } = event.target;
                  onSubmit({
                    influencerId: value,
                  }); // Trigger onSubmit directly
                }}
              >
                <option value="">See All</option>
                {labList?.map((lab, index) => (
                  <option key={index} value={lab?._id}>
                    {lab?.fullName}
                  </option>
                ))}
              </select>

              <span className="pe-none position-absolute top-50 end-0 translate-middle-y p-3   ">
                <ArrowDownBlackIcon />
              </span>
            </div>
          </div>
        </form>

        {/* transaction card */}
        <div className="transaction-top">
          <div className="py-3">
            <h5 className="text-black fs-4">Transaction Overview</h5>
          </div>
          <div className=" ">
            <TransactionHistory data={transactionHistory} />
          </div>
        </div>

        {/* transaction table */}
        <div className="transaction-bottom mt-5">
          <h4>Recent Transactions</h4>
          <TransactionTable
            data={transactionList}
            meta={transactionListMeta}
            isLoading={isLoadingTransactionList}
            setIsLoading={setIsLoadingTransactionList}
            error={transactionListError}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            fetchTransactionListWithParams={fetchTransactionListWithParams}
            pageSize={pageSize}
            setPageSize={setPageSize}
            selectedLab={selectedLab}
            totalRows={totalRows}
            setTotalRows={setTotalRows}
            handlePageChange={handlePageChange}
            handlePerRowsChange={handlePerRowsChange}
          />
        </div>
      </div>
    </>
  );
};
export default Transaction;
