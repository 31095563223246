import React from "react";
import InfluencerCard from "./InfluencerCard";
import Loader from "../../../common/components/loader/Loader"; // Import the loader

const InfluencerContainer = ({ data, isLoading, advertiser }) => {
  return (
    <div className="container my-4">
      {/* Display loader if data is being fetched */}
      {isLoading ? (
        <Loader /> // Section-specific loader
      ) : (
        <div className="row align-items-stretch justify-content-start">
          {data?.length > 0 ? (
            data?.map((item, index) => (
              <div
                key={index}
                className="col-12 col-md-6 col-lg-4 col-xxl-3 d-flex"
              >
                <InfluencerCard
                  id={item?._id}
                  image={item?.profileImageUrl}
                  name={item?.fullName}
                  categories={item?.influencer?.categories}
                  follower={item?.influencer?.totalFollowers}
                  tags={item?.influencer?.tags}
                  socials={item?.influencer?.socialProfiles}
                  featured={item?.isActiveBooking}
                  advertiser={advertiser}
                />
              </div>
            ))
          ) : (
            <p>No influencers found</p> // Display message if no data is available
          )}
        </div>
      )}
    </div>
  );
};

export default InfluencerContainer;
