const TopClinicItem = ({ data }) => {
  const { profileImageUrl, fullName, sell } = data;
  return (
    <div className="d-flex gap-2 align-items-center justify-content-between ">
      <div className="d-flex align-items-center gap-2 ">
        <img
          src={profileImageUrl}
          alt=""
          className="w-h-33px rounded-circle "
        />
        <span className="fs-14 fw-light ">{fullName}</span>
      </div>
      {/* <span className="fs-14 fw-light ">${sell}</span> */}
    </div>
  );
};
export default TopClinicItem;
