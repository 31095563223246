import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
// import { setForgetPasswordDataOtp } from "../../../redux/slices/forgetPasswordSlice";
// import { useDispatch } from "react-redux";
import authPrefixedRoutes from "../../../routes/all-routes/authRoutes";
import { useNavigate } from "react-router-dom";
// import { commonRoutes } from "../../../routes/all-routes/commonRoutes";

const useResetPassword = () => {
  //   const dispatch = useDispatch();
  const [resetError, setResetError] = useState(null);
  const [resetSubmitSuccess, setResetSubmitSuccess] = useState(false);
  const [resetSuccessResponse, setResetSuccessResponse] = useState(null);

  const navigate = useNavigate();

  const {
    mutate: resetPassword,
    isLoading: isResetLoading,
    isError: isResetError,
  } = useMutation({
    mutationKey: "resetPassword",
    mutationFn: async (formData) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/auth/forgot-password/set-password`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        const data = await response.json();

        if (!response.ok) {
          const text = await response.json();
          if (text?.error?.status === 401) {
            navigate(authPrefixedRoutes.LOGIN);
          }
          setResetError(data.error);
          throw new Error(data.error.message);
        }

        setResetSubmitSuccess(true);
        setResetSuccessResponse(data);
        // dispatch(
        //   setForgetPasswordDataOtp({ email: formData.email, otp: formData.otp })
        // );

        // clear data so that , user cannot use the verification screen without email
        // dispatch(clearForgetPasswordData());

        // Delay navigation for 1 second
        setTimeout(() => {
          // navigate to login page
          // window.location.href = `${process.env.REACT_APP_LAB_CLINIC_PANEL_URL}`;
          navigate(authPrefixedRoutes.LOGIN);
        }, 1500);

        return data;
      } catch (error) {
        setResetError(error);
        throw error;
      }
    },
    onError: (error) => {
      setResetError(error);
      throw error;
    },
  });

  return {
    resetPassword,
    isResetLoading,
    isResetError,
    resetError,
    resetSubmitSuccess,
    resetSuccessResponse,
  };
};

export default useResetPassword;
