// Modal.js
import React from "react";

const Modal = ({ content, onClose, onConfirm }) => {
  return (
    <div className="custom-modal">
      <p>{content}</p>
      <div>
        <button onClick={onClose} className="me-5 btn bg-warning ">
          No
        </button>
        <button onClick={onConfirm} className=" btn bg-primary-color text-white ">
          Yes
        </button>
      </div>
    </div>
  );
};

export default Modal;
