import React from "react";
import { useSelector } from "react-redux";
import MyImage from "../../../../../../custom-components/MyImage";
import SiteLogoContainer from "../../../../../common/components/section/SiteLogoContainer";


function InfluencerSidenavProfile() {
  
  const user = useSelector((state) => state.auth.user);

  return (
    <>
      <div className="sticky-top bg-top">
        <div className="min-height-84 d-flex justify-content-center align-items-center gap-2 bg-primary-Dashboard">
          <span className="icon-40">
            <SiteLogoContainer logoColor="white" />
          </span>
        </div>
        <hr className="m-0 opacity-100" />
      </div>
      {/* User Profile */}
      <div className="text-center mt-5 mb-4 d-flex flex-column justify-content-center align-items-center ">
        <div>
          <MyImage
            src={user.profileImageUrl}
            addBaseUrl={false}
            className="w-h-92px rounded-circle"
            alt="User Profile"
          />
        </div>
        <p className="fs-5">{user.fullName ?? "No name available"}</p>
      </div>
    </>
  );
}

export default InfluencerSidenavProfile;
