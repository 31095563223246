// useGetUserAccount;.js
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";

const useGetUserAccount = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.user.accessToken);

  const { isLoading, isError, data } = useQuery({
    queryKey: ["getInfluencerAccount"],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/auth/influencer/profile`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        const text = await response.json();
        if (text?.error?.status === 401) {
          navigate(authPrefixedRoutes.LOGIN);
        }

        return;
      }
      const data = await response.json();

      return data;
    },
  });

  useEffect(() => {
    // Fetch data on component mount
  }, []);

  return { isLoading1: isLoading, isError1: isError, data1: data };
};

export default useGetUserAccount;
