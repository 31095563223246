import { useQuery } from "@tanstack/react-query";
import axios from "axios";

// Custom hook for fetching data by id from an API
const useFecthDataById = (url, queryKey, id) => {
  const fetchDataById = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}${url}/${id}`
    );
    return response.data;
  };

   const { data, isLoading, error } = useQuery({
     queryKey: [queryKey, id], // Include id in the queryKey
     queryFn: fetchDataById,
     keepPreviousData: false, // Set keepPreviousData to false
   });

  return { data, isLoading, error };
};

export default useFecthDataById;
