import { Link } from "react-router-dom";
import advertiserPrefixedRoutes from "../../../../routes/all-routes/advertiserRoutes";
import { CartIcon } from "../../../../utils/MyIcons";
import { useSelector } from "react-redux";

const CartBadge = () => {
    const cartItemCount = useSelector((state) => state.cart.cartItemCount);

  let displayCount = cartItemCount;

  if (cartItemCount < 10) {
    displayCount = `${cartItemCount}`; // Add leading zero if message count is less than 10
  } else if (cartItemCount > 99) {
    displayCount = "99+"; // Display 99+ if message count is more than 99
  }
  return (
    <Link to={advertiserPrefixedRoutes.CART}>
      <div className="position-relative d-inline-block">
        <CartIcon />
        {cartItemCount !== 0 && (
          <span className="message-badge">{displayCount}</span>
        )}
      </div>
    </Link>
  );
};

export default CartBadge;
