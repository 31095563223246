const authPrefix = "auth";
const authRoutes = {
  LOGIN: "/login",
  FORGET_PASSWORD: "/forget-password",
  VERIFY_SIGNUP_OTP: "/verify-signup-otp",
  VERIFY_SIGNUP_OTP_ADVERTISER: "/verify-signup-otp-advertiser",
  FORGET_PASSWORD_OTP: "/forget-password-otp",
  RESET_PASSWORD: "/reset-password",
  CLINIC_SIGNUP: "/sign-up/advertiser",
  LAB_SIGNUP: "/sign-up/influencer",
};

const authPrefixedRoutes = {};

// Loop through each key in authRoutes and add the prefix
for (const key in authRoutes) {
  if (authRoutes.hasOwnProperty(key)) {
    authPrefixedRoutes[key] = `/${authPrefix}${authRoutes[key]}`;
  }
}

export default authPrefixedRoutes;

export { authPrefix, authRoutes, authPrefixedRoutes };
