// InfluencerAccount.js
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import useGetUserAccount from "../../../../hooks/data-hooks/influencer/cms/useGetUserAccount";
import useUpdateUserAccount from "../../../../hooks/data-hooks/influencer/cms/useUpdateUserAccount";
import Button from "../../../web/components/button/Button";
import Loader from "../../../common/components/loader/Loader";
import useFetchData from "../../../../hooks/data-hooks/common/useFetchData";
import Select from "react-select"; // Importing Select from react-select

const InfluencerAccount = () => {
  const { data1, isLoading1, isError1 } = useGetUserAccount();

  console.log(data1);

  const {
    updateContent,
    isLoadingUpdate,
    isError,
    errorUpdate,
    submitSuccess,
  } = useUpdateUserAccount();

  // Fetch Tag List data
  const {
    data: tagListData,
    isLoading: isFetchingTagListData,
    errorTagListData,
  } = useFetchData("/api/public/tag/all", "tagListKey",1,0);

  // Fetch Category List data
  const {
    data: categoryListData,
    isLoading: isFetchingCategoryListData,
    errorCategoryListData,
  } = useFetchData("/api/public/influencer-category/all", "categoryListKey",1,0);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm();

  useEffect(() => {
    // Show error toast if isError is true
    if (isError || isError1 || errorUpdate) {
      toast.error(isError || isError1 || errorUpdate);
    }
  }, [isError, isError1, errorUpdate]);

  // ----------------------------
  useEffect(() => {
    // Set default values if data1 is available
    if (data1) {
      setValue("bio", data1?.data?.bio);
      setValue("gender", data1?.data?.gender);
      setValue("regions", data1?.data?.regions);
      setValue("totalFollowers", data1?.data?.totalFollowers);
      // Set default selected categories
      if (data1?.data?.categories) {
        setValue(
          "category",
          data1?.data?.categories.map((category) => category._id)
        );
      }

      // Set default selected tags
      if (data1?.data?.tags) {
        setValue(
          "tags",
          data1?.data?.tags.map((tag) => tag._id)
        );
      }
    }
  }, [data1]);
  // ----------------------------

  const onSubmit = async (data) => {
    console.log(data);
    if (isSubmitting) return;
    try {
      // Explicitly removing any unintended fields
      const formattedData = {
        bio: data.bio,
        gender: data.gender,
        regions: data.regions,
        totalFollowers: data.totalFollowers,
        categories: data.category, // Add categories to the submitted data
        tags: data.tags, // Add tags to the submitted data
      };

      updateContent(formattedData);
    } catch (error) {
      // Error handling is done in useEffect
    }
  };

  if (isLoading1 || isFetchingTagListData || isFetchingCategoryListData)
    return <Loader />;
  return (
    <div className="cms-section ">
      <div className="cms-card  ">
        <form className="cms-form   mb-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="fs-18px fw-bold ">Additional Information</div>

          <div className=" cms-content custom-scroll ">
            {/* Bio */}
            <div className="form-item">
              <label className="">Bio</label>
              <div className="input-wrapper">
                <textarea
                  type="text"
                  className=""
                  placeholder="Enter Bio"
                  {...register("bio", {
                    required: "Field is required",
                  })}
                />
              </div>
            </div>
            {/* Gender */}
            <div className="form-item">
              <label className="">Gender</label>
              <div className="input-wrapper">
                <select
                  className="custom-select"
                  {...register("gender", {
                    required: "Field is required",
                  })}
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
            </div>
            {/* regions */}
            <div className="form-item">
              <label className="">Regions</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  placeholder="Enter Regions"
                  {...register("regions", {
                    required: "Field is required",
                  })}
                />
              </div>
            </div>
            {/* Total Followers */}
            <div className="form-item">
              <label className="">Total Followers</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  placeholder="Enter total Followers"
                  {...register("totalFollowers", {
                    required: "Field is required",
                  })}
                />
              </div>
            </div>
            {/* Category Dropdown with react-select */}
            <div className="form-item">
              <label>Categories</label>
              <Select
                options={categoryListData?.data?.map((category) => ({
                  label: category?.categoryName,
                  value: category?._id,
                }))}
                isMulti
                name="category"
                placeholder="Select a category"
                className="basic-multi-select"
                classNamePrefix="select"
                defaultValue={data1?.data?.categories.map((category) => ({
                  label: category?.categoryName,
                  value: category?._id,
                }))}
                onChange={(selectedOptions) =>
                  setValue(
                    "category",
                    selectedOptions.map((option) => option.value)
                  )
                }
              />
            </div>

            {/* Tag Dropdown with react-select */}
            <div className="form-item">
              <label>Tags</label>
              <Select
                options={tagListData?.data?.map((tag) => ({
                  label: tag?.tagName,
                  value: tag?._id,
                }))}
                isMulti
                name="tags"
                placeholder="Select tags"
                className="basic-multi-select"
                classNamePrefix="select"
                defaultValue={data1?.data?.tags.map((tag) => ({
                  label: tag?.tagName,
                  value: tag?._id,
                }))}
                onChange={(selectedOptions) =>
                  setValue(
                    "tags",
                    selectedOptions.map((option) => option.value)
                  )
                }
              />
            </div>
          </div>

          <Button
            label={isLoadingUpdate ? "Submitting" : "Submit"}
            type="submit"
            className={`w-100 ${isLoadingUpdate ? "bg-disabled" : ""}`}
            disabled={isLoadingUpdate}
          />
        </form>
      </div>
    </div>
  );
};
export default InfluencerAccount;
