// OrderPlacementDetails.js
import React, { useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom"; //for routing
import useGetOrderDetailsById from "../../../../hooks/data-hooks/advertiser/order/useGetOrderDetailsById"; //custom hook to call api
import Loader from "../../../common/components/loader/Loader";
// import MessageBadge from "../message/MessageBadge";
import { getFormattedDate } from "../../../../helpers/getFormattedDate";
import BackButton from "../../../../helpers/backButton";
import OrderProgressStepper from "../../../common/components/OrderProgressStepper";
import useGetTransactionDetailsById from "../../../../hooks/data-hooks/advertiser/transaction/useGetTransactionDetailsById";
import advertiserPrefixedRoutes from "../../../../routes/all-routes/advertiserRoutes";

const OrderPlacementDetails = () => {
  const { id } = useParams();

  const { isLoading, isError, data, refetchData } = useGetOrderDetailsById(id);

  // Fetch transaction details
  const {
    isLoadingTransaction,
    isErrorTransaction,
    dataTransaction,
    refetchDataTransaction,
  } = useGetTransactionDetailsById(id);

  // Refetch transaction data when component mounts
  useEffect(() => {
    if (id) {
      refetchDataTransaction(); // Ensure the data is fresh on mount
    }
  }, [id, refetchDataTransaction]);

  const columns = useMemo(
    () => [
      {
        name: "#",
        selector: (row, index) => index + 1,
        sortable: true,
      },
      {
        name: "Package",
        selector: (row) => <>{row?.packageId?.packageName}</>,
        sortable: true,
      },

      {
        name: "Price",
        selector: (row) => <>{row?.price}</>,
        sortable: true,
      },
      {
        name: "Quantity",
        selector: (row) => <>{row?.quantity}</>,
        sortable: true,
      },

      {
        name: "Total Price",
        selector: (row) => <>{row?.totalPrice}</>,
        sortable: true,
      },
    ],
    []
  );

  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "var(--primary-color)",
        color: "#fff",
      },
    },
  };

  // Show loader when loading either order details or transaction details
  if (isLoading || isLoadingTransaction) {
    return <Loader />;
  }

  // Error handling (optional, if you want to display errors)
  if (isError || isErrorTransaction) {
    return <div>Error loading data. Please try again later.</div>;
  }


  return (
    <div className="container">
      <BackButton />

      {/* Advertiser Details */}
      <div className="my-3 shadow bg-secondary-color p-3 border rounded-3 vstack">
        <h4 className="color-title-color fw-bold mb-3">Influencer Details</h4>
        <div className="row p-3">
          <div className="col pe-3">
            <p>
              <span className="fw-bold">Name: </span>
              <Link
                to={`${advertiserPrefixedRoutes.INFLUENCER_LIST}/${data?.documents?.influencerId?._id}`}
                className="link-style"
              >
                {data?.documents?.influencerId?.fullName}
              </Link>
            </p>
            <p>
              <span className="fw-bold">Email: </span>
              {data?.documents?.influencerId?.email}
            </p>
            <p>
              <span className="fw-bold">Order Placed: </span>
              {getFormattedDate(data?.documents?.createdAt)}
            </p>
          </div>
          <div className="col pe-3">
            <p>
              <span className="fw-bold">Order Number: </span>
              {data?.documents?.orderNumber}
            </p>
            <p>
              <span className="fw-bold">Order Status: </span>
              {data?.documents?.orderStatus}
            </p>
            <p>
              <span className="fw-bold">Payment Status: </span>
              {data?.documents?.paymentStatus}
            </p>
          </div>
        </div>

        {/* Order Progress Stepper */}
        <OrderProgressStepper
          orderId={data?.documents?._id}
          orderStatus={data?.documents?.orderStatus}
          paymentStatus={data?.documents?.paymentStatus}
          refetchData={refetchData}
          role="advertiser"
        />
        <Link
          to={`${advertiserPrefixedRoutes.MESSAGE}/${data?.documents?._id}`}
          className="link-style"
        >
          <div className="bg-highlight rounded-3 p-3 border fs-16 text-center mt-2">
            <span>Open Chat Conversation</span>
            {/* <MessageBadge messageCount={data?.documents?.totalMessages} />{" "} */}
          </div>
        </Link>
      </div>

      {/* Package List */}
      <div className="my-3  shadow bg-secondary-color p-3 border rounded-3">
        <div>
          <h5 className="color-title-color fw-bold mb-3 ">Order Details </h5>
        </div>

        <div className="my-4 shadow ">
          <DataTable
            title="Package List"
            customStyles={tableHeaderstyle}
            columns={columns}
            data={data?.documents?.orderItems}
            progressPending={isLoading}
            fixedHeader
            selectableRowsHighlight
            highlightOnHover
          />
        </div>
      </div>

      {/* Transaction Summary (Conditional Rendering) */}
      {dataTransaction && dataTransaction.length > 0 && (
        <div className="shadow bg-secondary-color rounded-3 p-3">
          <h5 className="color-title-color fw-bold mb-3">
            Transaction Summary
          </h5>
          <hr />
          <div className="d-flex justify-content-between fw-bold mt-3">
            <span>Transaction amount</span>
            <span>{dataTransaction[0]?.orderId?.totalAmount}</span>
          </div>
          <div className="d-flex justify-content-between fw-bold mt-3">
            <span>Platform Charge</span>
            <span>
              {dataTransaction[0]?.advertiserTotalAmount -
                dataTransaction[0]?.orderId?.totalAmount}
            </span>
          </div>
          <div className="d-flex justify-content-between fw-bold mt-3">
            <span>Total Amount</span>
            <span>{dataTransaction[0]?.advertiserTotalAmount}</span>
          </div>
        </div>
      )}
    </div>
  );
};
export default OrderPlacementDetails;
