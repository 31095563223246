// Feedback.js
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import advertiserPrefixedRoutes from "../../../../routes/all-routes/advertiserRoutes";
import { Helmet } from "react-helmet-async";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";
import { Rating } from "react-simple-star-rating";

const Feedback = ({id}) => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.user.accessToken);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [expandedFeedback, setExpandedFeedback] = useState({}); // Track expanded feedback

  const getFeedback = async (id, page, size = perPage) => {
    try {
      setLoading(true);

      const req = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/advertiser/feedback/all?influencerId=${id}&pageSize=${size}&pageNumber=${page}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const res = await req.json();
      if (res?.error?.status === 401) {
        navigate(authPrefixedRoutes.LOGIN);
      }
      setData(res);
      setTotalRows(res.meta.pagination.total_count);
    } catch (error) {
      console.log(error);
    }finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    setTotalRows(data?.meta?.pagination?.total_count);
  }, [data]);

  useEffect(() => {
    getFeedback(id,1);
  }, []);

  // Toggle feedback expansion
  const toggleFeedbackExpansion = (id) => {
    setExpandedFeedback((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const columns = [
    {
      name: "Influencer Id",
      selector: (row) => {
        return row?._id;
      },
      sortable: true,
    },
    {
      name: "Ratings",
      selector: (row) => {
        return (
          <div className="d-flex flex-column align-items-center gap-3 py-3">
            <div className="">
              <div className="d-flex flex-column gap-2">
                <span>
                  <Rating initialValue={row?.rating} readonly size={25} />
                  &nbsp;( {row?.rating} )
                </span>
              </div>
            </div>
          </div>
        );
      },
      sortable: true,
    },

    // Feedback
    {
      name: "Feedback",
      selector: (row) => {
        const isExpanded = expandedFeedback[row?._id];
        const feedbackPreview = row?.content.slice(0, 50);
        const isLongFeedback = row?.content.length > 100;

        return (
          <div className="">
            {isExpanded ? row?.content : feedbackPreview}
            {isLongFeedback && (
              <button
                className="btn btn-link p-0 link-style"
                onClick={() => toggleFeedbackExpansion(row?._id)}
              >
                {isExpanded ? "See Less" : "More..."}
              </button>
            )}
          </div>
        );
      },
      sortable: true,
    },
  ];

  const handlePageChange = (page) => {
    getFeedback(id, page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    getFeedback(id, page, newPerPage);
    setPerPage(newPerPage);
  };

  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "var(--primary-color)",
        color: "#fff",
      },
    },
  };



  return (
    <div className="container ">
      <Helmet>
        <title>
          Order Placement | Advertiser | {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <div className="border shadow">
        <DataTable
          // title="Feedback & Ratings"
          customStyles={tableHeaderstyle}
          columns={columns}
          data={data.data}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          fixedHeader
          selectableRowsHighlight
          highlightOnHover
        />
      </div>
    </div>
  );
};

export default Feedback;
