import {
  FaInstagramSquare,
  FaYoutube,
  FaFacebook,
  FaTiktok,
  FaSnapchat,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

import videoImg from "../assets/image/packages/video.svg";
import photoImg from "../assets/image/packages/photo.svg";
import sponsorshipImg from "../assets/image/packages/sponsorship.svg";
import storyImg from "../assets/image/packages/story.jpg";
import highlightImg from "../assets/image/packages/highlight.svg";

export const platformList = [
  "youtube",
  "facebook",
  "snapchat",
  "instagram",
  "x",
  "tiktok",
];

export const typeList = ["video", "photo", "sponsorship", "story", "highlight"];

export const ageList = ["18-24", "25-34", "35-44", "45+"];

export const rankList = [
  "Top - 1",
  "Top - 5",
  "Top - 10",
  "Top - 20",
  "Top - 50",
  "Top - 100",
  "Top - 500",
  "Top - 1000",
];

export const SocialIcons = {
  youtube: <FaYoutube color="red" size={20} />,
  facebook: <FaFacebook color="blue" size={20} />,
  snapchat: <FaSnapchat color="black" size={20} />,
  instagram: <FaInstagramSquare color="purple" size={20} />,
  x: <FaXTwitter color="blue" size={20} />,
  tiktok: <FaTiktok color="black" size={20} />,
};

export const SocialPlatformColors = {
  youtube: "bg-youtube",
  facebook: "bg-facebook",
  snapchat: "bg-snapchat",
  instagram: "bg-instagram",
  x: "bg-x",
  tiktok: "bg-tiktok",
};

export const PackageTypeImage = {
  video: videoImg,
  photo: photoImg,
  sponsorship: sponsorshipImg,
  story: storyImg,
  highlight: highlightImg,
};
