// useCommission.js
import { useState, useEffect } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
//import { commonRoutes } from "../../../../../routes/all-routes/commonRoutes";
import { useSelector } from "react-redux";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";

const useCommission = () => {
  const navigate = useNavigate(); // React Router v6's navigate function
  const token = useSelector((state) => state.auth.user.accessToken);
  const [commission, setCommission] = useState();
  const [placeholder, setPlaceHolder] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false); // New state for submit success

  const fetchCommissionData = useQuery({
    queryKey: ["getcommission"],
    queryFn: async () => {
      setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/admin/setting/get-setting`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        const text = await response.json();
        if (text?.error?.status === 401) {
          navigate(authPrefixedRoutes.LOGIN);
        }
        // dispatch(setError("Failed to fetch data"));
        //  navigate(commonRoutes.SERVER_ERROR); // Navigate to error500 page
        return;
      }
      const data = await response.json();
      setIsLoading(false);
      //  console.log(data);
      setCommission(data?.data);
      setPlaceHolder(data?.data?.placeholder);
      return data?.data;
    },
  });

  const fetchCommission = () => {
    fetchCommissionData.refetch();
  };

  useEffect(() => {
    fetchCommission();
  }, []);

  const {
    mutate: updateContent,

    isError,
  } = useMutation({
    mutationKey: "updatesetting",
    mutationFn: async (formData) => {
      //  console.log(formData);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/admin/setting/set-setting`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(formData),
          }
        );

        const data = await response.json();

        if (!response.ok) {
          const text = await response.json();
          if (text?.error?.status === 401) {
            navigate(authPrefixedRoutes.LOGIN);
          }
          throw new Error(data.error.message);
        }
        setSubmitSuccess(true);
        return data;
      } catch (error) {
        // Set login error state
        setError(error);
        throw error; // Re-throw the error to be caught by onError
      }
    },
    onError: (error) => {
      setError(error);
      throw error; // Re-throw the error to be caught by onError
    },
  });

  return {
    isLoadingCommission: isLoading,
    isError,
    placeholder,
    commission,
    submitSuccess,
    updateContent,
  };
};

export default useCommission;
