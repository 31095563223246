import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import useUpdateTag from "../../../../hooks/data-hooks/admin/tag/useUpdateTag";
import useGetTagById from "../../../../hooks/data-hooks/admin/tag/useGetTagById.js";
import Loader from "../../../common/components/loader/Loader";
import BackButton from "../../../../helpers/backButton.js";

const UpdateTag = () => {
  const params = useParams();
  const { fetchTagWithId, tagDetails, isLoading, isError } =
    useGetTagById(params.id);

  const {
    updateContent,
    isLoadingUpdate,
    isErrorUpdate,
    error,
    submitSuccess,
    isSubmitting,
  } = useUpdateTag(params.id);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    // Set default values if data1 is available
    if (tagDetails) {
      setValue("tagName", tagDetails?.data?.tagName);
    }
  }, [tagDetails]);

  useEffect(() => {
    // Show error toast if isError is true
    if (isError) {
      toast.error(error.message);
    } else if (isErrorUpdate) {
      toast.error(isErrorUpdate);
    }
  }, [isError, error, isErrorUpdate]);

  useEffect(() => {
    // Show success toast if submitSuccess is true
    if (submitSuccess) {
      toast.success("Form Submit Successful");
    }
  }, [submitSuccess]);

  const onSubmit = async (data) => {
    // Prevent form submission if there is an ongoing login process
    if (isSubmitting) return;

    try {
      // Call the custom hook for API call
      updateContent(data);
    } catch (error) {
      // Error handling is done in useEffect
    }
  };

  useEffect(() => {
    fetchTagWithId(params.id); // Fetch clinic details when clinicId changes
  }, [params.id]);

  if (isLoading) return <Loader />;
  return (
    <div className="ms-5 col-lg-6">
      <div className="cms-card ">
        <BackButton />
        <form className="cms-form mb-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="fs-24 fw-bold ">Update Tag Form</div>

          <div className="mt-5">
            <div className="form-item">
              <label className="">Tag Name</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  placeholder="Enter tag name"
                  {...register("tagName", {
                    required: "Tag name is required",
                  })}
                />
              </div>
            </div>
          </div>

          <button type="submit" className="w-100 button-primary h-64px">
            {isSubmitting ? "Updating..." : "Update Tag"}
          </button>
        </form>
      </div>
    </div>
  );
};
export default UpdateTag;
