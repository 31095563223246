import TransactionCard from "./TransactionCard";
import img1 from "../../../../dummy-img/transaction1.png";
import img2 from "../../../../dummy-img/transaction 2.png";
import img3 from "../../../../dummy-img/transaction3.png";
import img4 from "../../../../dummy-img/transaction4.png";

const TransactionHistory = (data) => {
  const { totalTransaction, totalDue, totalReceivedAmount } = data.data;
  return (
    <div className="row g-2  align-items-stretch">
      <TransactionCard
        title="Total transactions"
        amount={totalTransaction}
        // time="This month"
        image={img1}
      />
      <TransactionCard
        title="Total received Amount"
        amount={totalReceivedAmount}
        // time="This month"
        image={img2}
      />
      <TransactionCard
        title="Total due"
        amount={totalDue}
        // time="This month"
        image={img3}
      />
      {/* <TransactionCard
        title="Pending Amount"
        amount={totalReceivedAmount}
        time="This month"
        image={img4}
      /> */}
    </div>
  );
};
export default TransactionHistory;
