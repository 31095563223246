import { useLocation, Link } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import useLogout from "../../../../hooks/other-hooks/useLogout";

import DashboardIcon, {
  ArrowIcon,
  LogoutIcon,
  TransactionIcon,
  CloseIcon,
  HamburgerIcon,
  BookingIcon,
  UserManagementIcon,
  BellIcon,
  SettingsNewIcon,
  DotIcon,
  PaymentIcon,
  AnalyticsIcon,
  MessageIcon,
  MessagingIcon,
  MessageWhiteIcon,
} from "../../../../utils/MyIcons";
import { useEffect, useState } from "react";
import AdvertiserSidenavProfile from "./layout-partials/Advertiser-sidenav/AdvertiserSidenavProfile";
import AdvertiserPrefixedRoutes, {
  advertiserPrefixedRoutes,
  advertiserRoutes,
} from "../../../../routes/all-routes/advertiserRoutes";

const AdvertiserSideBar = () => {
  const handleLogout = useLogout();
  const location = useLocation();
  const [open, setOpen] = useState("");
  const [sidebarVisible, setSidebarVisible] = useState(true); // State to manage sidebar visibility

  // Function to toggle sidebar visibility
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  // Function to detect screen size changes and show/hide sidebar accordingly
  useEffect(() => {
    const handleResize = () => {
      const isSmallScreen = window.innerWidth < 768; // Adjust breakpoint as needed
      if (isSmallScreen) {
        setSidebarVisible(false); // Hide sidebar on small screens
      } else {
        setSidebarVisible(true); // Show sidebar on large screens
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call the function initially
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleOpenSubMenu = (key) => {
    if (open === key) {
      setOpen("");
    } else {
      setOpen(key);
    }
  };

  return (
    <div className="text-white  d-flex  ">
      <Sidebar
        backgroundColor="rgba(0,0,0,0)"
        rootStyles={{
          background: "var(--primary-dashboard)",
          display: sidebarVisible ? "block" : "none", // Hide or show sidebar based on state
        }}
        className="sidebar-content" // Add a class for event handling
      >
        <div className="">
          <AdvertiserSidenavProfile />

          {/* Menu */}
          <div className=" sideBar-menu custom-scroll">
            <Menu
              transitionDuration={800}
              renderExpandIcon={({ open }) => (
                <span className={open ? "arrow-collapsed" : "arrow-expanded"}>
                  <ArrowIcon />
                </span>
              )}
              menuItemStyles={{
                button: ({ level, active }) => {
                  // only apply styles on first level elements of the tree
                  if (level === 0)
                    return {
                      color: active ? "white" : "var(--text-menu-color)",

                      backgroundColor: active
                        ? "var(--focus-color)"
                        : "var(--primary-Dashboard)",
                      transition: "background-color .3s ease-in-out",
                      "&:hover": {
                        backgroundColor: "var(--focus-color)",
                      },
                      marginLeft: 12,
                      marginRight: 12,
                      marginBottom: 6,
                      borderRadius: 6,
                      padding: 6,
                    };
                  // only apply styles on second level elements of the tree
                  if (level === 1)
                    return {
                      color: active ? "white" : "var(--text-menu-color)",

                      backgroundColor: active
                        ? "var(--focus-color)"
                        : "var(--sub-menu-color)",
                      transition: "background-color .3s ease-in-out",
                      "&:hover": {
                        backgroundColor: "var(--focus-color)",
                      },
                      padding: 18,
                    };
                },
              }}
            >
              {/* Dashboard */}
              <MenuItem
                onClick={() => handleOpenSubMenu("")}
                active={location.pathname.includes(advertiserRoutes.DASHBOARD)}
                className={` ${
                  location.pathname.includes(advertiserRoutes.DASHBOARD)
                    ? ""
                    : "menu-svg"
                }`}
                icon={<DashboardIcon />}
                component={<Link to={AdvertiserPrefixedRoutes.DASHBOARD} />}
              >
                Dashboard
              </MenuItem>

              {/* Influencer List */}
              <MenuItem
                onClick={() => handleOpenSubMenu("")}
                active={location.pathname.includes(
                  advertiserRoutes.INFLUENCER_LIST
                )}
                className={` ${
                  location.pathname.includes(advertiserRoutes.INFLUENCER_LIST)
                    ? ""
                    : "menu-svg"
                }`}
                icon={<UserManagementIcon />}
                component={
                  <Link to={AdvertiserPrefixedRoutes.INFLUENCER_LIST} />
                }
              >
                Influencer List
              </MenuItem>

              {/* Order Placement */}
              <MenuItem
                onClick={() => handleOpenSubMenu("")}
                active={location.pathname.includes(
                  advertiserRoutes.ORDER_PLACEMENT
                )}
                className={` ${
                  location.pathname.includes(advertiserRoutes.ORDER_PLACEMENT)
                    ? ""
                    : "menu-svg"
                }`}
                icon={<BookingIcon />}
                component={
                  <Link to={AdvertiserPrefixedRoutes.ORDER_PLACEMENT} />
                }
              >
                Order Placement
              </MenuItem>

              {/* Transaction */}
              <MenuItem
                onClick={() => handleOpenSubMenu("")}
                active={location.pathname.includes(
                  advertiserRoutes.TRANSACTION
                )}
                className={` ${
                  location.pathname.includes(advertiserRoutes.TRANSACTION)
                    ? ""
                    : "menu-svg"
                }`}
                icon={<TransactionIcon />}
                component={
                  <Link to={AdvertiserPrefixedRoutes.TRANSACTION} />
                }
              >
                Transactions
              </MenuItem>

              {/*   Message */}
              <MenuItem
                onClick={() => handleOpenSubMenu("")}
                active={location.pathname.includes(
                  AdvertiserPrefixedRoutes.MESSAGE
                )}
                className={` ${
                  location.pathname.includes(AdvertiserPrefixedRoutes.MESSAGE)
                    ? ""
                    : "menu-svg"
                }`}
                icon={<MessageWhiteIcon />}
                component={<Link to={AdvertiserPrefixedRoutes.MESSAGE} />}
              >
                Message
              </MenuItem>

              {/*   Ratings */}
              {/* <MenuItem
                onClick={() => handleOpenSubMenu("")}
                active={location.pathname.includes(advertiserRoutes.RATINGS)}
                className={` ${
                  location.pathname.includes(advertiserRoutes.RATINGS)
                    ? ""
                    : "menu-svg-3"
                }`}
                icon={<AnalyticsIcon />}
                component={<Link to={AdvertiserPrefixedRoutes.RATINGS} />}
              >
                Ratings and Feedback
              </MenuItem> */}

              <MenuItem
                onClick={() => handleOpenSubMenu("")}
                active={location.pathname.includes(
                  advertiserRoutes.ADVERTISER_SETTINGS
                )}
                className={` ${
                  location.pathname.includes(
                    advertiserRoutes.ADVERTISER_SETTINGS
                  )
                    ? ""
                    : "menu-svg"
                }`}
                icon={<SettingsNewIcon />}
                component={
                  <Link to={AdvertiserPrefixedRoutes.ADVERTISER_SETTINGS} />
                }
              >
                Settings
              </MenuItem>

              {/* Settings menu */}
              {/* <SubMenu
                onClick={() => handleOpenSubMenu("settings")}
                open={open === "settings"}
                defaultOpen={location.pathname.includes("settings")}
                active={location.pathname.includes("settings")}
                label="Settings"
                className={` ${
                  location.pathname.includes("settings") ? "" : " menu-svg-1"
                }`}
                icon={<SettingsNewIcon />}
              >
                <MenuItem
                  active={location.pathname.includes("web-content")}
                  className={` ${
                    location.pathname.includes("web-content")
                      ? ""
                      : "menu-svg-2"
                  }`}
                  icon={<DotIcon />}
                  component={<Link to="settings/web-content" />}
                >
                  Web Content CMS
                </MenuItem>

                <MenuItem
                  active={location.pathname.includes(advertiserRoutes.CATEGORY)}
                  className={` ${
                    location.pathname.includes(advertiserRoutes.CATEGORY)
                      ? ""
                      : "menu-svg-2"
                  }`}
                  icon={<DotIcon />}
                  component={<Link to={advertiserPrefixedRoutes.CATEGORY} />}
                >
                  Category
                </MenuItem>

                <MenuItem
                  active={location.pathname.includes(advertiserRoutes.TAG)}
                  className={` ${
                    location.pathname.includes(advertiserRoutes.TAG)
                      ? ""
                      : "menu-svg-2"
                  }`}
                  icon={<DotIcon />}
                  component={<Link to={advertiserPrefixedRoutes.TAG} />}
                >
                  Tag
                </MenuItem>
              </SubMenu> */}
            </Menu>
          </div>

          {/* Logout */}
          <div className=" bg-primary-color-dark mt-120px">
            <hr className="m-0 opacity-100 " />
            <div className="d-flex justify-content-center align-items-end m-5">
              <div
                onClick={handleLogout}
                className="cursor-pointer text-decoration-none text-white d-flex gap-3 align-items-center justify-content-center "
              >
                <LogoutIcon /> <span>Logout</span>
              </div>
            </div>
          </div>
        </div>
      </Sidebar>

      {/* Hamburger icon for small screens */}
      <div
        className="d-block d-md-none ms-1  cursor-pointer   z-index-10  "
        onClick={() => toggleSidebar()}
      >
        {sidebarVisible ? <CloseIcon /> : <HamburgerIcon />}
      </div>
    </div>
  );
};
export default AdvertiserSideBar;
