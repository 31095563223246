import React, { useState } from "react";
import { BiSolidCategoryAlt } from "react-icons/bi";
import { GiDuration } from "react-icons/gi";

import {
  SocialIcons,
  SocialPlatformColors,
  PackageTypeImage,
} from "../../../../utils/Socials";
import { Link } from "react-router-dom";
import advertiserPrefixedRoutes from "../../../../routes/all-routes/advertiserRoutes";

const ServiceCard = ({ data }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const backgroundClass =
    SocialPlatformColors[data?.socialPlatformName?.toLowerCase()] ||
    "bg-default";
  const image = PackageTypeImage[data?.typeOfPackage]?.toLowerCase() || "";
  const icon = SocialIcons[data?.socialPlatformName?.toLowerCase()] || "";

  const toggleDescription = () => setIsExpanded(!isExpanded);

  const descriptionPreview = data?.description.slice(0, 50);
  const isLongDescription = data?.description.length > 100;

  return (
    <div
      className={`service-card border rounded shadow-sm p-2 d-flex flex-column justify-content-between h-100 ${backgroundClass} w-100 `}
    >
      <div className="d-flex flex-column justify-content-between flex-grow-1 bg-secondary-color rounded p-2">
        <div>
          {/* Image */}
          <div className="service-image-container">
            <img
              src={image}
              alt={data?.typeOfPackage}
              className="img-fluid object-fit-cover"
            />
          </div>
          {/* Title */}
          <h6 className="mt-3 fw-bold">{data?.packageName}</h6>

          {/* Subtitle with 'See More'/'See Less' */}
          <p className="flex-grow-1">
            {isExpanded ? data?.description : descriptionPreview}
            {" "}
            {isLongDescription && (
              <button
                className="btn btn-link p-0 link-style"
                onClick={toggleDescription}
                // style={{ color: "#007bff" }}
              >
                {isExpanded ? "See Less" : "More..."}
              </button>
            )}
          </p>
        </div>

        {/* Price and Book Now */}
        <div>
          <div className="d-flex align-items-center gap-2">
            <span>{icon}</span>
            <span className="fw-medium">
              <BiSolidCategoryAlt /> {data?.typeOfPackage}
            </span>
            <span className="fw-medium">
              <GiDuration /> {data?.duration} Days
            </span>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-3">
            <span className="fw-medium fs-6">${data?.price}</span>
            <Link
              className="btn bg-primary-color text-white"
              to={advertiserPrefixedRoutes.DASHBOARD}
            >
              Book Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
