import React from "react";
import ServiceCard from "./ServiceCard";
// Import Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay  } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";

const ServiceContainer = ({ title, data }) => {
  return (
    <div className="service-container my-5">
      <h2 className="title pb-4">{title}</h2>
      <Swiper
        slidesPerView={1}
        spaceBetween={20}
        autoplay={{ delay: 2000 }}
        loop={true}
        pagination={{ clickable: true }}
        modules={[Autoplay]}
        breakpoints={{
          640: { slidesPerView: 1 }, // 1 card for small screens
          768: { slidesPerView: 2 }, // 2 cards for tablets
          1024: { slidesPerView: 3 }, // 3 cards for medium screens
          1200: { slidesPerView: 4 }, // 4 cards for large screens
        }}
        className="h-100 py-2 d-grid"
      >
        {data.map((item, index) => (
          <SwiperSlide key={index} className="d-flex h-100 ">
            <ServiceCard
              data={item}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ServiceContainer;
