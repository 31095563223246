// useGetCategoryList.js
import { useEffect, useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";
import { useNavigate } from "react-router-dom";

const useGetCategoryList = (pageSize, pageNumber) => {
  const token = useSelector((state) => state.auth.user.accessToken);
  const [submitSuccess, setSubmitSuccess] = useState(false); // New state for submit success
  const [error, setError] = useState(null);
  const [category, setCategory] = useState({});
  const [categoryError, setCategoryError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  const navigate = useNavigate();

  const fetchCategoryList = useQuery({
    queryKey: ["featurelist", pageSize, pageNumber],
    queryFn: async () => {
      try {
        setIsLoading(true);
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/admin/featured/all?pageSize=${pageSize}&pageNumber=${pageNumber}`;
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          // const text = await response.json();
          // if (text?.error?.status === 401) {
          //   navigate(authPrefixedRoutes.LOGIN);
          // }
          const text1 = await response.text();
          setCategoryError(text1);
          setIsLoading(false);
          throw new Error("Failed to fetch Feature List");
        }

        const responseData = await response.json();
        setCategory(responseData); // Assuming responseData contains clinic details
        setTotalRows(responseData.pagination.total_count);
        setIsLoading(false);

        return responseData;
      } catch (error) {
        setCategoryError(error);
        setIsLoading(false);
        throw error;
      }
    },
    onError: (error) => {
      setCategoryError(error);
      setIsLoading(false);
      throw error;
    },
  });

  useEffect(() => {
    setTotalRows(category?.pagination?.total_count);
  }, [category]);

  useEffect(() => {
    fetchCategoryList.refetch();
  }, []);

  const { mutate: updateCategoryStatus, isError } = useMutation({
    mutationKey: ["updatefeaturestatus"],

    mutationFn: async (id) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/admin/featured/${id}/toggle`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();

        if (!response.ok) {
          const text = await response.json();
          if (text?.error?.status === 401) {
            navigate(authPrefixedRoutes.LOGIN);
          }
          throw new Error(data.error.message);
        }

        if (response.status === 200) {
          toast.success("Feature state changed successfully!");
          fetchCategoryList.refetch();
        }

        setCategory((prevState) => {
          return {
            ...prevState,
            data: prevState?.data?.map((item) => {
              if (item._id === id)
                return {
                  ...item,
                  status: item.status === "active" ? "deactivate" : "active",
                };
              return item;
            }),
          };
        });

        setSubmitSuccess(true);

        return data;
      } catch (error) {
        // Set login error state
        setError(error);
        throw error; // Re-throw the error to be caught by onError
      } finally {
        setIsLoading(false);
      }
    },
    onError: (error) => {
      setError(error);
      throw error; // Re-throw the error to be caught by onError
    },
  });

  const { mutate: deleteCategory, isErrorDelete } = useMutation({
    mutationKey: ["deletefeature"],
    mutationFn: async (id) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/admin/featured/${id}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();

        if (!response.ok) {
          const text = await response.json();
          if (text?.error?.status === 401) {
            navigate(authPrefixedRoutes.LOGIN);
          }
          throw new Error(data.error.message);
        }

        if (response.status === 200) {
          toast.success("Feature deleted successfully!");

          fetchCategoryList.refetch();
        }

        setSubmitSuccess(true);

        return data;
      } catch (error) {
        // Set login error state
        setError(error);
        throw error; // Re-throw the error to be caught by onError
      } finally {
        setIsLoading(false);
      }
    },
    onError: (error) => {
      setError(error);
      throw error; // Re-throw the error to be caught by onError
    },
  });

  const fetchCategory = (pageSize, pageNumber) => {
    fetchCategoryList.refetch(pageSize, pageNumber);
  };

  return {
    updateCategoryStatus,
    deleteCategory,
    fetchCategory,
    category,
    isLoading,
    categoryError,
    fetchCategoryList,
    totalRows,
    setTotalRows,
  };
};

export default useGetCategoryList;
