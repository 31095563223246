import React from "react";
import Button from "../button/Button";
import { useNavigate } from "react-router";




const HeroSection1 = ({ data, order = 1, img1, img2, buttonUrl }) => {
  const { button_text, image_one, image_two, paragraph, title } = data?.content;
  const navigate = useNavigate();

  const ImageWrapperHeroSection1 = () => {
    return (
      <div className="image-wrapper ">
        <img src={image_one} className="img-fluid" alt="Hero" />
      </div>
    );
  };

  const ImageWrapperHeroSection2 = () => {
    return (
      <div className="image-wrapper ms-auto ">
        <img src={image_one} className="img-fluid" alt="Hero" />
      </div>
      // <div className="image-wrapper-2 ms-auto position-relative">
      //   <div className="position-relative">
      //     {/* Main background image */}
      //     <img src={image_two} alt="Hero Section" className="img-fluid rad-5" />
      //     <div className="overlay"></div>
      //     <img
      //       src={image_one}
      //       alt="Hero Section"
      //       className="hero-image-2  img-fluid position-absolute rad-5"
      //       style={{ zIndex: 1 }}
      //     />
      //   </div>
      // </div>
    );
  };

  return (
    <div className={`${order === 2 && "bg-primary-color-light-2"}`}>
      <div className={`max-width mx-auto mt-5 pt-5 mb-5 pb-5 px-4 `}>
        <div
          className={`row align-items-center position-relative ${
            order === 2 && "flex-row-reverse"
          }`}
        >
          {/* Image Wrapper */}
          <div className="col-md-6 desktop-view position-relative">
            {order === 1 ? (
              <ImageWrapperHeroSection1 />
            ) : (
              <ImageWrapperHeroSection2 />
            )}
          </div>

          {/* Content Text */}
          <div className="col-md-6">
            <div className=" mb-3">
              <h2 className="title-40 fw-semibold">{title}</h2>
            </div>
            <div className="mobile-view mt-5 mb-5 position-relative">
              {order === 1 ? (
                <ImageWrapperHeroSection1 />
              ) : (
                <ImageWrapperHeroSection2 />
              )}
            </div>
            <div className="">
              <p className="fs-16 color-title-color mb-3 py-3">{paragraph}</p>
              <Button
                onClick={() => navigate(buttonUrl)}
                label={button_text}
                className={"col-12 col-sm-auto mb-3"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection1;
