const adminPrefix = "admin";
const adminRoutes = {
  DASHBOARD: "/",
  ADVERTISER: "/users/advertiser",
  ADVERTISER_DETAILS: "/users/advertiser/details",
  INFLUENCER: "/users/influencer",
  INFLUENCER_DETAILS: "/users/influencer/details",
  DISPUTES: "/booking/disputes",
  CREATE_DISPUTES: "/products/add-product-category",
  PACKAGES: "/packages",
  ORDERS: "/orders",
  TRANSACTION: "/transaction",
  MESSAGE: "/message",
  SETTINGS: "/settings",
  WEB_CONTENT_SETTINGS: "/settings/web-content",
  GENERAL_SETTINGS: "/settings/general-settings",
  CATEGORY: "/settings/category",
  CREATE_CATEGORY: "/settings/category/create-category",
  UPDATE_CATEGORY: "/settings/category/update-category",
  TAG: "/settings/tag",
  CREATE_TAG: "/settings/tag/create-tag",
  UPDATE_TAG: "/settings/tag/update-tag",
  FEATURE: "/settings/feature",
  CREATE_FEATURE: "/settings/feature/create-feature",
  UPDATE_FEATURE: "/settings/feature/update-feature",
  PAYMENT: "/payment",
  REPORT: "/report",
};

const adminPageNames = {
  DASHBOARD: "Dashboard",
  ADVERTISER: "User Management",
  ADVERTISER_DETAILS: "Advertiser Details",
  INFLUENCER: "User Management",
  INFLUENCER_DETAILS: "Influencer Details",
  CREATE_DISPUTES: "Add New Product Category",
  DISPUTES: "Booking Oversight",
  PACKAGES: "Packages",
  ORDERS: "Orders",
  TRANSACTION: "Transaction",
  MESSAGE: "Message",
  SETTINGS: "Settings",
  WEB_CONTENT_SETTINGS: "Web Content Settings",
  GENERAL_SETTINGS: "General Settings",
  CATEGORY: "Category",
  CREATE_CATEGORY: "Create Category",
  UPDATE_CATEGORY: "Update Category",
  TAG: "tag",
  CREATE_TAG: "Create Tag",
  UPDATE_TAG: "Update Tag",
  FEATURE: "Feature",
  CREATE_FEATURE: "Create Feature",
  UPDATE_FEATURE: "Update Feature",
  PAYMENT: "Payment",
  REPORT: "Report",
};

const adminPrefixedRoutes = {};

// Loop through each key in adminRoutes and add the prefix
for (const key in adminRoutes) {
  if (adminRoutes.hasOwnProperty(key)) {
    adminPrefixedRoutes[key] = `/${adminPrefix}${adminRoutes[key]}`;
  }
}

export default adminPrefixedRoutes;

export { adminPrefix, adminRoutes, adminPrefixedRoutes, adminPageNames };
