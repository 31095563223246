import React, { useEffect } from "react";
import ProductDetailsInfo from "../productDetailsInfo/ProductDetailsInfo";
import useGetProductDetailsById from "../../../../hooks/data-hooks/admin/product/useGetProductDetailsById";
import { useParams } from "react-router";
import Loader from "../../../common/components/loader/Loader";
import { Link } from "react-router-dom";
import PackageCard from "../../../common/components/package/PackageCard";
import BackButton from "../../../../helpers/backButton";

const PackageDetails = () => {
  const params = useParams();
  const { fetchProductDetailWithId, isLoading, productDetails } =
    useGetProductDetailsById(params?.id);

  useEffect(() => {
    fetchProductDetailWithId(params?.id); // Fetch clinic details when product-id/param-id changes
  }, [params?.id]);

  console.log(productDetails);

  if (isLoading) return <Loader />;
  return (
    <div className="container ">
      <BackButton />
      <PackageCard data={productDetails} role="admin" />
    </div>
  );
};
export default PackageDetails;
