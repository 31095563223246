// WebRouter.js
import React from "react";
import { Routes, Route } from "react-router-dom";
// Import Web panel components
import WebLayout from "../modules/web/components/layout/WebLayout";
import Home from "../modules/web/components/pages/Home";
import { webRoutes } from "./../routes/all-routes/webRoutes.js";
// Import any other web components you want to include
import AboutUs from "../modules/web/components/pages/AboutUs";
import ContactUs from "../modules/web/components/pages/ContactUs";
import PageNotFound from "./../modules/common/components/PageNotFound";
import AllInfluencers from "../modules/web/components/pages/AllInfluencers.js";
import InfluencerDetails from "../modules/web/components/pages/InfluencerDetails.js";

const WebRouter = () => {
  return (
    <WebLayout>
      <Routes>
        <Route path={webRoutes.HOME} element={<Home />} />
        <Route path={webRoutes.ABOUT_US} element={<AboutUs />} />
        <Route path={webRoutes.CONTACT_US} element={<ContactUs />} />
        <Route path={webRoutes.INFLUENCERS} element={<AllInfluencers />} />
        {/* Dynamic route for influencer details */}
        <Route path={`${webRoutes.INFLUENCERS}/:id`} element={<InfluencerDetails />} />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </WebLayout>
  );
};

export default WebRouter;
