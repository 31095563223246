import React from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({
  pageCount,
  currentPage,
  handlePageClick,
  isLoading,
  error,
}) => {
  return (
    <div className="d-flex justify-content-center">
      
      {!isLoading && !error && pageCount > 1 && (
        <ReactPaginate
          breakLabel=""
          nextLabel="Next"
          onPageChange={handlePageClick}
          forcePage={currentPage - 1} // Pages are 0-indexed in ReactPaginate
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="Previous"
          marginPagesDisplayed={1}
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          previousLinkClassName="custom-previous-link"
          nextLinkClassName="custom-next-link"
          pageLinkClassName="custom-page-link"
          activeLinkClassName="active-page-link"
          disabledLinkClassName="disabled-page-link"
        />
      )}
    </div>
  );
};

export default Pagination;
