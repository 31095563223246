import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const TransactionTable = ({
  data,
  meta,
  isLoading,
  setIsLoading,
  error,
  currentPage,
  setCurrentPage,
  fetchTransactionListWithParams,
  pageSize,
  setPageSize,
  selectedLab,
  totalRows,
  setTotalRows,
  handlePageChange,
  handlePerRowsChange,
}) => {
  const navigate = useNavigate();

  const columns = [
    {
      name: "#",
      selector: (row, index) =>
        (meta?.pagination?.current_page - 1) * pageSize + index + 1,
      sortable: true,
    },

    {
      name: "Transaction ID",
      selector: (row) => (
        <Link className="link-style" to={`/admin/transaction/${row._id}`}>
          {row._id}
        </Link>
      ),
      sortable: true,
    },
    {
      name: "Advertiser",
      selector: (row) => <>{row.orderId.advertiserId.fullName}</>,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => {
        const date = new Date(row.createdAt);
        return date.toLocaleString("en-US", {
          day: "2-digit",
          month: "short",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
      },
      sortable: true,
    },
    {
      name: "Payment Method",
      selector: (row) => {
        return row.paymentMethod;
      },
      sortable: true,
    },

    {
      name: "Amount",
      selector: (row) => {
        return row.amount;
      },

      sortable: true,
    },
    {
      name: "Order Status",
      selector: (row) => {
        return row.orderId.orderStatus;
      },

      sortable: true,
    },
    // {
    //   name: "Receipt",
    //   selector: (row) => (
    //     <>
    //       <button className="btn btn-3 mt-2 ">Download</button>
    //     </>
    //   ),
    //   sortable: true,
    // },
  ];

  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "var(--primary-color)",
        color: "#fff",
      },
    },
  };

  return (
    <div>
      
      <div className="shadow border">
        <DataTable
          customStyles={tableHeaderstyle}
          columns={columns}
          data={data}
          progressPending={isLoading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          fixedHeader
          highlightOnHover
          // onRowClicked={(val) => {
          //   navigate(`/admin/transaction/${val._id}`);
          // }}
        />
      </div>
    </div>
  );
};

export default TransactionTable;
