// Transaction Card
const TransactionCard = (data) => {
  const { title, amount, time,image } = data;
  return (
    <div className="  col-md-6 col-lg-4">
      <div className="d-flex flex-column  justify-content-start p-3 rounded-4 shadow border bg-white ">
        <div className="w-25">
          <img className="w-75" src={image} alt="" />
        </div>
        <span className=" transaction-title text-title fs-20 mt-2 ">
          {title}
        </span>

        <span className=" mt-2 transaction-amount text-title fs-3 fw-bold text-primary-color">
          ${amount}
        </span>

        <span className="text-title fs-6 text-body-secondary ">{time}</span>
      </div>
    </div>
  );
};

export default TransactionCard;
