import React, { useMemo } from "react";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import useGetOrderDetailsById from "../../../../hooks/data-hooks/influencer/order/useGetOrderDetailsById";
import Loader from "../../../common/components/loader/Loader";
import { getFormattedDate } from "../../../../helpers/getFormattedDate";
import BackButton from "../../../../helpers/backButton";
import OrderProgressStepper from "../../../common/components/OrderProgressStepper";
import influencerPrefixedRoutes from "../../../../routes/all-routes/influencerRoutes";
import MessageBadge from "../../../admin/components/message/MessageBadge";
import { cloneUniforms } from "three/src/renderers/shaders/UniformsUtils.js";

const OrderDetails = () => {
  const { id } = useParams();
  const { isLoading, isError, data, refetchData } = useGetOrderDetailsById(id);


  const columns = useMemo(
    () => [
      {
        name: "Package Name",
        selector: (row) => <>{row?.packageId?.packageName}</>,
        sortable: true,
      },
      {
        name: "Platform",
        selector: (row) => <>{row?.packageId?.socialPlatformName}</>,
        sortable: true,
      },
      {
        name: "Type",
        selector: (row) => <>{row?.packageId?.typeOfPackage}</>,
        sortable: true,
      },
      {
        name: "Item Price",
        selector: (row) => <>{row?.packageId?.price}</>,
        sortable: true,
      },
      {
        name: "Quantity",
        selector: (row) => <>{row?.quantity}</>,
        sortable: true,
      },
      {
        name: "Total Price",
        selector: (row) => <>{row?.totalPrice}</>,
        sortable: true,
      },
    ],
    []
  );

  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "var(--primary-color)",
        color: "#fff",
      },
    },
  };


  if (isLoading) return <Loader />;

  return (
    <div className="container">
      <BackButton />

      {/* Advertiser Details */}
      <div className="my-3 shadow bg-white p-3 border rounded-3 vstack">
        <h4 className="color-title-color fw-bold mb-3">Advertiser Details</h4>
        <div className="row p-3">
          <div className="col pe-3">
            <p>
              <span className="fw-bold">Name: </span>
              {data?.documents?.advertiserId?.fullName}
            </p>
            <p>
              <span className="fw-bold">Email: </span>
              {data?.documents?.advertiserId?.email}
            </p>
            <p>
              <span className="fw-bold">Order Placed: </span>
              {getFormattedDate(data?.documents?.createdAt)}
            </p>
          </div>
          <div className="col pe-3">
            <p>
              <span className="fw-bold">Order Number: </span>
              {data?.documents?.orderNumber}
            </p>
            <p>
              <span className="fw-bold">Order Status: </span>
              {data?.documents?.orderStatus}
            </p>
            <p>
              <span className="fw-bold">Payment Status: </span>
              {data?.documents?.paymentStatus}
            </p>
          </div>
        </div>

        {/* Order Progress Stepper */}
        <OrderProgressStepper
          orderId={data?.documents?._id}
          orderStatus={data?.documents?.orderStatus}
          paymentStatus={data?.documents?.paymentStatus}
          refetchData={refetchData}
          role="influencer"
        />
        <Link
          to={`${influencerPrefixedRoutes.MESSAGE}/${data?.documents?._id}`}
          className="link-style"
        >
          <div className="bg-highlight rounded-3 p-3 border fs-16 text-center mt-2">
            <span>Open Chat Conversation</span>
            {/* <MessageBadge messageCount={data?.documents?.totalMessages} />{" "} */}
          </div>
        </Link>
      </div>

      {/* List of Packages */}
      <div className="my-3 shadow bg-white p-3 border rounded-3">
        <DataTable
          title="List of Packages"
          customStyles={tableHeaderstyle}
          columns={columns}
          data={data?.documents?.orderItems}
          progressPending={isLoading}
          fixedHeader
          selectableRowsHighlight
          highlightOnHover
        />
      </div>
    </div>
  );
};

export default OrderDetails;
