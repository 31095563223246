import React from "react";
import { Helmet } from "react-helmet-async";
import PageHeader from "../page-header/PageHeader";
import ContactUsForm from "../contact-us-form/ContactUsForm";
// import Partner from "../partner/Partner";
// import useWebContent from "../../../../hooks/data-hooks/website/useWebContent";

const ContactUs = () => {
  // const { isLoading, data } = useWebContent();
  return (
    <div className="w-100 bg-white">
      <Helmet>
        <title>Contact Us | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <PageHeader
        // title={"CONTACT"}
        header={"Contact Us"}
        subTitle={"Home / Contact Us"}
      />
      <ContactUsForm />
      {/* <Partner data={data?.data?.brand?.content} /> */}
    </div>
  );
};

export default ContactUs;
