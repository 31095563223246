import React from 'react';

const Payment = () => {
    return (
      <div className="container">
        <h1>Payment Page</h1>
      </div>
    );
};

export default Payment;