// useGetProductCategoryList.js
import { useEffect, useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";
import { useNavigate } from "react-router-dom";

const useGetOrderListOfLab = (id, perPage, currentPage) => {
  const token = useSelector((state) => state.auth.user.accessToken);
  const [submitSuccess, setSubmitSuccess] = useState(false); // New state for submit success
  const [error, setError] = useState(null);
  const [orderList, setOrderList] = useState({});
  const [orderListError, setOrderListError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const fetchOrderListOfLabWithLabName = useQuery({
    queryKey: ["orderlistoflab"],
    queryFn: async () => {
      try {
        setIsLoading(true);
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/admin/order-management/orders?influencerId=${id}&pageSize=${perPage}&pageNumber=${currentPage}`;
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          const text = await response.json();
          if (text?.error?.status === 401) {
            navigate(authPrefixedRoutes.LOGIN);
          }
          const text1 = await response.text();
          setOrderListError(text1);
          setIsLoading(false);
          throw new Error("Failed to fetch clinic details");
        }

        const responseData = await response.json();
        setOrderList(responseData); // Assuming responseData contains clinic details
        setIsLoading(false);

        return responseData;
      } catch (error) {
        setOrderListError(error);
        setIsLoading(false);
        throw error;
      }
    },
    onError: (error) => {
      setOrderListError(error);
      setIsLoading(false);
      throw error;
    },
  });


  const fetchOrderListOfLab = (perPage, pageNumber) => {
    fetchOrderListOfLabWithLabName.refetch(perPage, pageNumber);
  };

  useEffect(() => {
    fetchOrderListOfLab(perPage, currentPage);
  }, []);

  return {
    fetchOrderListOfLab,
    orderList,
    isLoading,
    orderListError,
  };
};

export default useGetOrderListOfLab;
