import ReactPaginate from "react-paginate";
import { Helmet } from "react-helmet-async";
import { SearchIcon } from "../../../../utils/MyIcons";
import { Link } from "react-router-dom";
import useLabListLogic from "../../../../hooks/data-hooks/admin/lab-management/useLabListLogic";

import MyImage from "../../../../custom-components/MyImage";
import TableShimmer from "../../../common/components/shimmers/TableShimmer";
import PaginationShimmer from "../../../common/components/shimmers/PaginationShimmer";
import { Str } from "@jimuelpalaca/str";
import { getItemNumber } from "../../../../helpers/textHelpers";
import NoDataInTable from "../../../common/components/blanks/NoDataInTable";
import adminPrefixedRoutes, {
  adminPageNames,
} from "../../../../routes/all-routes/adminRoutes";
import { useEffect } from "react";

const Influencer = () => {
  const {
    register,
    pageSize,
    handleSubmit,
    onSubmit,
    isSubmitting,
    currentPage,
    setCurrentPage,
    handlePageClick,
    searchTerm,
    filterStatus,
    setFilterStatus,
    labList,
    isLoading,
    labListError,
    labListMeta,
    debouncedSubmit,
    labStates,
    handleVerifyLab,
    handleActiveDeactiveLab,
  } = useLabListLogic();

  // console.log("labList", labList);

  return (
    <>
      <Helmet>
        <title>
          {adminPageNames.INFLUENCER} | Admin | {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <div className="container">
        <div className="shadow ">
          <h5 className=" p-4 bg-white rounded-top-3 mb-0 fw-bold">
            Influencer List
          </h5>
          <div className="p-4 bg-white rounded-bottom-3 table-responsive vstack gap-4">
            <div className="vstack gap-4">
              {/* table header */}
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="d-flex justify-content-between align-items-center"
              >
                {/* table filter */}

                <div className="d-flex gap-2">
                  <select
                    className="form-select outline-none"
                    aria-label="Status"
                    name="status"
                    {...register("status")}
                    onChange={(event) => {
                      const selectedStatus = event.target.value;
                      setFilterStatus(selectedStatus); // Update filterStatus state
                      onSubmit({ searchTerm, status: selectedStatus }); // Trigger onSubmit directly
                    }}
                  >
                    <option value="">Filter</option>
                    <option value="active">Active</option>
                    <option value="pending">Pending</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
                {/* table search */}
                {/* <div className="d-flex gap-2">
                  <div className=" search border border-1 rounded-3 d-flex align-items-center">
                    <span className="ps-3">
                      <SearchIcon />
                    </span>
                    <input
                      type="text"
                      className="rounded-3 p-2 w-min-250px border-0 bg-transparent "
                      placeholder="Search"
                      aria-label="Search"
                      name="searchTerm"
                      {...register("searchTerm")}
                      onChange={(event) =>
                        debouncedSubmit({
                          searchTerm: event.target.value,
                          status: filterStatus,
                        })
                      }
                    />
                  </div>
                </div> */}
              </form>
              {/* table */}
              <div className="table-responsive min-h-600px border rounded-bottom-3">
                {(isLoading || labListError) && true && <TableShimmer />}
                {!isLoading && !labListError && labList.length === 0 && (
                  <NoDataInTable />
                )}
                {!isLoading && !labListError && labList.length > 0 && (
                  <table className="table table-bordered align-middle">
                    <thead>
                      <tr>
                        <th
                          colSpan={1}
                          scope="col"
                          className="bg-secondary-color ps-3"
                        >
                          #No
                        </th>
                        <th
                          colSpan={2}
                          scope="col"
                          className="bg-secondary-color ps-3"
                        >
                          Influencer
                        </th>
                        <th
                          colSpan={2}
                          scope="col"
                          className="bg-secondary-color ps-3"
                        >
                          Email
                        </th>
                        <th
                          colSpan={1}
                          scope="col"
                          className="bg-secondary-color ps-3"
                        >
                          Phone Number
                        </th>
                        <th
                          colSpan={1}
                          scope="col"
                          className="bg-secondary-color ps-3"
                        >
                          Status
                        </th>
                        <th
                          colSpan={2}
                          scope="col"
                          className="bg-secondary-color ps-3"
                        >
                          Actions
                        </th>
                        {/* <th
                          colSpan={2}
                          scope="col"
                          className="bg-secondary-color ps-3"
                        >
                          Verified
                        </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {labList.map((lab, index) => (
                        <tr key={index}>
                          <td
                            colSpan={1}
                            className="ps-3 fs-14 fw-light text-text"
                          >
                            {getItemNumber(pageSize, currentPage, index)}
                          </td>
                          <td
                            colSpan={2}
                            className="ps-3 fs-14 fw-light text-text deco"
                          >
                            <Link
                              to={`${adminPrefixedRoutes.INFLUENCER_DETAILS}/${lab?._id}`}
                              className="link-style"
                            >
                              <div className="text-nowrap">
                                <MyImage
                                  src={lab?.profileImageUrl}
                                  addBaseUrl={false}
                                  alt=""
                                  className="w-h-24px rounded-circle me-2 "
                                />
                                <span className="fs-14 fw-light  ">
                                  {lab?.fullName}
                                </span>
                              </div>
                            </Link>
                          </td>
                          <td
                            colSpan={2}
                            className="ps-3 fs-14 fw-light text-text"
                          >
                            {lab?.email ?? "Info unavailable"}
                          </td>
                          <td
                            colSpan={1}
                            className="ps-3 fs-14 fw-light text-text"
                          >
                            {lab?.phoneNumber ?? "Info unavailable"}
                          </td>
                          <td
                            colSpan={1}
                            className="ps-3 fs-14 fw-light text-text"
                          >
                            {lab?.status ?? "Info unavailable"}
                            {/* {Str.snakeToWord(lab.status ?? "unknown")} */}
                          </td>
                          <td
                            colSpan={2}
                            className="ps-3 fs-14 fw-light text-text"
                          >
                            {
                              <div className="form-switch">
                                <input
                                  className="form-check-input cursor-pointer"
                                  type="checkbox"
                                  role="switch"
                                  checked={lab?.status === "active"}
                                  onChange={() =>
                                    handleActiveDeactiveLab(lab?._id)
                                  }
                                  disabled={
                                    labStates[lab._id]?.activeDeactiveLabLoading
                                  } // Disable switch when loading
                                />
                              </div>
                            }
                            {/* {lab.status !== "pending" && (
                              <div className="form-switch">
                                <input
                                  className="form-check-input cursor-pointer"
                                  type="checkbox"
                                  role="switch"
                                  checked={lab.status === "active"}
                                  onChange={() =>
                                    handleActiveDeactiveLab(lab._id)
                                  }
                                  disabled={
                                    labStates[lab._id]?.activeDeactiveLabLoading
                                  } // Disable switch when loading
                                />
                              </div>
                            )} */}
                          </td>
                          {/* <td
                            colSpan={2}
                            className="ps-3 fs-14 fw-light text-text"
                          >
                            {
                              <div className="form-switch">
                                <input
                                  className="form-check-input cursor-pointer"
                                  type="checkbox"
                                  role="switch"
                                  checked={lab.creatorId.isVerified === true}
                                  onChange={() => handleVerifyLab(lab._id)}
                                  disabled={
                                    labStates[lab._id]?.verifyLabLoading
                                  } // Disable switch when loading
                                />
                              </div>
                            }
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>

            <span className="d-flex justify-content-end">
              {(isLoading || labListError) && <PaginationShimmer />}
              {!isLoading &&
                !labListError &&
                labListMeta &&
                labList.length > 0 && (
                  <ReactPaginate
                    breakLabel=""
                    nextLabel="Next"
                    onPageChange={handlePageClick}
                    forcePage={currentPage - 1}
                    pageRangeDisplayed={3}
                    pageCount={Math.ceil(
                      labListMeta?.pagination?.total_page ?? 1
                    )}
                    previousLabel="Previous"
                    marginPagesDisplayed={1}
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    previousLinkClassName="custom-previous-link"
                    nextLinkClassName="custom-next-link"
                    pageLinkClassName="custom-page-link"
                    activeLinkClassName="active-page-link"
                    disabledLinkClassName="disabled-page-link"
                  />
                )}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default Influencer;
// import ReactPaginate from "react-paginate";
// import { Helmet } from "react-helmet-async";
// import { SearchIcon } from "../../../../utils/MyIcons";
// import { Link } from "react-router-dom";
// import useLabListLogic from "../../../../hooks/data-hooks/admin/lab-management/useLabListLogic";

// import MyImage from "../../../../custom-components/MyImage";
// import TableShimmer from "../../../common/components/shimmers/TableShimmer";
// import PaginationShimmer from "../../../common/components/shimmers/PaginationShimmer";
// import { Str } from "@jimuelpalaca/str";
// import { getItemNumber } from "../../../../helpers/textHelpers";
// import NoDataInTable from "../../../common/components/blanks/NoDataInTable";
// import adminPrefixedRoutes from "../../../../routes/all-routes/adminRoutes";
// import { useEffect } from "react";

// const AdminLab = () => {
//   const {
//     register,
//     pageSize,
//     handleSubmit,
//     onSubmit,
//     isSubmitting,
//     currentPage,
//     setCurrentPage,
//     handlePageClick,
//     searchTerm,
//     filterStatus,
//     setFilterStatus,
//     labList,
//     isLoading,
//     labListError,
//     labListMeta,
//     debouncedSubmit,
//     labStates,
//     handleVerifyLab,
//     handleActiveDeactiveLab,
//   } = useLabListLogic();

//   // console.log("labList", labList);

//   return (
//     <>
//       <Helmet>
//         <title>Lab Management</title>
//       </Helmet>
//       <div className="container">
//         <div className="shadow ">
//           <h5 className="bg-primary-color p-4 text-white rounded-top-3 mb-0">
//             Influencer Management
//           </h5>
//           <div className="p-4 bg-white rounded-bottom-3 table-responsive vstack gap-4">
//             <div className="vstack gap-4">
//               {/* table header */}
//               <form
//                 onSubmit={handleSubmit(onSubmit)}
//                 className="d-flex justify-content-between align-items-center"
//               >
//                 {/* table filter */}

//                 <div className="d-flex gap-2">
//                   <select
//                     className="form-select outline-none"
//                     aria-label="Status"
//                     name="status"
//                     {...register("status")}
//                     onChange={(event) => {
//                       const selectedStatus = event.target.value;
//                       setFilterStatus(selectedStatus); // Update filterStatus state
//                       onSubmit({ searchTerm, status: selectedStatus }); // Trigger onSubmit directly
//                     }}
//                   >
//                     <option value="">Filter</option>
//                     <option value="active">Active</option>
//                     <option value="pending">Pending</option>
//                     <option value="inactive">Inactive</option>
//                   </select>
//                 </div>
//                 {/* table search */}
//                 <div className="d-flex gap-2">
//                   <div className=" search border border-1 rounded-3 d-flex align-items-center">
//                     <span className="ps-3">
//                       <SearchIcon />
//                     </span>
//                     <input
//                       type="text"
//                       className="rounded-3 p-2 w-min-250px border-0 bg-transparent "
//                       placeholder="Search"
//                       aria-label="Search"
//                       name="searchTerm"
//                       {...register("searchTerm")}
//                       onChange={(event) =>
//                         debouncedSubmit({
//                           searchTerm: event.target.value,
//                           status: filterStatus,
//                         })
//                       }
//                     />
//                   </div>
//                 </div>
//               </form>
//               {/* table */}
//               <div className="table-responsive min-h-600px border rounded-bottom-3">
//                 {(isLoading || labListError) && true && <TableShimmer />}
//                 {!isLoading && !labListError && labList.length === 0 && (
//                   <NoDataInTable />
//                 )}
//                 {!isLoading && !labListError && labList.length > 0 && (
//                   <table className="table table-bordered align-middle">
//                     <thead>
//                       <tr>
//                         <th
//                           colSpan={1}
//                           scope="col"
//                           className="bg-secondary-color ps-3"
//                         >
//                           #No
//                         </th>
//                         <th
//                           colSpan={3}
//                           scope="col"
//                           className="bg-secondary-color ps-3"
//                         >
//                           Influencer Name
//                         </th>
//                         <th
//                           colSpan={2}
//                           scope="col"
//                           className="bg-secondary-color ps-3"
//                         >
//                           Influencer Role
//                         </th>
//                         <th
//                           colSpan={1}
//                           scope="col"
//                           className="bg-secondary-color ps-3"
//                         >
//                           Status
//                         </th>
//                         {/* <th
//                           colSpan={2}
//                           scope="col"
//                           className="bg-secondary-color ps-3"
//                         >
//                           Actions
//                         </th>
//                         <th
//                           colSpan={2}
//                           scope="col"
//                           className="bg-secondary-color ps-3"
//                         >
//                           Verified
//                         </th> */}
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {labList.map((lab, index) => (
//                         <tr key={index}>
//                           <td
//                             colSpan={1}
//                             className="ps-3 fs-14 fw-light text-text"
//                           >
//                             {getItemNumber(pageSize, currentPage, index)}
//                           </td>
//                           <td
//                             colSpan={3}
//                             className="ps-3 fs-14 fw-light text-text deco"
//                           >
//                             <Link
//                               to={`${adminPrefixedRoutes.USERS_LAB_DETAILS}/${lab._id}/${lab.labName}`}
//                               className="link-style"
//                             >
//                               <MyImage
//                                 src={lab.labImageUrl}
//                                 addBaseUrl={true}
//                                 alt=""
//                                 className="w-h-24px rounded-circle me-2 "
//                               />
//                               <span className="fs-14 fw-light  ">
//                                 {lab.labName}
//                               </span>
//                             </Link>
//                           </td>
//                           <td
//                             colSpan={2}
//                             className="ps-3 fs-14 fw-light text-text"
//                           >
//                             {lab.labPhoneNumber ?? "Phone number unavailable"}
//                           </td>
//                           <td
//                             colSpan={1}
//                             className="ps-3 fs-14 fw-light text-text"
//                           >
//                             {Str.snakeToWord(lab.status ?? "unknown")}
//                           </td>
//                           <td
//                             colSpan={2}
//                             className="ps-3 fs-14 fw-light text-text"
//                           >
//                             {/* {lab.status == "pending" && (
//                               <button
//                                 onClick={() => handleVerifyLab(lab._id)}
//                                 disabled={labStates[lab._id]?.verifyLabLoading}
//                                 type="button"
//                                 className="btn btn-outline-info btn-sm"
//                               >
//                                 {labStates[lab._id]?.verifyLabLoading
//                                   ? "Verifying..."
//                                   : "Verify Lab"}
//                               </button>
//                             )} */}
//                             {lab.status !== "pending" && (
//                               <div className="form-switch">
//                                 <input
//                                   className="form-check-input cursor-pointer"
//                                   type="checkbox"
//                                   role="switch"
//                                   checked={lab.status === "active"}
//                                   onChange={() =>
//                                     handleActiveDeactiveLab(lab._id)
//                                   }
//                                   disabled={
//                                     labStates[lab._id]?.activeDeactiveLabLoading
//                                   } // Disable switch when loading
//                                 />
//                               </div>
//                             )}
//                           </td>
//                           {/* <td
//                             colSpan={2}
//                             className="ps-3 fs-14 fw-light text-text"
//                           >
//                             {
//                               <div className="form-switch">
//                                 <input
//                                   className="form-check-input cursor-pointer"
//                                   type="checkbox"
//                                   role="switch"
//                                   checked={lab.creatorId.isVerified === true}
//                                   onChange={() => handleVerifyLab(lab._id)}
//                                   disabled={
//                                     labStates[lab._id]?.verifyLabLoading
//                                   } // Disable switch when loading
//                                 />
//                               </div>
//                             }
//                           </td> */}
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 )}
//               </div>
//             </div>

//             <span className="d-flex justify-content-end">
//               {(isLoading || labListError) && <PaginationShimmer />}
//               {!isLoading &&
//                 !labListError &&
//                 labListMeta &&
//                 labList.length > 0 && (
//                   <ReactPaginate
//                     breakLabel=""
//                     nextLabel="Next"
//                     onPageChange={handlePageClick}
//                     forcePage={currentPage - 1}
//                     pageRangeDisplayed={3}
//                     pageCount={Math.ceil(
//                       labListMeta?.pagination?.total_page ?? 1
//                     )}
//                     previousLabel="Previous"
//                     marginPagesDisplayed={1}
//                     renderOnZeroPageCount={null}
//                     containerClassName="pagination"
//                     previousLinkClassName="custom-previous-link"
//                     nextLinkClassName="custom-next-link"
//                     pageLinkClassName="custom-page-link"
//                     activeLinkClassName="active-page-link"
//                     disabledLinkClassName="disabled-page-link"
//                   />
//                 )}
//             </span>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };
// export default AdminLab;
