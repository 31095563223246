import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import BackButton from "../../../../helpers/backButton";
import useCreateSocialProfile from "../../../../hooks/data-hooks/influencer/social-profile/useCreateSocialProfile";
import { platformList, ageList, rankList } from "../../../../utils/Socials";

const CreateSocialProfile = () => {
  const {
    updateContent,
    isLoading,
    isError,
    error,
    submitSuccess,
    isSubmitting,
  } = useCreateSocialProfile();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    if (isError) {
      toast.error(error.message);
    }
  }, [isError, error]);

  useEffect(() => {
    if (submitSuccess) {
      toast.success("Form Submit Successful");
    }
  }, [submitSuccess]);

  const onSubmit = async (data) => {
    // if (totalPercentage !== 100) {
    //   toast.error(
    //     "The total percentage of majority followers countries must be 100."
    //   );
    //   return;
    // }
    // const formattedData = {
    //   ...data,
    //   followersInterests,
    //   audienceType,
    //   majorityFollowersCountries,
    // };
    console.log(data);

    if (isSubmitting) return;
    try {
      await updateContent(data);
    } catch (error) {
      // Error handling is done in useEffect
    }
  };

  return (
    <div className="container">
      <div className="col-lg-6">
        <div className="cms-card">
          <BackButton />
          <form className="cms-form mb-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="fs-24 fw-bold">Create New Social Profile</div>

            <div className="mt-5">
              {/* social Platform */}
              <div className="form-item">
                <label className="">Choose Social Platform</label>
                <div className="input-wrapper">
                  <select
                    className="custom-select"
                    {...register("socialPlatformName", {
                      required: "Platform Name is required",
                    })}
                  >
                    <option value="">Select Platform</option>
                    {platformList.map((platform, index) => (
                      <option key={index} value={platform}>
                        {platform}
                      </option>
                    ))}
                  </select>
                </div>
                {errors.socialPlatformName && (
                  <p className="text-danger">
                    {errors.socialPlatformName.message}
                  </p>
                )}
              </div>

              {/* Social Platform Link */}
              <div className="form-item">
                <label className="">Social Link</label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    className=""
                    placeholder="Enter social link e.g. https://www.facebook.com/user1"
                    {...register("socialLink", {
                      required: "Social Link is required",
                      pattern: {
                        value:
                          /^(https?:\/\/)?(www\.)?[a-zA-Z0-9._%+-]+\.[a-z]{2,}(\/[A-Za-z0-9._%+-]*)*$/i,
                        message: "Invalid URL format",
                      },
                    })}
                  />
                  {errors.socialLink && (
                    <span className="error">{errors.socialLink.message}</span>
                  )}
                </div>
              </div>

              {/* followers */}
              <div className="form-item">
                <label className="">Followers</label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    className=""
                    placeholder="Enter followers e.g. 126500"
                    {...register("followers", {
                      required: "followers is required",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Must be a valid number",
                      },
                    })}
                  />
                  {errors.followers && (
                    <span className="error">{errors.followers.message}</span>
                  )}
                </div>
              </div>

              {/* engagementRate */}
              <div className="form-item">
                <label className="">Engagement Rate</label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    className=""
                    placeholder="Enter engagement Rate e.g. 43%"
                    {...register("engagementRate", {
                      required: "engagement Rate is required",
                      pattern: {
                        value: /^[0-9]+%$/,
                        message: "Must be a percentage (e.g., 43%)",
                      },
                    })}
                  />
                  {errors.engagementRate && (
                    <span className="error">
                      {errors.engagementRate.message}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <button type="submit" className="w-100 button-primary h-64px">
              {isSubmitting ? "Creating..." : "Create Profile"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateSocialProfile;

// constants and states...........................................

// const [followersInterests, setFollowersInterests] = useState([
//   { type: "", percentage: "" },
// ]);
// const [audienceType, setAudienceType] = useState([
//   { type: "", percentage: "" },
// ]);
// const [majorityFollowersCountries, setMajorityFollowersCountries] = useState([
//   { type: "", percentage: "" },
// ]);

// const [totalPercentage, setTotalPercentage] = useState(0);
// const [percentageError, setPercentageError] = useState("");

// functions ............................................

// // Calculate the total percentage of majorityFollowersCountries
// const calculateTotalPercentage = (countries) => {
//   const total = countries.reduce(
//     (sum, country) => sum + parseFloat(country.percentage || 0),
//     0
//   );
//   setTotalPercentage(total);
//   if (total !== 100) {
//     setPercentageError("The total percentage must equal 100.");
//   } else {
//     setPercentageError("");
//   }
// };

// // Dynamic field handlers for audienceType and majorityFollowersCountries
// const handleAddField = (setFunction, fieldArray) => {
//   setFunction([...fieldArray, { type: "", percentage: "" }]);
// };

// const handleRemoveField = (index, setFunction, fieldArray) => {
//   const newArray = fieldArray.filter((_, i) => i !== index);
//   setFunction(newArray);
// };

// const handleFieldChange = (index, field, value, setFunction, fieldArray) => {
//   const newArray = fieldArray.map((item, i) =>
//     i === index ? { ...item, [field]: value } : item
//   );
//   setFunction(newArray);
// };

// form fields.........................................

// {
//   /* averageViewsPerPost */
// }
// <div className="form-item">
//   <label className="">Average Views Per Post</label>
//   <div className="input-wrapper">
//     <input
//       type="text"
//       className=""
//       placeholder="Enter engagement Rate e.g. 43354"
//       {...register("averageViewsPerPost", {
//         required: "Average Views Per Post is required",
//         pattern: {
//           value: /^[0-9]+$/,
//           message: "Must be a valid number",
//         },
//       })}
//     />
//     {errors.averageViewsPerPost && (
//       <span className="error">{errors.averageViewsPerPost.message}</span>
//     )}
//   </div>
// </div>;

// {
//   /* ratioOfCommentsToLikes */
// }
// <div className="form-item">
//   <label className="">Ratio Of Comments To Likes</label>
//   <div className="input-wrapper">
//     <input
//       type="text"
//       className=""
//       placeholder="Enter ratio Of Comments To Likes e.g. 20.55"
//       {...register("ratioOfCommentsToLikes", {
//         required: "Ratio Of Comments To Likes is required",
//         pattern: {
//           value: /^[0-9]+(\.[0-9]+)?$/,
//           message: "Must be a valid number (e.g., 20.55)",
//         },
//       })}
//     />
//     {errors.ratioOfCommentsToLikes && (
//       <span className="error">{errors.ratioOfCommentsToLikes.message}</span>
//     )}
//   </div>
// </div>;

// {
//   /* majorityFollowersAge */
// }
// <div className="form-item">
//   <label className="">Majority Followers Age</label>
//   <div className="input-wrapper">
//     <select
//       className="custom-select"
//       {...register("majorityFollowersAge", {
//         required: "Majority Followers Age is required",
//       })}
//     >
//       <option value="">Select Age Range</option>
//       {ageList.map((age, index) => (
//         <option key={index} value={age}>
//           {age}
//         </option>
//       ))}
//     </select>

//     {errors.majorityFollowersAge && (
//       <span className="error">{errors.majorityFollowersAge.message}</span>
//     )}
//   </div>
// </div>;

// {
//   /* majorityFollowersGender */
// }
// <div className="form-item">
//   <label className="">Majority Followers Gender</label>
//   <div className="input-wrapper">
//     <select
//       className="custom-select" // Add a class for custom styling
//       {...register("majorityFollowersGender", {
//         required: "Majority Followers Gender is required",
//       })}
//     >
//       <option value="">Select Gender</option>
//       <option value="males">Males</option>
//       <option value="females">Females</option>
//     </select>
//     {errors.majorityFollowersGender && (
//       <span className="error">{errors.majorityFollowersGender.message}</span>
//     )}
//   </div>
// </div>;

// {
//   /* rank */
// }
// <div className="form-item">
//   <label className="">Rank</label>
//   <div className="input-wrapper">
//     <select
//       className="custom-select"
//       {...register("rank", {
//         required: "Rank is required",
//       })}
//     >
//       <option value="">Select Rank Option</option>
//       {rankList.map((rank, index) => (
//         <option key={index} value={rank}>
//           {rank}
//         </option>
//       ))}
//     </select>

//     {errors.rank && <span className="error">{errors.rank.message}</span>}
//   </div>
// </div>;

// {
//   /* Dynamic Followers Interests */
// }
// <div className="form-item mt-5">
//   <label>Followers Interests</label>
//   {followersInterests.map((interest, index) => (
//     <div key={index} className="mb-1 d-flex flex-column align-items-end">
//       <button
//         type="button"
//         className="btn"
//         onClick={() =>
//           handleRemoveField(index, setFollowersInterests, followersInterests)
//         }
//       >
//         X
//       </button>
//       <input
//         type="text"
//         placeholder="Interest Type"
//         value={interest.type}
//         onChange={(e) =>
//           handleFieldChange(
//             index,
//             "type",
//             e.target.value,
//             setFollowersInterests,
//             followersInterests
//           )
//         }
//         className="mb-2"
//       />
//       <input
//         type="number"
//         placeholder="Percentage"
//         value={interest.percentage}
//         onChange={(e) =>
//           handleFieldChange(
//             index,
//             "percentage",
//             e.target.value,
//             setFollowersInterests,
//             followersInterests
//           )
//         }
//         min="0"
//         max="100"
//       />
//       <hr className="w-100" />
//     </div>
//   ))}
//   <button
//     type="button"
//     className="btn bg-secondary color-white mb-4"
//     onClick={() => handleAddField(setFollowersInterests, followersInterests)}
//   >
//     Add More Interest
//   </button>
// </div>;

// {
//   /* Dynamic Audience Type */
// }
// <div className="form-item mt-5">
//   <label>Audience Type</label>
//   {audienceType.map((audience, index) => (
//     <div key={index} className="mb-1 d-flex flex-column align-items-end">
//       <button
//         type="button"
//         className="btn"
//         onClick={() => handleRemoveField(index, setAudienceType, audienceType)}
//       >
//         X
//       </button>
//       <input
//         type="text"
//         placeholder="Audience Type"
//         value={audience.type}
//         onChange={(e) =>
//           handleFieldChange(
//             index,
//             "type",
//             e.target.value,
//             setAudienceType,
//             audienceType
//           )
//         }
//         className="mb-2"
//       />
//       <input
//         type="number"
//         placeholder="Percentage"
//         value={audience.percentage}
//         onChange={(e) =>
//           handleFieldChange(
//             index,
//             "percentage",
//             e.target.value,
//             setAudienceType,
//             audienceType
//           )
//         }
//         min="0"
//         max="100"
//       />
//       <hr className="w-100" />
//     </div>
//   ))}
//   <button
//     type="button"
//     className="btn bg-secondary color-white mb-4"
//     onClick={() => handleAddField(setAudienceType, audienceType)}
//   >
//     Add More Audience Type
//   </button>
// </div>;

// {
//   /* Dynamic Majority Followers Countries */
// }
// <div className="form-item mt-5">
//   <label>
//     Majority Followers Countries{" "}
//     <p className="text-danger fs-12 mb-0">
//       Total Percentage: must equal to 100
//     </p>{" "}
//   </label>
//   {majorityFollowersCountries.map((country, index) => (
//     <div key={index} className="mb-1 d-flex flex-column align-items-end">
//       <button
//         type="button"
//         className="btn"
//         onClick={() =>
//           handleRemoveField(
//             index,
//             setMajorityFollowersCountries,
//             majorityFollowersCountries,
//             "majorityFollowersCountries"
//           )
//         }
//       >
//         X
//       </button>
//       <input
//         type="text"
//         placeholder="Country"
//         value={country.type}
//         onChange={(e) =>
//           handleFieldChange(
//             index,
//             "type",
//             e.target.value,
//             setMajorityFollowersCountries,
//             majorityFollowersCountries,
//             "majorityFollowersCountries"
//           )
//         }
//         className="mb-2"
//       />
//       <input
//         type="number"
//         placeholder="Percentage"
//         value={country.percentage}
//         onChange={(e) =>
//           handleFieldChange(
//             index,
//             "percentage",
//             e.target.value,
//             setMajorityFollowersCountries,
//             majorityFollowersCountries,
//             "majorityFollowersCountries"
//           )
//         }
//         min="0"
//         max="100"
//         className={percentageError ? "error-input" : ""}
//       />
//       <hr className="w-100" />
//     </div>
//   ))}
//   {percentageError && <div className="error-text mb-3">{percentageError}</div>}
//   <div>Total Percentage: {totalPercentage}%</div>
//   <button
//     type="button"
//     className="btn bg-secondary color-white mb-4"
//     onClick={() =>
//       handleAddField(
//         setMajorityFollowersCountries,
//         majorityFollowersCountries,
//         "majorityFollowersCountries"
//       )
//     }
//     disabled={totalPercentage >= 100} // Disable adding more fields if total reaches 100
//   >
//     Add More Country
//   </button>
// </div>;
