import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";
import influencerPrefixedRoutes from "../../../../routes/all-routes/influencerRoutes";

const useUpdatePackage = (id) => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false); // New state for submit success
  const [isSubmitting, setIsSubmitting] = useState(false);
  const token = useSelector((state) => {
    return state.auth.user.accessToken;
  });

  const {
    mutate: updateContent,
    isLoading,
    isError,
  } = useMutation({
    mutationKey: "updatepackage",
    mutationFn: async (formData) => {
      setIsSubmitting(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/influencer/social-profile/${id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(formData),
          }
        );

        const data = await response.json();

        if (!response.ok) {
          const text = await response.json();
          if (text?.error?.status === 401) {
            navigate(authPrefixedRoutes.LOGIN);
          }
          throw new Error(data.error.message);
        }
        setSubmitSuccess(true);
        setIsSubmitting(false);
        setTimeout(() => {
          navigate(influencerPrefixedRoutes.SOCIAL_PROFILES);
        }, 1000);
        return data;
      } catch (error) {
        // Set login error state
        setError(error);
        throw error; // Re-throw the error to be caught by onError
      }
    },
    onError: (error) => {
      setError(error);
      throw error; // Re-throw the error to be caught by onError
    },
  });

  return {
    updateContent,
    isLoading,
    isError,
    error,
    submitSuccess,
    isSubmitting,
  }; // Return submitSuccess
};

export default useUpdatePackage;
