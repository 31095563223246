// Dashboard.js
import React from "react";
import DashboardPieChart from "../../../admin/components/dashboardPieChart/DashboardPieChart";
import { Helmet } from "react-helmet";
import { advertiserPageNames } from "../../../../routes/all-routes/advertiserRoutes";
import Stats from "../dashboard/stats/Stats";
import UserInfo from "../dashboard/userInfo/UserInfo";
import BestLabSeller from "../dashboard/bestLabSeller/BestLabSeller";

const Dashboard = () => {
  return (
    <>
      <Helmet>
        <title>
          {advertiserPageNames.DASHBOARD} | Admin | {process.env.REACT_APP_NAME}
        </title>
      </Helmet>

      <div className="container">
        <div className="row gy-4 align-items-stretch">
          <div className="col-12 col-lg-6 col-xxl-4">
            <Stats />
          </div>
          <div className="col-12 col-lg-6 col-xxl-4">
            <DashboardPieChart />
          </div>
          <div className="col-12 col-xxl-4">
            <BestLabSeller />
          </div>

          <div className="col-12">
            <UserInfo />
          </div>
        </div>
      </div>
      {/* <div className="container   ">
        <div className="row gy-4 align-items-stretch ">
          <div className="col-lg-6 col-xxl-4 ">
            <Stats />
          </div>
          <div className="col-lg-6 col-xxl-4">
            <DashboardPieChart />
          </div>
          <div className="col-lg-6 col-xxl-4">
            <TopSellingProduct />
          </div>

          <div className=" col-xxl-8 ">
          <DashboardAreaChart />
        </div>
          <div className=" col-xxl-8 ">
            <DashboardBarChart />
          </div>

          <div className="col-lg-6 col-xxl-4">
            <BestLabSeller />
          </div>

          <div className="col-xxl-8 ">
            <UserInfo />
          </div>
          <div className="col-lg-6 col-xxl-4">
            <TopClinic />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Dashboard;
