import React from "react";
import { useParams } from "react-router-dom";
import useWebContent from "../../../../hooks/data-hooks/website/useWebContent";
import { UserIcon1, MailIcon1, BagIcon1 } from "../../../../utils/WebIcons";
import Testimonial from "../testimonial/Testimonial";
import ServiceContainer from "../service/ServiceContainer";

import useFecthDataById from "../../../../hooks/data-hooks/common/useFecthDataById";
import Loader from "../../../common/components/loader/Loader";
import { SocialIcons, SocialPlatformColors } from "../../../../utils/Socials";

const InfluencerDetails = () => {
  const { isLoading: isWebContentLoading, data: webContentData } =
    useWebContent();

  const { id } = useParams(); // Get the dynamic id from the URL
  const { data: mainData, isLoading: isFetchingData } = useFecthDataById(
    "/api/public/influencer-search",
    "influencerKeyIdWeb",
    id
  );

  console.log(mainData);

  // Function to render the progress bar with dynamic color
  const renderProgressBar = (platform, engagementRate, colorClass) => {
    return (
      <div key={platform}>
        <p className="mb-0 fw-semibold">{platform}</p>
        <div className="d-flex align-items-center">
          <div className="progress flex-grow-1" style={{ height: "6px" }}>
            <div
              className={`progress-bar ${colorClass}`}
              role="progressbar"
              style={{ width: `${engagementRate}` }}
              aria-valuenow={engagementRate}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <span className="ms-2 fw-bold">{engagementRate}</span>
        </div>
      </div>
    );
  };

  const formatNumToK = (num) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // Convert to millions
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + "K"; // Convert to thousands
    } else {
      return num.toString(); // Return as is if less than 1000
    }
  };

  const ensureCorrectUrl = (url) => {
    if (url?.startsWith("https//:")) {
      return url.replace("https//:", "https://");
    }
    return url;
  };

  if (isWebContentLoading || isFetchingData) return <Loader />;

  return (
    <div className="container">
      <section className="p-3 rounded-3 border border-2 shadow mt-4">
        <div className="row my-4 row-gap-3 align-items-stretch">
          {/* First Column - Image */}
          <div className="col-12 col-sm-6 col-lg-3 d-flex align-items-center">
            <div className="image-container1">
              <img
                src={mainData?.data?.profileImageUrl}
                alt={mainData?.data?.fullName}
                className="influencer-img p-1"
              />
            </div>
          </div>

          {/* Second Column - Info */}
          <div className="col-12 col-sm-6 col-lg-3 d-flex flex-column justify-content-center gap-3">
            <div className="d-flex align-items-center">
              <UserIcon1 />
              <span className="fw-bold fs-5 ps-2">
                {mainData?.data?.fullName || "N/A"}
              </span>
            </div>
            <div>
              <BagIcon1 />
              <span className="ps-2 fw-medium">
                {mainData?.data?.influencer?.categories[0]?.categoryName ||
                  "N/A"}
              </span>
            </div>

            <div>
              <MailIcon1 />
              <span className="ps-2 fw-medium">
                {mainData?.data?.email || "N/A"}{" "}
              </span>
            </div>

            {/* Followers */}
            <div className="my-3">
              <span className="bg-primary-color text-white px-3 py-2 rounded-2 fs-12 ">
                Followers
              </span>
            </div>

            {/* Social Followers with Icons */}
            <div className="d-flex align-items-center gap-2 flex-wrap">
              {mainData?.data?.influencer?.socialProfiles?.map(
                (profile, index) => {
                  const IconComponent =
                    SocialIcons[profile?.socialPlatformName.toLowerCase()];
                  const formattedFollowers = formatNumToK(profile?.followers);

                  return (
                    <div
                      key={index}
                      className="d-flex align-items-center gap-2"
                    >
                      {IconComponent}
                      <span className="fw-semibold">{formattedFollowers}</span>
                    </div>
                  );
                }
              )}
            </div>
          </div>

          {/* Third Column - Social Media Links and Average Likes */}
          <div className="col-12 col-sm-6 col-lg-3 d-flex flex-column">
            <div className="flex-grow-1 border border-2 rounded p-3 d-flex flex-column justify-content-center gap-2">
              {/* Social Media Links */}
              <p className="mb-0 fw-semibold">Social Media Links</p>
              {/* Social Links with Icons */}
              <div className="d-flex  gap-3 flex-wrap">
                {mainData?.data?.influencer?.socialProfiles?.map(
                  (profile, index) => {
                    const IconComponent =
                      SocialIcons[profile?.socialPlatformName.toLowerCase()];
                    const correctedUrl = ensureCorrectUrl(profile?.socialLink); // Ensure the URL is correct
                    return (
                      <a
                        key={index}
                        className="d-flex align-items-center gap-2 cursor-pointer"
                        // href={profile?.socialLink}
                        href={correctedUrl}
                        rel="noopener noreferrer"
                      >
                        {IconComponent}
                      </a>
                    );
                  }
                )}
              </div>
            </div>

            {/* Average Likes */}
            <div className="flex-grow-1 border border-2 rounded p-3 d-flex flex-column justify-content-center gap-2 mt-4">
              <p className="mb-0 fw-semibold">Total Followers</p>
              <span className="fw-bold fs-4">
                {mainData?.data?.influencer?.totalFollowers}
              </span>
            </div>
          </div>

          {/* Fourth Column - Engagement Rate with Progress Bars */}
          <div className="col-12 col-sm-6 col-lg-3 ">
            <div className="border border-2 rounded p-3 h-100 d-flex flex-column jus">
              <p className="mb-1 fw-semibold">Engagement Rate:</p>
              {mainData?.data?.influencer?.socialProfiles?.map((item, index) =>
                item
                  ? renderProgressBar(
                      item?.socialPlatformName,
                      item?.engagementRate,
                      SocialPlatformColors[
                        item?.socialPlatformName.toLowerCase()
                      ] // Get color dynamically
                    )
                  : null
              )}
            </div>
          </div>
        </div>
      </section>
      <ServiceContainer title="Packages" data={mainData?.packages} />
      <Testimonial data={webContentData?.data?.testimonial} />
    </div>
  );
};

export default InfluencerDetails;
