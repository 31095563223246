import React from "react";


const Partner = ({ data }) => {
  return (
    <div className="partner-container max-width mx-auto  px-4 d-flex justify-content-center align-items-center flex-column bg-primary-color rounded-3">
      <div className="partner-title fw-semibold mb-4">
        {/* Trusted by greatest companies */}
        {data?.title || "Trusted by greatest companies"}
      </div>

      <div className="partner-logo-container d-flex align-items-center justify-content-around flex-wrap">
        {data?.brand_items?.map((item) => (
          <img
            key={JSON.stringify(item)}
            src={item?.image}
            alt={"Brand logo"}
            className="img-fluid mx-4 px-3 my-3"
          />
        ))}

      </div>
    </div>
  );
};

export default Partner;

/* -------------------------
---------Backup Code----------
----------------------------------- */
// export default Partner;
// import React from "react";
// import logo from "../../../../assets/svg/Logo.svg";
// import logo1 from "../../../../assets/svg/Logo-1.svg";
// import logo2 from "../../../../assets/svg/Logo-2.svg";
// import logo3 from "../../../../assets/svg/Logo-3.svg";
// import logo4 from "../../../../assets/svg/Logo-4.svg";

// const Partner = ({ data }) => {
//   return (
//     <div className="partner-container max-width mx-auto  px-4 d-flex justify-content-center align-items-center flex-column bg-primary-color rounded-3">
//       <div className="partner-title fw-semibold mb-4">
//         {/* Trusted by greatest companies */}
//         {data?.title || "Trusted by greatest companies"}
//       </div>

//       <div className="partner-logo-container d-flex align-items-center justify-content-around flex-wrap">
//         {data?.brand_items?.map((item) => (
//           <img
//             key={JSON.stringify(item)}
//             src={item?.image}
//             alt={"Brand Image"}
//             className="img-fluid mx-4 px-3 my-3"
//           />
//         ))}

//       </div>
//     </div>
//   );
// };

// export default Partner;
