import React, { useState } from "react";
import { FaRegCircle, FaCheckCircle } from "react-icons/fa";
import { BsFillInboxesFill } from "react-icons/bs";
import { IoMdTime } from "react-icons/io";
import { MdOutlinePayments } from "react-icons/md";

import Modal from "./Modal";
import useOrderStatusChange from "../../../hooks/data-hooks/common/useOrderStatusChange";

const OrderProgressStepper = ({
  orderId,
  orderStatus,
  paymentStatus,
  refetchData,
  role
}) => {
  const {
    orderStatusChangeToCancel,
    orderStatusChangeToProcessing,
    orderStatusChangeToComplete,
    isLoading
  } = useOrderStatusChange(orderId, refetchData);

  const [modal, setModal] = useState({
    show: false,
    content: "",
    onConfirm: null,
  });

  const stepIcons = {
    pending: <IoMdTime />,
    payment: <MdOutlinePayments />,
    confirmed: <BsFillInboxesFill />,
    completed: <FaCheckCircle />,
  };

  const steps = ["pending", "payment", "confirmed", "completed"];

  const determineCurrentStep = () => {
    if (orderStatus === "completed" && paymentStatus === "completed") {
      return 3; // completed
    } else if (orderStatus === "confirmed" && paymentStatus === "completed") {
      return 2; // confirmed
    } else if (orderStatus === "pending" && paymentStatus === "completed") {
      return 1; // payment
    } else {
      return 0; // pending
    }
  };

  const currentStepIndex = determineCurrentStep();

  const handleIconClick = (index) => {
    if (currentStepIndex === 1 && index === 2 && role === "influencer") {
      setModal({
        show: true,
        content: "Confirm Order?",
        onConfirm: () => {
          orderStatusChangeToProcessing(orderId);
          setModal({ show: false });
        },
      });
    } else if (currentStepIndex === 2 && index === 3 && role === "advertiser") {
      setModal({
        show: true,
        content: "Complete Order?",
        onConfirm: () => {
          orderStatusChangeToComplete(orderId);
          setModal({ show: false });
        },
      });
    }
  };

  return (
    <>
      {currentStepIndex === 1 && role === "influencer" && (
        <button
          className="btn bg-primary-gradient text-white w-100px mx-auto px-4"
          onClick={() => handleIconClick(2)}
          disabled={isLoading}
        >
          {isLoading ? "Confirming..." : "Confirm Order"}
        </button>
      )}

      {currentStepIndex === 2 && role === "advertiser" && (
        <button
          className="btn bg-primary-gradient text-white w-100px mx-auto px-4"
          onClick={() => handleIconClick(3)}
          disabled={isLoading}
        >
          {isLoading ? "Completing..." : "Complete Order"}
        </button>
      )}

      <div className="stepper-container1">
        {steps.map((step, index) => (
          <div
            key={step}
            className={`  step1 ${index <= currentStepIndex ? "active" : ""}`}
          >
            <span
              className="step-icon1 cursor-pointer"
              onClick={() => handleIconClick(index)}
            >
              {index <= currentStepIndex ? stepIcons[step] : <FaRegCircle />}
            </span>
            <span className="step-text1">{step}</span>
          </div>
        ))}
      </div>
      {modal.show && (
        <Modal
          content={modal.content}
          onClose={() => setModal({ show: false })}
          onConfirm={() => {
            modal.onConfirm();
          }}
        />
      )}
    </>
  );
};

export default OrderProgressStepper;
