import { useLocation } from 'react-router-dom';
import { influencerPageNames, influencerPrefixedRoutes } from '../../routes/all-routes/influencerRoutes';

const useInfluencerPageName = (panelName = "influencer") => {
  const location = useLocation();
  let currentPageName = "influencer Dashboard"; // Default page name

  //console.log("Current location:", location.pathname);

  try {
    const matchedRoute = Object.entries(influencerPrefixedRoutes).find(([key, value]) => {
      const regex = new RegExp(`^${value}(\/|$)`);
      //console.log("Checking route:", key, value, regex.test(location.pathname));
      return regex.test(location.pathname);
    });

    //console.log("Matched route:", matchedRoute);

    if (matchedRoute) {
      const currentPageKey = matchedRoute[0];
      currentPageName = influencerPageNames[currentPageKey];
      //console.log("Current page name:", currentPageName);
    }
  } catch (error) {
    //console.error("An error occurred while determining the current page name:", error);
  }

  return currentPageName;
};

export default useInfluencerPageName;
