export function getFormattedDate(date) {
  const parsedDate = new Date(date);

  // Check if the date is valid
  if (isNaN(parsedDate.getTime())) {
    return "Invalid Date"; // or handle it however you prefer
  }

  const year = parsedDate.getFullYear();
  const month = parsedDate.toLocaleString("default", { month: "short" }); // Get short month name (e.g., Nov)
  const day = parsedDate.getDate().toString().padStart(2, "0"); // Ensure day is two digits

  // Extract hours, minutes and set AM/PM
  let hours = parsedDate.getHours();
  const minutes = parsedDate.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12 || 12; // Convert to 12-hour format and handle 0 as 12

  const formattedTime = `${hours}:${minutes}${ampm}`;

  return `${year} ${month} ${day}, ${formattedTime}`;
}
