import InfluencerSideBar from "./InfluencerSideBar";
import InfluencerTopBar from "./InfluencerTopBar";
import "../../../../styles/innerPanel.css";

const InfluencerLayout = ({ children }) => {
  return (
    <div className="inner-panel d-flex vh-100 overflow-hidden bg-Influencer-gradient">
      <InfluencerSideBar />
      <div className="position-relative d-flex flex-1 flex-column overflow-y-auto overflow-x-hidden w-100 custom-scroll">
        <InfluencerTopBar />
        <main>
          <div className="mx-auto pt-4 pb-4 ">{children}</div>
        </main>
      </div>
    </div>
  );
};

export default InfluencerLayout;
