import React from "react";
import { BiSolidCategoryAlt } from "react-icons/bi";
import { GiDuration } from "react-icons/gi";

import {
  SocialIcons,
  SocialPlatformColors,
  PackageTypeImage,
} from "../../../../utils/Socials";
import { Link } from "react-router-dom";
import adminPrefixedRoutes from "../../../../routes/all-routes/adminRoutes";

const PackageCard = ({ data, role }) => {
  const backgroundClass =
    SocialPlatformColors[data?.socialPlatformName?.toLowerCase()] ||
    "bg-default";
  const image = PackageTypeImage[data?.typeOfPackage]?.toLowerCase() || "";
  const icon = SocialIcons[data?.socialPlatformName?.toLowerCase()] || "";

  console.log(data);

  return (
    <div
      className={`package-card border rounded shadow-sm p-4 d-flex flex-column justify-content-between h-100  ${backgroundClass} `}
    >
      <div className="d-flex flex-column justify-content-between flex-grow-1 bg-white p-5 rounded-3">
        <div>
          {/* Image */}
          <div className="package-image-container mx-auto">
            <img
              src={image}
              alt={data?.typeOfPackage}
              className="img-fluid object-fit-cover rounded-2"
            />
          </div>
          <div className="row mt-3 row-gap-4 align-items-stretch">
            <div className="col-lg-6 bg-secondary-color py-2">
              {/* Title */}
              <h3 className="text-title fw-bold ">{data?.packageName}</h3>
              {/* Subtitle */}
              <p className="flex-grow-1 fs-5">{data?.description}</p>
              <p className="fs-6">
                <span className="text-title">Plaform</span> : {icon}{" "}
                {data?.socialPlatformName}
              </p>
              {/* Info */}
              <div className="d-flex  align-items-center gap-2 fs-5 fw-bold text-primary-color">
                <span className="">
                  <BiSolidCategoryAlt /> {data?.typeOfPackage}
                </span>
                <span className="">
                  <GiDuration /> {data?.duration} Days
                </span>
              </div>
            </div>
            <div className="col-lg-6 fs-6 bg-secondary-color py-2">
              <p className="text-title">Package Information</p>

              <p>
                <span className="text-title">Influencer Id</span> : {""}
                <Link
                  className="link-style"
                  to={`${adminPrefixedRoutes.INFLUENCER_DETAILS}/${data?.userId}`}
                >
                  <span className="text-wrap">{data?.userId}</span>
                </Link>
              </p>

              <p>
                <span className="text-title">Package Id</span> : {""}
                <span className="text-wrap">{data?._id}</span>
              </p>
              <p>
                <span className="text-title">Status</span> : {data?.status}
              </p>
              <p>
                <span className="text-title">Enable</span> :{" "}
                {data?.isEnable ? "Yes" : "No"}
              </p>
            </div>
          </div>
        </div>
        {/* Price and Book Now */}
        <div className="d-flex justify-content-between align-items-center mt-4">
          <span className="fw-bold fs-6 ">Price : ${data?.price}</span>
          {role !== "admin" ? (
            <button
              className="btn bg-primary-color color-white-cream"
              onClick={data?._id}
            >
              Book Now
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PackageCard;
