import { useLocation, Link } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import useLogout from "../../../../hooks/other-hooks/useLogout";

import DashboardIcon, {
  AnalyticsIcon,
  ArrowIcon,
  DotIcon,
  LogoutIcon,
  ReportsIcon,
  SettingsIcon,
  TransactionIcon,
  UserIcon,
  ProductsIcon,
  OrdersIcon,
  MessageWhiteIcon,
  OrdersRoundedIcon,
  CloseIcon,
  HamburgerIcon,
  PaymentIcon,
  BookingIcon,
  UserManagementIcon,
  SettingsNewIcon,
} from "../../../../utils/MyIcons";
import { useEffect, useState } from "react";
import AdminSidenavProfile from "./layout-partials/admin-sidenav/AdminSidenavProfile";
import adminPrefixedRoutes, {
  adminRoutes,
} from "../../../../routes/all-routes/adminRoutes";

const AdminSideBar = () => {
  const handleLogout = useLogout();
  const location = useLocation();
  const [open, setOpen] = useState("");
  const [sidebarVisible, setSidebarVisible] = useState(true); // State to manage sidebar visibility

  // Function to toggle sidebar visibility
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  // Function to detect screen size changes and show/hide sidebar accordingly
  useEffect(() => {
    const handleResize = () => {
      const isSmallScreen = window.innerWidth < 768; // Adjust breakpoint as needed
      if (isSmallScreen) {
        setSidebarVisible(false); // Hide sidebar on small screens
      } else {
        setSidebarVisible(true); // Show sidebar on large screens
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call the function initially
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleOpenSubMenu = (key) => {
    if (open === key) {
      setOpen("");
    } else {
      setOpen(key);
    }
  };

  return (
    <div className="text-white  d-flex  ">
      <Sidebar
        backgroundColor="rgba(0,0,0,0)"
        rootStyles={{
          background: "var(--primary-dashboard)",
          display: sidebarVisible ? "block" : "none", // Hide or show sidebar based on state
        }}
        className="sidebar-content" // Add a class for event handling
      >
        {/* <div className=" mb-170px"> */}
        <div className="">
          <AdminSidenavProfile />

          {/* Menu */}
          <div className=" sideBar-menu custom-scroll">
            <Menu
              transitionDuration={800}
              renderExpandIcon={({ open }) => (
                <span className={open ? "arrow-collapsed" : "arrow-expanded"}>
                  <ArrowIcon />
                </span>
              )}
              menuItemStyles={{
                button: ({ level, active }) => {
                  // only apply styles on first level elements of the tree
                  if (level === 0)
                    return {
                      color: active ? "white" : "var(--text-menu-color)",
                      // backgroundColor: active
                      //   ? "var(--primary-Dashboard)"
                      //   : "none",
                      backgroundColor: active
                        ? "var(--focus-color)"
                        : "var(--primary-Dashboard)",
                      transition: "background-color .3s ease-in-out",
                      "&:hover": {
                        backgroundColor: "var(--focus-color)",
                      },
                      marginLeft: 12,
                      marginRight: 12,
                      marginBottom: 6,
                      borderRadius: 6,
                      padding: 6,
                    };
                  // only apply styles on second level elements of the tree
                  if (level === 1)
                    return {
                      color: active ? "white" : "var(--text-menu-color)",
                      // backgroundColor: active
                      //   ? "var(--sub-menu-color)"
                      //   : "var(--focus-color)",
                      // backgroundColor: active
                      //   ? "var(--focus-color)"
                      //   : "var(--sub-menu-color)",
                      backgroundColor: active
                        ? "var(--sub-menu-color)"
                        : "var(--sub-menu-color)",
                      transition: "background-color .3s ease-in-out",
                      "&:hover": {
                        color: "white",
                        backgroundColor: "var(--sub-menu-color)",
                        // backgroundColor: "var(--focus-color)",
                      },
                      padding: 18,
                    };
                },
              }}
            >
              {/* Dashboard */}
              <MenuItem
                onClick={() => handleOpenSubMenu("")}
                active={location.pathname === "/admin"}
                className={` ${
                  location.pathname === "/admin" ? "" : "menu-svg"
                }`}
                icon={<DashboardIcon />}
                component={<Link to="/admin" />}
              >
                Dashboard
              </MenuItem>
              {/* user management menu */}
              <SubMenu
                onClick={() => handleOpenSubMenu("users")}
                open={open === "users"}
                defaultOpen={location.pathname.includes("users")}
                active={location.pathname.includes("users")}
                label="User Management"
                className={` ${
                  location.pathname.includes("users") ? "" : "menu-svg"
                }`}
                icon={<UserManagementIcon />}
              >
                {/* Advertizer List */}
                <MenuItem
                  active={location.pathname.includes("advertiser")}
                  className={` ${
                    location.pathname.includes("advertiser") ? "" : "menu-svg-2"
                  }`}
                  icon={<DotIcon />}
                  component={<Link to={adminPrefixedRoutes.ADVERTISER} />}
                >
                  Advertiser List
                </MenuItem>

                {/* Influencer List */}
                <MenuItem
                  active={location.pathname.includes("influencer")}
                  className={` ${
                    location.pathname.includes("influencer") ? "" : "menu-svg-2"
                  }`}
                  icon={<DotIcon />}
                  component={<Link to={adminPrefixedRoutes.INFLUENCER} />}
                >
                  Influencer List
                </MenuItem>
              </SubMenu>
              {/* Packages */}
              <MenuItem
                onClick={() => handleOpenSubMenu("")}
                active={location.pathname.includes(
                  adminPrefixedRoutes.PACKAGES
                )}
                className={` ${
                  location.pathname.includes(adminPrefixedRoutes.PACKAGES)
                    ? ""
                    : "menu-svg"
                }`}
                icon={<BookingIcon />}
                component={<Link to={adminPrefixedRoutes.PACKAGES} />}
              >
                Packages
              </MenuItem>
              {/* Order menu */}

              <MenuItem
                onClick={() => handleOpenSubMenu("")}
                active={location.pathname.includes("orders")}
                className={` ${
                  location.pathname.includes("orders") ? "" : "menu-svg"
                }`}
                icon={<OrdersRoundedIcon />}
                component={<Link to="orders" />}
              >
                Orders
              </MenuItem>

              {/* Transaction */}
              <MenuItem
                onClick={() => handleOpenSubMenu("")}
                active={location.pathname.includes(
                  adminPrefixedRoutes.TRANSACTION
                )}
                className={` ${
                  location.pathname.includes(adminPrefixedRoutes.TRANSACTION)
                    ? ""
                    : "menu-svg"
                }`}
                icon={<TransactionIcon />}
                component={<Link to={adminPrefixedRoutes.TRANSACTION} />}
              >
                Transaction
              </MenuItem>
              {/* Message */}
              <MenuItem
                onClick={() => handleOpenSubMenu("")}
                active={location.pathname.includes(adminPrefixedRoutes.MESSAGE)}
                className={` ${
                  location.pathname.includes(adminPrefixedRoutes.MESSAGE)
                    ? ""
                    : "menu-svg"
                }`}
                icon={<MessageWhiteIcon />}
                component={<Link to={adminPrefixedRoutes.MESSAGE} />}
              >
                Message
              </MenuItem>
              {/* Settings menu */}
              <SubMenu
                onClick={() => handleOpenSubMenu("settings")}
                open={open === "settings"}
                defaultOpen={location.pathname.includes("settings")}
                active={location.pathname.includes("settings")}
                label="Settings"
                className={` ${
                  location.pathname.includes("settings") ? "" : " menu-svg"
                }`}
                icon={<SettingsNewIcon />}
              >
                <MenuItem
                  active={location.pathname.includes("web-content")}
                  className={` ${
                    location.pathname.includes("web-content")
                      ? ""
                      : "menu-svg-2"
                  }`}
                  icon={<DotIcon />}
                  component={<Link to="settings/web-content" />}
                >
                  Web Content CMS
                </MenuItem>
                {/* <MenuItem
                  active={location.pathname.includes("general-settings")}
                  className={` ${
                    location.pathname.includes("general-settings")
                      ? ""
                      : "menu-svg-2"
                  }`}
                  icon={<DotIcon />}
                  component={<Link to="settings/general-settings" />}
                >
                  General Settings
                </MenuItem> */}
                <MenuItem
                  active={location.pathname.includes(adminRoutes.CATEGORY)}
                  className={` ${
                    location.pathname.includes(adminRoutes.CATEGORY)
                      ? ""
                      : "menu-svg-2"
                  }`}
                  icon={<DotIcon />}
                  component={<Link to={adminPrefixedRoutes.CATEGORY} />}
                >
                  Category
                </MenuItem>

                <MenuItem
                  active={location.pathname.includes(adminRoutes.TAG)}
                  className={` ${
                    location.pathname.includes(adminRoutes.TAG)
                      ? ""
                      : "menu-svg-2"
                  }`}
                  icon={<DotIcon />}
                  component={<Link to={adminPrefixedRoutes.TAG} />}
                >
                  Tag
                </MenuItem>

                <MenuItem
                  active={location.pathname.includes(adminRoutes.FEATURE)}
                  className={` ${
                    location.pathname.includes(adminRoutes.FEATURE)
                      ? ""
                      : "menu-svg-2"
                  }`}
                  icon={<DotIcon />}
                  component={<Link to={adminPrefixedRoutes.FEATURE} />}
                >
                  Featured
                </MenuItem>
              </SubMenu>
              {/* Booking Oversight */}
              {/* <SubMenu
                onClick={() => handleOpenSubMenu("Booking Oversight")}
                open={open === "Booking Oversight"}
                defaultOpen={location.pathname.includes("booking")}
                active={location.pathname.includes("booking")}
                label="Booking Oversight"
                className={` ${
                  location.pathname.includes("booking") ? "" : " menu-svg-1"
                }`}
                icon={<BookingIcon />}
              >
                <MenuItem
                  active={location.pathname.includes("all-bookings")}
                  className={` ${
                    location.pathname.includes("all-bookings")
                      ? ""
                      : "menu-svg-2"
                  }`}
                  icon={<DotIcon />}
                  component={<Link to={adminPrefixedRoutes.ALL_BOOKINGS} />}
                >
                  All Bookings
                </MenuItem>

                <MenuItem
                  active={location.pathname.includes("disputes")}
                  className={` ${
                    location.pathname.includes("disputes") ? "" : "menu-svg-2"
                  }`}
                  icon={<DotIcon />}
                  component={<Link to={adminPrefixedRoutes.DISPUTES} />}
                >
                  Disputes
                </MenuItem>
              </SubMenu> */}
              {/* Reports & Analytics */}
              {/* <MenuItem
                onClick={() => handleOpenSubMenu("")}
                active={location.pathname.includes("report")}
                className={` ${
                  location.pathname.includes("report") ? "" : "menu-svg"
                }`}
                icon={<ReportsIcon />}
                component={<Link to="/admin/report" />}
              >
                Reports & Analytics
              </MenuItem> */}
              {/* -------------------------Extra Menu Items------------------------- */}

              {/* other menu */}
              {/* <MenuItem
              onClick={() => handleOpenSubMenu("")}
              active={location.pathname.includes("analytics")}
              className={` ${
                location.pathname.includes("analytics") ? "" : "menu-svg-1"
              } `}
              icon={<AnalyticsIcon />}
              component={<Link to="/admin/analytics" />}
            >
              Analytics
            </MenuItem> */}
              {/* <MenuItem
              onClick={() => handleOpenSubMenu("")}
              active={location.pathname.includes("reports")}
              className={` ${
                location.pathname.includes("reports") ? "" : "menu-svg"
              }`}
              icon={<ReportsIcon />}
              component={<Link to="/admin/reports" />}
            >
              Reports
            </MenuItem> */}
              {/* <MenuItem
              onClick={() => handleOpenSubMenu("")}
              active={location.pathname.includes("settings")}
              className={` ${
                location.pathname.includes("settings") ? "" : "menu-svg"
              }`}
              icon={<SettingsIcon />}
              component={<Link to="/admin/settings" />}
            >
              Settings
            </MenuItem> */}
              {/* -------------------------^_^--Extra Menu Items--^_^------------------------- */}
            </Menu>
          </div>

          {/* Logout */}
          <div className=" bg-primary-color-dark mt-120px">
            <hr className="m-0 opacity-100 " />
            <div className="d-flex justify-content-center align-items-end m-5">
              <div
                onClick={handleLogout}
                className="cursor-pointer text-decoration-none text-white d-flex gap-3 align-items-center justify-content-center "
              >
                <LogoutIcon /> <span>Logout</span>
              </div>
            </div>
          </div>
        </div>

        {/* Logout Optional*/}
        {/* <div className=" fixed-bottom h-160px w-249px  bg-primary-color-dark">
          <hr className="m-0 opacity-100 " />
          <div className="d-flex justify-content-center m-5 ">
            <div
              onClick={handleLogout}
              className="cursor-pointer text-decoration-none text-white d-flex gap-3 align-items-center justify-content-center "
            >
              <LogoutIcon /> <span>Logout</span>
            </div>
          </div>
        </div> */}
      </Sidebar>

      {/* Hamburger icon for small screens */}
      <div
        className="d-block d-md-none ms-1  cursor-pointer   z-index-10  "
        onClick={() => toggleSidebar()}
      >
        {sidebarVisible ? <CloseIcon /> : <HamburgerIcon />}
      </div>
    </div>
  );
};
export default AdminSideBar;

/* -----------------------------------------------------------
------------------------backup code---------
----------------------------------- */

// import { useLocation, Link } from "react-router-dom";
// import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
// import useLogout from "../../../../hooks/other-hooks/useLogout";

// import DashboardIcon, {
//   AnalyticsIcon,
//   ArrowIcon,
//   DotIcon,
//   LogoutIcon,
//   ReportsIcon,
//   SettingsIcon,
//   TransactionIcon,
//   UserIcon,
//   ProductsIcon,
//   OrdersIcon,
//   MessageWhiteIcon,
//   OrdersRoundedIcon,
//   CloseIcon,
//   HamburgerIcon,
// } from "../../../../utils/MyIcons";
// import { useEffect, useState } from "react";
// import AdminSidenavProfile from "./layout-partials/admin-sidenav/AdminSidenavProfile";

// const AdminSideBar = () => {
//   const handleLogout = useLogout();
//   const location = useLocation();
//   const [open, setOpen] = useState("");
//   const [sidebarVisible, setSidebarVisible] = useState(true); // State to manage sidebar visibility

//   // Function to toggle sidebar visibility
//   const toggleSidebar = () => {
//     setSidebarVisible(!sidebarVisible);
//   };

//   // Function to detect screen size changes and show/hide sidebar accordingly
//   useEffect(() => {
//     const handleResize = () => {
//       const isSmallScreen = window.innerWidth < 768; // Adjust breakpoint as needed
//       if (isSmallScreen) {
//         setSidebarVisible(false); // Hide sidebar on small screens
//       } else {
//         setSidebarVisible(true); // Show sidebar on large screens
//       }
//     };

//     window.addEventListener("resize", handleResize);
//     handleResize(); // Call the function initially
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);
//   const handleOpenSubMenu = (key) => {
//     if (open === key) {
//       setOpen("");
//     } else {
//       setOpen(key);
//     }
//   };

//   return (
//     <div className="text-white  d-flex  ">
//       <Sidebar
//         backgroundColor="rgba(0,0,0,0)"
//         rootStyles={{
//           background: "var(--primary-Dashboard)",
//           display: sidebarVisible ? "block" : "none", // Hide or show sidebar based on state
//         }}
//         className="sidebar-content" // Add a class for event handling
//       >
//         <div className="mb-170px ">
//           <AdminSidenavProfile />

//           {/* Menu */}
//           <div className=" sideBar-menu custom-scroll">
//             <Menu
//               transitionDuration={800}
//               renderExpandIcon={({ open }) => (
//                 <span className={open ? "arrow-collapsed" : "arrow-expanded"}>
//                   <ArrowIcon />
//                 </span>
//               )}
//               menuItemStyles={{
//                 button: ({ level, active }) => {
//                   // only apply styles on first level elements of the tree
//                   if (level === 0)
//                     return {
//                       color: active ? "white" : "var(--text-menu-color)",
//                       backgroundColor: active ? "var(--focus-color)" : "none",
//                       transition: "background-color .3s ease-in-out",
//                       "&:hover": {
//                         backgroundColor: "var(--focus-color)",
//                       },
//                       marginLeft: 12,
//                       marginRight: 12,
//                       marginBottom: 6,
//                       borderRadius: 6,
//                       padding: 6,
//                     };
//                   // only apply styles on second level elements of the tree
//                   if (level === 1)
//                     return {
//                       color: active ? "white" : "var(--text-menu-color)",
//                       backgroundColor: active
//                         ? "var(--sub-menu-color)"
//                         : "var(--focus-color)",
//                       transition: "background-color .3s ease-in-out",
//                       "&:hover": {
//                         backgroundColor: "var(--sub-menu-color)",
//                       },
//                       padding: 18,
//                     };
//                 },
//               }}
//             >
//               {/* Dashboard */}
//               <MenuItem
//                 onClick={() => handleOpenSubMenu("")}
//                 active={location.pathname === "/admin"}
//                 className={` ${
//                   location.pathname === "/admin" ? "" : "menu-svg"
//                 }`}
//                 icon={<DashboardIcon />}
//                 component={<Link to="/admin" />}
//               >
//                 Dashboard
//               </MenuItem>

//               {/* user management menu */}
//               <SubMenu
//                 onClick={() => handleOpenSubMenu("users")}
//                 open={open === "users"}
//                 defaultOpen={location.pathname.includes("users")}
//                 active={location.pathname.includes("users")}
//                 label="User Management"
//                 className={` ${
//                   location.pathname.includes("users") ? "" : "menu-svg-1"
//                 }`}
//                 icon={<UserIcon />}
//               >
//                 <MenuItem
//                   active={location.pathname.includes("clinic")}
//                   className={` ${
//                     location.pathname.includes("clinic") ? "" : "menu-svg-2"
//                   }`}
//                   icon={<DotIcon />}
//                   component={<Link to="users/clinic" />}
//                 >
//                   Clinic
//                 </MenuItem>
//                 <MenuItem
//                   active={location.pathname.includes("lab")}
//                   className={` ${
//                     location.pathname.includes("lab") ? "" : "menu-svg-2"
//                   }`}
//                   icon={<DotIcon />}
//                   component={<Link to="users/lab" />}
//                 >
//                   Lab
//                 </MenuItem>
//               </SubMenu>

//               {/* Product menu */}
//               <SubMenu
//                 onClick={() => handleOpenSubMenu("Booking Oversight")}
//                 open={open === "Booking Oversight"}
//                 defaultOpen={location.pathname.includes("Booking Oversight")}
//                 active={location.pathname.includes("Booking Oversight")}
//                 label="Booking Oversight"
//                 className={` ${
//                   location.pathname.includes("Booking Oversight") ? "" : " menu-svg-1"
//                 }`}
//                 icon={<ProductsIcon />}
//               >
//                 <MenuItem
//                   active={location.pathname.includes("product-list")}
//                   className={` ${
//                     location.pathname.includes("product-list")
//                       ? ""
//                       : "menu-svg-2"
//                   }`}
//                   icon={<DotIcon />}
//                   component={<Link to="products/product-list" />}
//                 >
//                   Product list
//                 </MenuItem>
//                 <MenuItem
//                   active={location.pathname.includes("product-category")}
//                   className={` ${
//                     location.pathname.includes("product-category")
//                       ? ""
//                       : "menu-svg-2"
//                   }`}
//                   icon={<DotIcon />}
//                   component={<Link to="products/product-category" />}
//                 >
//                   Product category
//                 </MenuItem>
//               </SubMenu>

//               {/* Order menu */}
//               {/* <MenuItem
//                 onClick={() => handleOpenSubMenu("")}
//                 active={location.pathname.includes("orders")}
//                 className={` ${
//                   location.pathname.includes("orders") ? "" : "menu-svg"
//                 }`}
//                 icon={<OrdersRoundedIcon />}
//                 component={<Link to="orders" />}
//               >
//                 Orders
//               </MenuItem> */}

// {/* Transaction */}
// <MenuItem
//   onClick={() => handleOpenSubMenu("")}
//   active={location.pathname.includes("transaction")}
//   className={` ${
//     location.pathname.includes("transaction") ? "" : "menu-svg"
//   }`}
//   icon={<TransactionIcon />}
//   component={<Link to="/admin/transaction" />}
// >
//   Payment
// </MenuItem>

//               {/* Message */}
//               {/* <MenuItem
//                 onClick={() => handleOpenSubMenu("")}
//                 active={location.pathname.includes("message")}
//                 className={` ${
//                   location.pathname.includes("message") ? "" : "menu-svg"
//                 }`}
//                 icon={<MessageWhiteIcon />}
//                 component={<Link to="message" />}
//               >
//                 Message
//               </MenuItem> */}

//               {/* other menu */}
//               {/* <MenuItem
//               onClick={() => handleOpenSubMenu("")}
//               active={location.pathname.includes("analytics")}
//               className={` ${
//                 location.pathname.includes("analytics") ? "" : "menu-svg-1"
//               } `}
//               icon={<AnalyticsIcon />}
//               component={<Link to="/admin/analytics" />}
//             >
//               Analytics
//             </MenuItem> */}

//               {/* <MenuItem
//               onClick={() => handleOpenSubMenu("")}
//               active={location.pathname.includes("reports")}
//               className={` ${
//                 location.pathname.includes("reports") ? "" : "menu-svg"
//               }`}
//               icon={<ReportsIcon />}
//               component={<Link to="/admin/reports" />}
//             >
//               Reports
//             </MenuItem> */}

//               {/* Settings menu */}
//               <SubMenu
//                 onClick={() => handleOpenSubMenu("settings")}
//                 open={open === "settings"}
//                 defaultOpen={location.pathname.includes("settings")}
//                 active={location.pathname.includes("settings")}
//                 label="Settings"
//                 className={` ${
//                   location.pathname.includes("settings") ? "" : " menu-svg-1"
//                 }`}
//                 icon={<SettingsIcon />}
//               >
//                 <MenuItem
//                   active={location.pathname.includes("web-content")}
//                   className={` ${
//                     location.pathname.includes("web-content")
//                       ? ""
//                       : "menu-svg-2"
//                   }`}
//                   icon={<DotIcon />}
//                   component={<Link to="settings/web-content" />}
//                 >
//                   Web Content CMS
//                 </MenuItem>
//                 <MenuItem
//                   active={location.pathname.includes("general-settings")}
//                   className={` ${
//                     location.pathname.includes("general-settings")
//                       ? ""
//                       : "menu-svg-2"
//                   }`}
//                   icon={<DotIcon />}
//                   component={<Link to="settings/general-settings" />}
//                 >
//                   General Settings
//                 </MenuItem>
//               </SubMenu>

//               {/* <MenuItem
//               onClick={() => handleOpenSubMenu("")}
//               active={location.pathname.includes("settings")}
//               className={` ${
//                 location.pathname.includes("settings") ? "" : "menu-svg"
//               }`}
//               icon={<SettingsIcon />}
//               component={<Link to="/admin/settings" />}
//             >
//               Settings
//             </MenuItem> */}
//             </Menu>
//           </div>
//         </div>
//         {/* Logout */}
//         <div className=" fixed-bottom h-160px w-249px  bg-primary-color-dark">
//           <hr className="m-0 opacity-100 " />
//           <div className="d-flex justify-content-center m-5 ">
//             <div
//               onClick={handleLogout}
//               className="cursor-pointer text-decoration-none text-white d-flex gap-3 align-items-center justify-content-center "
//             >
//               <LogoutIcon /> <span>Logout</span>
//             </div>
//           </div>
//         </div>
//       </Sidebar>

//       {/* Hamburger icon for small screens */}
//       <div
//         className="d-block d-md-none ms-1  cursor-pointer   z-index-10  "
//         onClick={() => toggleSidebar()}
//       >
//         {sidebarVisible ? <CloseIcon /> : <HamburgerIcon />}
//       </div>
//     </div>
//   );
// };
// export default AdminSideBar;
