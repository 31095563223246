import React from "react";
import Stats from "../stats/Stats";
import BestLabSeller from "../bestLabSeller/BestLabSeller";
import TopClinic from "../topClinic/TopClinic";
import UserInfo from "../userInfo/UserInfo";
import DashboardPieChart from "../dashboardPieChart/DashboardPieChart";
import DashboardBarChart from "../dashboardBarChart/DashboardBarChart";
import { Helmet } from "react-helmet";
// import DashboardAreaChart from "../dashboardAreaChart/DashboardAreaChart";
import { adminPageNames } from "../../../../routes/all-routes/adminRoutes";
import TopPackage from "../topPackage/TopPackage";

const Dashboard = () => {
  return (
    <>
      <Helmet>
        <title>
          {adminPageNames.DASHBOARD} | Admin | {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <div className="container   ">
        <div className="row gy-4 align-items-stretch">
          <div className="col-lg-6  ">
            <Stats />
          </div>
          <div className="col-lg-6 ">
            <DashboardPieChart />
          </div>
          <div className="col-12 col-xxl-4">
            <TopPackage />
          </div>

          <div className="col-lg-6 col-xxl-4">
            <BestLabSeller />
          </div>

          <div className="col-lg-6 col-xxl-4">
            <TopClinic />
          </div>
          <div className="col-12">
            <UserInfo />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
