import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ClinicIcon,
  LabIcon,
  OrderIcon,
  OrdersIcon,
  OrdersRoundedIcon,
} from "../../../../utils/MyIcons";
import MessageBadge from "./MessageBadge";
import advertiserPrefixedRoutes from "../../../../routes/all-routes/advertiserRoutes";

function formatDate(isoDate) {
  const date = new Date(isoDate);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");

  let amPm = "AM";
  if (hours >= 12) {
    amPm = "PM";
    hours %= 12;
  }
  if (hours === 0) {
    hours = 12;
  }

  return `${day}/${month}/${year} ${hours}:${minutes} ${amPm}`;
}

const MessageItem = ({
  messageItem,
  handleAddMessage,
  order,
  setDisplayedMessages,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const {
    orderNumber,
    advertiserId,
    influencerId,
    lastMessageTime,
    totalMessages,
  } = messageItem;

  const handleClick = () => {
    setDisplayedMessages([]);
    navigate(`${advertiserPrefixedRoutes.MESSAGE}/${messageItem?._id}`);
    handleAddMessage(messageItem);
  };

  return (
    <div
      onClick={handleClick}
      style={{
        cursor: "pointer",
        backgroundColor:
          messageItem?._id === params?.id ? "var(--highlight-color)" : "",
      }}
    >
      <div className="d-flex justify-content-center align-items-center border p-3">
        <div className="ms-1 w-100">
          <div className="d-flex justify-content-between flex-column">
            <div className="fs-14 fw-bold  d-flex justify-content-between ">
              <div>
                <span className="message-svg">
                  <OrderIcon />
                </span>
                <span className="ms-1 text-break"> {orderNumber}</span>
              </div>
              <span>
                <MessageBadge messageCount={totalMessages} />
              </span>
            </div>

            <div className="d-block d-md-flex justify-content-between">
              <div className="fs-12 d-flex justify-content-start  me-1 ">
                <span className="message-svg">
                  <ClinicIcon />
                </span>
                <span className="ms-1">{advertiserId?.fullName}</span>
              </div>

              <div className="fs-12 d-flex justify-content-end ">
                <span className="me-1">{influencerId?.fullName}</span>
                <span className="message-svg">
                  <LabIcon />
                </span>
              </div>
            </div>

            {lastMessageTime && (
              <h5 className="fs-10 d-flex mt-2 justify-content-end">
                <span className="ms-1 text-break">
                  {lastMessageTime
                    ? formatDate(lastMessageTime)
                    : "No Chat yet"}
                </span>
              </h5>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageItem;
