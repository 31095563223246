// useGetcartItems.js
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { commonRoutes } from "../../../../routes/all-routes/commonRoutes";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";
import { useState } from "react";
import { toast } from "react-toastify";
import { setCartData } from "../../../../redux/slices/cartSlice";

const useGetcartItems = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.user.accessToken);
  const [cartSummary, setCartSummary] = useState({});
  const [isLoadingSummary, setIsLoadingSummary] = useState(false);

  const {
    isLoading,
    isError,
    data,
    refetch: refetchCartItems, // Extract refetch for cart items
  } = useQuery({
    queryKey: ["useGetcartItems"],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/advertiser/cart/items?pageNumber=1&pageSize=0&sortField=createdAt&sortDirection=desc`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        const text = await response.json();
        if (text?.error?.status === 401) {
          navigate(authPrefixedRoutes.LOGIN);
        }
        navigate(commonRoutes.SERVER_ERROR);
        return;
      }
      const data = await response.json();
      return data;
    },
  });

  const {
    isError: isErrorSummary,
    refetch: refetchCartSummary, // Extract refetch for cart summary
  } = useQuery({
    queryKey: ["cartSummaryData"],
    queryFn: async () => {
      setIsLoadingSummary(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/advertiser/cart/cart-items-total-price`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        const text = await response.json();
        if (text?.error?.status === 401) {
          navigate(authPrefixedRoutes.LOGIN);
        }
        navigate(commonRoutes.SERVER_ERROR);
        return;
      }
      const data = await response.json();
      setCartSummary(data);
      setIsLoadingSummary(false);
      return data;
    },
  });

  const { mutate: deleteCartItem } = useMutation({
    mutationKey: ["removecartitem"],
    mutationFn: async (id) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/advertiser/cart/remove-item/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      if (!response.ok) {
        const text = await response.json();
        if (text?.error?.status === 401) {
          navigate(authPrefixedRoutes.LOGIN);
        }
        throw new Error(data.error.message);
      }

      if (response.status === 200) {
        toast.success("Item removed from cart successfully!");
      }

      return data;
    },
    onSuccess: (responseData) => {
      // Assuming `responseData` contains the updated cart information
      dispatch(setCartData({ cartItemCount: responseData.cartItemCount }));
      // Refetch both cart items and summary data after successful removal
      refetchData();
    },
    onError: (error) => {
      throw error;
    },
  });

  // Refetch both cart items and summary data
  const refetchData = () => {
    refetchCartItems();
    refetchCartSummary();
  };

  return {
    isLoading,
    isError,
    data,
    isLoadingSummary,
    isErrorSummary,
    cartSummary,
    refetchData,
    deleteCartItem,
  };
};

export default useGetcartItems;
