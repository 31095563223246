import React from "react";
import DashboardPieChart from "../../../admin/components/dashboardPieChart/DashboardPieChart";
import { Helmet } from "react-helmet";
import { influencerPageNames } from "../../../../routes/all-routes/influencerRoutes";
import Stats from "../dashboard/stats/Stats";
import TopPackage from "../dashboard/topPackage/TopPackage";
import UserInfo from "../dashboard/userInfo/UserInfo";

const Dashboard = () => {
  return (
    <>
      <Helmet>
        <title>
          {influencerPageNames.DASHBOARD} | Influencer |
          {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <div className="container">
        <div className="row gy-4 align-items-stretch">
          <div className="col-12 col-lg-6 col-xxl-4  ">
            <Stats />
          </div>
          <div className="col-12 col-lg-6 col-xxl-4">
            <DashboardPieChart />
          </div>
          <div className="col-12 col-xxl-4">
            <TopPackage />
          </div>
          <div className="col-12">
            <UserInfo />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
