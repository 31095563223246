import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";

const useGetOrderDetailsById = (id) => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.user.accessToken);

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ["getOrderDetailsById", id], // Include `id` in the query key for dynamic fetching
    queryFn: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/influencer/order-status-management/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const text = await response.json();
        if (text?.error?.status === 401) {
          navigate(authPrefixedRoutes.LOGIN);
        }
        throw new Error(text.message || "Failed to fetch order details");
      }

      return response.json(); // Return the fetched data
    },
    enabled: !!id, // Ensure the query runs only if `id` is available
  });

  // Refetch function to manually refresh data
  const refetchData = () => {
    refetch();
  };

  return {
    isLoading,
    isError,
    data,
    refetchData, // Expose the refetch function
  };
};

export default useGetOrderDetailsById;

