import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { authPrefixedRoutes } from "../../../routes/all-routes/authRoutes";
import { commonRoutes } from "../../../routes/all-routes/commonRoutes";
import { useDispatch } from "react-redux";
import { setForgetPasswordData } from "./../../../redux/slices/forgetPasswordSlice";

const useForgetPassword = () => {
  const navigate = useNavigate(); // Initialize navigate
  const dispatch = useDispatch();
  const [forgetPasswordError, setForgetPasswordError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [forgetPasswordSuccessResponse, setForgetPasswordSuccessResponse] =
    useState(null); // Add state for success response message and data

  const {
    mutate: forgetPassword,
    isLoading,
    isError,
  } = useMutation({
    mutationKey: "forgetPassword",
    mutationFn: async (formData) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/auth/forgot-password/send-otp`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        const data = await response.json();

        if (!response.ok) {
          const text = await response.json();
          if (text?.error?.status === 401) {
            navigate(authPrefixedRoutes.LOGIN);
          }
          setForgetPasswordError(data.error);
          throw new Error(data.error.message);
        }

        // Set submit success flag
        setSubmitSuccess(true);
        dispatch(setForgetPasswordData({ email: formData.email }));
        // Set success response data directly from the response
        setForgetPasswordSuccessResponse(data);

        // Delay navigation for 1 second
        setTimeout(() => {
          // navigate to verify otp
          navigate(commonRoutes.FORGET_PASSWORD_OTP);
        }, 1500);

        return data;
      } catch (error) {
        // Set Forget Password error state
        setForgetPasswordError(error);
        throw error;
      }
    },
    onError: (error) => {
      setForgetPasswordError(error);
      throw error;
    },
  });

  return {
    forgetPassword,
    isLoading,
    isError,
    forgetPasswordError,
    submitSuccess,
    forgetPasswordSuccessResponse,
  };
};
export default useForgetPassword;
