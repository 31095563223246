import React from "react";
import SiteLogoContainer from "../../../common/components/section/SiteLogoContainer";
import {
  TwitterIcon,
  FacebookIcon,
  LinkedInIcon,
} from "../../../../utils/WebIcons";
import useWebContent from "../../../../hooks/data-hooks/website/useWebContent";
import Partner from "../partner/Partner";

const Footer = () => {

  const { data } = useWebContent();
  const { section_one, section_two, section_three } =
    data?.data?.footer_links?.content;

  const FooterInnerLayout = ({ children }) => {
    return <div className=" mb-3">{children}</div>;
  };

  const SocialMediaIcons = () => {
    return (
      <div className="d-flex">
        <div className="icon-wrapper cursor-pointer">
          <a href="https://twitter.com">
            <TwitterIcon />
          </a>
        </div>
        <div className="icon-wrapper cursor-pointer">
          <a href="https://facebook.com">
            <FacebookIcon />
          </a>
        </div>
        <div className="icon-wrapper cursor-pointer">
          <a href="https://linkedin.com">
            <LinkedInIcon />
          </a>
        </div>
      </div>
    );
  };
 
  return (
    <div className="w-100">
      <div className="partner">
        <Partner data={data?.data?.brand?.content} />
      </div>

      {/* <div className="footer"></div> */}
      <footer className="w-100 bg-black pt-140 px-4 position-relative">
        <div className=" container-fluid d-flex justify-content-evenly align-items-start flex-wrap flex-column flex-md-row py-3">
          {/* 1 */}
          <FooterInnerLayout>
            <SiteLogoContainer logoColor="white" />
            <address className="fs-16 color-white-cream mt-4 font-family-secondary lh-lg">
              1429 Something Bridge, LA 4281, <br />
              Call: (321) 428 321 3902
            </address>
            <SocialMediaIcons />
          </FooterInnerLayout>

          {/* 2 */}
          <FooterInnerLayout>
            <div className="footer-title text-decoration-none color-white-cream">
              {section_one?.title}
             
            </div>
            <ul>
              {section_one?.links?.map((item) => (
                <li
                  className="color-white-cream lh-lg"
                  key={JSON.stringify(item)}
                >
                  <a
                    href={item?.url}
                    className="text-decoration-none color-white-cream fs-18"
                  >
                    {item?.label}
                  </a>
                </li>
              ))}
             
            </ul>
          </FooterInnerLayout>

          {/* 3 */}
          <FooterInnerLayout>
            <div className="footer-title text-decoration-none color-white-cream">
              {section_two?.title}
              
            </div>
            <ul>
              {section_two?.links?.map((item) => (
                <li
                  className="color-white-cream lh-lg"
                  key={JSON.stringify(item)}
                >
                  <a
                    href={item?.url}
                    className="text-decoration-none color-white-cream fs-18"
                  >
                    {item?.label}
                  </a>
                </li>
              ))}
             
            </ul>
          </FooterInnerLayout>

          {/* 4 */}
          <FooterInnerLayout>
            <div className="footer-title text-decoration-none color-white-cream">
              {section_three?.title}
            </div>
            <ul>
              {section_three?.links?.map((item) => (
                <li
                  className="color-white-cream lh-lg"
                  key={JSON.stringify(item)}
                >
                  <a
                    href={item?.url}
                    className="text-decoration-none color-white-cream fs-18"
                  >
                    {item?.label}
                  </a>
                </li>
              ))}
              
            </ul>
          </FooterInnerLayout>
        </div>
      </footer>
    </div>
  );
};

export default Footer;