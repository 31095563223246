import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Loader from "../../../common/components/loader/Loader";
import BackButton from "../../../../helpers/backButton.js";
import useUpdatePackage from "../../../../hooks/data-hooks/influencer/social-profile/useUpdatePackage.js";
import useGetPackageById from "../../../../hooks/data-hooks/influencer/social-profile/useGetPackageById.js";
import { platformList } from "../../../../utils/Socials";

const UpdateSocialProfile = () => {
  const params = useParams();
  const { fetchPackageWithId, packageDetails, isLoading, isError } =
    useGetPackageById(params.id);

  console.log("packageDetails : ", packageDetails);

  const {
    updateContent,
    isLoadingUpdate,
    isErrorUpdate,
    error,
    submitSuccess,
    isSubmitting,
  } = useUpdatePackage(params.id);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    // Set default values if data1 is available
    if (packageDetails) {
      setValue("socialPlatformName", packageDetails?.socialPlatformName);
      setValue("socialLink", packageDetails?.socialLink);
      setValue("followers", packageDetails?.followers);
      setValue("engagementRate", packageDetails?.engagementRate);
    }
  }, [packageDetails]);

  useEffect(() => {
    // Show error toast if isError is true
    if (isError) {
      toast.error(error.message);
    } else if (isErrorUpdate) {
      toast.error(isErrorUpdate);
    }
  }, [isError, error, isErrorUpdate]);

  useEffect(() => {
    // Show success toast if submitSuccess is true
    if (submitSuccess) {
      toast.success("Form Submit Successful");
    }
  }, [submitSuccess]);

  const onSubmit = async (data) => {
    // Prevent form submission if there is an ongoing login process
    if (isSubmitting) return;

    try {
      // Call the custom hook for API call
      updateContent(data);
    } catch (error) {
      // Error handling is done in useEffect
    }
  };

  useEffect(() => {
    fetchPackageWithId(params.id); // Fetch clinic details when clinicId changes
  }, [params.id]);

  if (isLoading) return <Loader />;
  return (
    <div className="container">
      <div className=" col-lg-6">
        <div className="cms-card ">
          <BackButton />
          <form className="cms-form mb-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="fs-24 fw-bold">Update Social Profile Details</div>

            <div className="mt-5">
              {/* social Platform */}
              <div className="form-item">
                <label className="">Choose Social Platform</label>
                <div className="input-wrapper">
                  <select
                    className="custom-select"
                    {...register("socialPlatformName", {
                      required: "Platform Name is required",
                    })}
                  >
                    <option value="">Select Platform</option>
                    {platformList.map((platform, index) => (
                      <option key={index} value={platform}>
                        {platform}
                      </option>
                    ))}
                  </select>
                </div>
                {errors.socialPlatformName && (
                  <p className="text-danger">
                    {errors.socialPlatformName.message}
                  </p>
                )}
              </div>

              {/* Social Platform Link */}
              <div className="form-item">
                <label className="">Social Link</label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    className=""
                    placeholder="Enter social link e.g. https://www.facebook.com/user1"
                    {...register("socialLink", {
                      required: "Social Link is required",
                      pattern: {
                        value:
                          /^(https?:\/\/)?(www\.)?[a-zA-Z0-9._%+-]+\.[a-z]{2,}(\/[A-Za-z0-9._%+-]*)*$/i,
                        message: "Invalid URL format",
                      },
                    })}
                  />
                  {errors.socialLink && (
                    <span className="error">{errors.socialLink.message}</span>
                  )}
                </div>
              </div>

              {/* followers */}
              <div className="form-item">
                <label className="">Followers</label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    className=""
                    placeholder="Enter followers e.g. 126500"
                    {...register("followers", {
                      required: "followers is required",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Must be a valid number",
                      },
                    })}
                  />
                  {errors.followers && (
                    <span className="error">{errors.followers.message}</span>
                  )}
                </div>
              </div>

              {/* engagementRate */}
              <div className="form-item">
                <label className="">Engagement Rate</label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    className=""
                    placeholder="Enter engagement Rate e.g. 43%"
                    {...register("engagementRate", {
                      required: "engagement Rate is required",
                      pattern: {
                        value: /^[0-9]+%$/,
                        message: "Must be a percentage (e.g., 43%)",
                      },
                    })}
                  />
                  {errors.engagementRate && (
                    <span className="error">
                      {errors.engagementRate.message}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <button type="submit" className="w-100 button-primary h-64px">
              {isSubmitting ? "Updating..." : "Update Profile"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
export default UpdateSocialProfile;
