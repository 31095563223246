import React from "react";
import { useNavigate } from "react-router-dom";
import { IoArrowBackSharp } from "react-icons/io5";

const BackButton = ({
  label = "Go Back",
  className = "btn bg-primary-color color-white-cream me-3 mb-3   ",
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1); // This will take the user back to the previous page
  };

  return (
    <button className={className} onClick={handleClick}>
      <IoArrowBackSharp className="me-2" />
      {label}
    </button>
  );
};

export default BackButton;
