import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";
import { useNavigate } from "react-router-dom";

const useGetLab = (initialQueryParams) => {
  const token = useSelector((state) => state.auth.user.accessToken);

  const [labList, setLabList] = useState([]);
  // const [sortedLabList, setSortedLabList] = useState([]);
  const [labListMeta, setLabListMeta] = useState({});
  const [labListError, setLabListError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const fetchLabList = useQuery({
    queryKey: ["labList1", initialQueryParams], // Include queryParams in the query key
    queryFn: async () => {
      setIsLoading(true);

      // Modify queryFn to accept queryParams
      try {
        let url = constructLabListUrl(initialQueryParams);
        //console.log("API URL:", url); // Log the API URL
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        // console.log("hello");

        if (!response.ok) {
          const text = await response.json();
          if (text?.error?.status === 401) {
            navigate(authPrefixedRoutes.LOGIN);
          }
          const text1 = await response.text(); // Get response text for error handling
          //console.error("Error response:", text);
          setLabListError(text1);
          setIsLoading(false);
          throw new Error("Failed to fetch data"); // Throw an error to trigger onError callback
        }

        // console.log("Response:", response);

        const responseData = await response.json();
        // console.log("Response data:", responseData);
        // console.log("Response data:", responseData.data.sort()); // Log response data

        setLabList(responseData.data); // Set lab list from response data field
        setLabListMeta(responseData.meta); // Set lab list meta information
        setIsLoading(false);

        return responseData; // Return the fetched data
      } catch (error) {
        //console.error("Fetch error:", error); // Log fetch error
        setLabListError(error);
        setIsLoading(false);
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    onError: (error) => {
      //console.error("Query error:", error); // Log query error
      setLabListError(error);
      setIsLoading(false);

      throw error;
    },
  });

  const fetchLabListWithParams = (queryParams) => {
    //console.log("qp:",queryParams);
    const apiUrl = constructLabListUrl(queryParams);
    //console.log("apiUrl:",apiUrl);
    fetchLabList.refetch(apiUrl);
  };

  const constructLabListUrl = (queryParams) => {
    const queryString = Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join("&");
    return `${process.env.REACT_APP_API_BASE_URL}/api/admin/influencer/all?${queryString}`;
  };

  useEffect(() => {
    fetchLabListWithParams(initialQueryParams);
  }, []);

  return {
    fetchLabListWithParams,
    isLoading,
    isError: fetchLabList.isError,
    labList,
    setLabList,
    labListMeta,
    labListError,
  };
};

export default useGetLab;
