import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import useUpdateServiceCountSection from "../../../../hooks/data-hooks/admin/homepage/service-count-section/useUpdateServiceCountSection";
import useGetServiceCountSection from "../../../../hooks/data-hooks/admin/homepage/service-count-section/useGetServiceCountSection";
import Button from "../../../web/components/button/Button";
import Loader from "../../../common/components/loader/Loader";

const ServiceCountSection = () => {
  const {
    data1,
    isLoading1,
    isError1,
    handleRemovePair,
    serviceCountData,
    placeHolder,
  } = useGetServiceCountSection();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    resetField,
  } = useForm();
  const [inputPairs, setInputPairs] = useState(0);

  const handleAddPair = () => setInputPairs((prevCount) => prevCount + 1);

  useEffect(() => {
    console.log("data1:", data1); // Check the structure of data1 for debugging
    // Check if data1 and data1.data.content exist and is an array
    if (Array.isArray(data1?.data?.content)) {
      data1.data.content.forEach((item, index) => {
        Object.entries(item).forEach(([key, value]) => {
          setValue(`${key}_${index}`, value);
        });
      });
      setInputPairs(data1.data.content.length);
    } else {
      console.warn("data1.data.content is not an array or is undefined.");
    }
  }, [data1, setValue]);


  const { updateContent, isLoading, isError, error, submitSuccess } =
    useUpdateServiceCountSection();

  useEffect(() => {
    if (isError || error || isError1) {
      toast.error(isError || error || isError1);
    }
  }, [isError, error, isError1]);

  // useEffect(() => {
  //   if (submitSuccess) {
  //     toast.success("Form Submit Successful");
  //   }
  // }, [submitSuccess]);

  const onSubmit = async (data) => {
    if (isSubmitting) return;
    try {
      const serviceItems = [];
      for (let i = 0; data[`name_${i}`] !== undefined; i++) {
        const name = data[`name_${i}`];
        const count = data[`count_${i}`];

        if (!name || !count) continue;

        serviceItems.push({ name, count });
      }

      const formattedData = { service_items: serviceItems };
      updateContent(formattedData);
    } catch (error) {
      // Error handled by useEffect
    }
  };

  if (isLoading1) return <Loader />;

  return (
    <div className="cms-section">
      <div className="cms-card">
        <form className="cms-form mb-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="fs-18px fw-bold mb-3">Service Count Section</div>
          <div className="cms-content custom-scroll">
            {Array.from({ length: inputPairs }).map((_, index) => (
              <div key={index}>
                <div className="form-item">
                  <div className="d-flex justify-content-between">
                    <label>Name - {index + 1}</label>
                    <button
                      type="button"
                      className="border-0 px-2"
                      onClick={() => {
                        handleRemovePair(
                          index,
                          serviceCountData[index]?.name,
                          serviceCountData[index]?.count
                        );
                        setInputPairs((prevCount) => prevCount - 1);
                        resetField(`name_${index}`);
                        resetField(`count_${index}`);
                      }}
                    >
                      X
                    </button>
                  </div>
                  <input
                    placeholder={placeHolder?.service_items?.name}
                    {...register(`name_${index}`, {
                      required: "Field is required",
                    })}
                  />
                </div>
                <div className="form-item">
                  <label>Count - {index + 1}</label>
                  <input
                    placeholder={placeHolder?.service_items?.count}
                    {...register(`count_${index}`, {
                      required: "Field is required",
                    })}
                  />
                </div>
                <hr />
              </div>
            ))}
            <button
              type="button"
              className="w-25 button-primary p-2 mb-3"
              onClick={handleAddPair}
            >
              Add More
            </button>
          </div>
          <Button
            label={isLoading ? "Submitting" : "Submit"}
            type="submit"
            className={`w-100 ${isLoading ? "bg-disabled" : ""}`}
            disabled={isLoading}
          />
        </form>
      </div>
    </div>
  );
};

export default ServiceCountSection;

