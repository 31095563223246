import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const useUpdateUserAccount = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false); // New state for submit success
  const token = useSelector((state) => {
    // console.log(state);
    return state.auth.user.accessToken;
  });

  const navigate = useNavigate();

  const {
    mutate: updateContent,

    isError,
  } = useMutation({
    mutationKey: ["updateInfluencerAccount"],
    mutationFn: async (formData) => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/auth/influencer/profile`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(formData),
          }
        );

        const data = await response.json();

        if (!response.ok) {
          const text = await response.json();
          if (text?.error?.status === 401) {
            navigate(authPrefixedRoutes.LOGIN);
          }
          throw new Error(data.error.message);
        }
        setSubmitSuccess(true);
        toast.success("Form Submit Successful");
        return data;
      } catch (error) {
        // Set login error state
        setError(error);
        throw error; // Re-throw the error to be caught by onError
      } finally {
        setIsLoading(false);
      }
    },
    onError: (error) => {
      setError(error);
      throw error; // Re-throw the error to be caught by onError
    },
  });

  return {
    updateContent,
    isLoadingUpdate: isLoading,
    isErrorUpdate: isError,
    error,
    submitSuccess,
  }; // Return submitSuccess
};

export default useUpdateUserAccount;
