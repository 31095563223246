import AdvertiserSideBar from "./AdvertiserSideBar";
import AdvertiserTopBar from "./AdvertiserTopBar";
import "../../../../styles/innerPanel.css";

const AdvertiserLayout = ({ children }) => {
  return (
    <div className="inner-panel d-flex vh-100 overflow-hidden bg-Advertiser-gradient">
      <AdvertiserSideBar />
      <div className="position-relative d-flex flex-1 flex-column overflow-y-auto overflow-x-hidden w-100 custom-scroll">
        <AdvertiserTopBar />
        <main>
          <div className="mx-auto pt-4 pb-4 ">{children}</div>
        </main>
      </div>
    </div>
  );
};

export default AdvertiserLayout;
