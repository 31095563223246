import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { StarIcon } from "../../../../utils/MyIcons";
import useLabDetails from "../../../../hooks/data-hooks/admin/lab-management/useLabDetails";
import { Link, useParams } from "react-router-dom";
import MyImage from "../../../../custom-components/MyImage";
import { Str } from "@jimuelpalaca/str";
import ProfileBarShimmer from "../../../common/components/shimmers/ProfileBarShimmer";
import useGetOrderListOfLab from "../../../../hooks/data-hooks/admin/lab-management/useGetOrderListOfLab";
import DataTable from "react-data-table-component";
import Loader from "../../../common/components/loader/Loader";
import { Rating } from "react-simple-star-rating";
import influencerImg from "../../../../assets/image/packages/influencer.jpg";
import BackButton from "../../../../helpers/backButton";
import adminPrefixedRoutes from "../../../../routes/all-routes/adminRoutes";

const InfluencerDetails = () => {
  const [totalRowsOrder, setTotalRowsOrder] = useState(0);
  const [totalRowsProduct, setTotalRowsProduct] = useState(0);

  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  let { id } = useParams();


  const {
    fetchLabDetailsWithId,
    isLoadingLabDetails,
    isError,
    labDetails,
    labDetailsError,
    productList,
    fetchProductListWithId,
  } = useLabDetails(id, perPage, currentPage);

  const { fetchOrderListOfLab, isLoading, orderList } = useGetOrderListOfLab(
    id,
    perPage,
    currentPage
  );

  const [tabNum, setTabNum] = useState(1);

  // console.log("orderList", orderList);
  // console.log("productList", productList);

  useEffect(() => {
    setTotalRowsOrder(orderList?.meta?.pagination?.total_count);
  }, [orderList]);

  useEffect(() => {
    setTotalRowsProduct(productList?.meta?.pagination?.total_count);
  }, [productList]);

  // Slice data array based on current page and items per page
  // const paginatedData = clinicTotalOrderData.slice(
  //   currentPage * perPage,
  //   (currentPage + 1) * perPage
  // );

  // Function to handle page change
  // const handlePageClick = ({ selected }) => {
  //   setCurrentPage(selected);
  // };

  // -- real Logic

  // console.log(productList);

  useEffect(() => {
    fetchLabDetailsWithId(id, perPage, currentPage); // Fetch lab details when labId changes

    fetchProductListWithId(id, perPage, currentPage);
  }, [id, perPage, currentPage]);

  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#F5FAFF",
      },
    },
  };

  const handlePageChange = (page) => {
    fetchProductListWithId(id, perPage, page);
    fetchOrderListOfLab(perPage, page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchProductListWithId(id, newPerPage, page);
    fetchOrderListOfLab(newPerPage, page);
    setCurrentPage(page);
    setPerPage(newPerPage);
  };

  const columnsOrder = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    // {
    //   name: "#",
    //   selector: (row, index) =>
    //     (data?.meta?.pagination?.current_page - 1) * perPage + index + 1,
    //   sortable: true,
    // },
    {
      name: "Order Id",
      selector: (row) => (
        <Link to={`/admin/orders/${row._id}`} className="link-style">
          {row?.orderNumber}
        </Link>
      ),
      sortable: true,
    },
    {
      name: "Ordered By",
      selector: (row) => {
        return row?.advertiserId?.email;
      },

      sortable: true,
    },
    {
      name: "Advertiser",
      selector: (row) => {
        return row?.advertiserId?.fullName;
      },
      sortable: true,
    },
    {
      name: "Order Status",
      selector: (row) => {
        return row?.orderStatus;
      },
      sortable: true,
    },
    {
      name: "Payment Status",
      selector: (row) => {
        return row.paymentStatus;
      },
      sortable: true,
    },
    // {
    //   name: "Delivery Status",
    //   selector: (row) => {
    //     return row.deliveryStatus;
    //   },
    //   sortable: true,
    // },
    // {
    //   name: "Messages",
    //   cell: (row) => (
    //     <Link to={`${adminPrefixedRoutes.MESSAGE}/${row?._id}`} className="">
    //       <MessageBadge messageCount={row.totalMessages} />
    //     </Link>
    //   ),
    // },
  ];

  const columnsOfProductList = [
    {
      name: "#",
      selector: (row, index) =>
        (productList?.meta?.pagination?.current_page - 1) * perPage + index + 1,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => (
        <Link
          to={`${adminPrefixedRoutes.PACKAGES}/${row?._id}`}
          className="link-style"
        >
          {row?.packageName}
        </Link>
      ),
      sortable: true,
    },
    {
      name: "Platform",
      selector: (row) => row?.socialPlatformName,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row?.typeOfPackage,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => row?.price,
      sortable: true,
    },

    {
      name: "Status",
      selector: (row) => (row?.isEnable === true ? "Active" : "Inactive"),
      sortable: true,
    },
  ];
  console.log("labDetails", labDetails);

  if (isLoading || isLoadingLabDetails) return <Loader />;
  return (
    <div className="container ">
      <BackButton />
      <h5>
        Influencer Profile :{" "}
        {!isLoadingLabDetails && labDetails
          ? labDetails?.userInfo?.fullName
          : "Loading..."}
      </h5>

      <div className="d-flex flex-column flex-lg-row gap-3 ">
        {/* Profile */}
        <div className="bg-white rounded-3 p-4 w-lg-25 ">
          {(isLoadingLabDetails || isError) && <ProfileBarShimmer />}
          {!isLoadingLabDetails && !isError && labDetails && (
            <>
              <div className="text-center">
                <MyImage
                  src={labDetails?.userInfo?.profileImageUrl}
                  addBaseUrl={false}
                  alt=""
                  className="w-h-130px rounded-circle border border-5  border-primary-color my-3"
                />
                <div className="mt-2 vstack gap- ">
                  <span className="fw-bold text-title ">
                    {labDetails?.userInfo?.fullName ?? "Name unavailable"}
                  </span>
                  <span className="fs-14 ">
                    {labDetails?.userInfo?.phoneNumber ??
                      "Phone number  unavailable"}
                  </span>
                  <span className="fs-14 ">
                    Status :{" "}
                    {Str.snakeToWord(labDetails?.userInfo?.status ?? "unknown")}
                  </span>
                </div>
              </div>
              <hr />
              <div className="d-flex fs-12 ">
                <div className="d-flex flex-column w-50 gap-2 ">
                  <span>Followers</span>
                  <span>Clinic Ratings</span>
                  <span>Total Feedback</span>
                </div>
                <div className="d-flex flex-column gap-2">
                  <span>: 2.5 K</span>
                  <span>
                    : <Rating initialValue={4} readonly size={15} />
                    &nbsp;(4)
                  </span>
                  <span>: 2167</span>
                </div>
              </div>
              <hr />
              <p className="fs-12">
                A passionate content creator with a growing following, I
                specialize in [niche/topic] and engage my audience through
                authentic, relatable posts. With a focus on [platforms like
                Instagram, YouTube, TikTok], I partner with brands to deliver
                impactful campaigns that resonate with my community, driving
                both engagement and results.
              </p>
              <hr />
              <p className="fs-12">
                1234 Market Street,
                <br /> Suite 500 City,
                <br /> State,
                <br /> ZIP Code Country <br />
                <br />
                {labDetails?.userInfo?.email} <br />
                Website: influercer.com
              </p>
              <img src={influencerImg} alt="" className="w-100 rounded-3 " />
            </>
          )}
        </div>
        {/* Table */}
        <div className="flex-grow-1 ">
          <ul className="nav nav-tabs">
            <li className="nav-item" onClick={() => setTabNum(1)}>
              <a
                className={`nav-link ${tabNum === 1 && "active"}`}
                aria-current="page"
              >
                Orders
              </a>
            </li>
            <li className="nav-item" onClick={() => setTabNum(2)}>
              <a className={`nav-link ${tabNum === 2 && "active"}`}>Packages</a>
            </li>
          </ul>
          {/* Order Table */}
          <div className={`${tabNum !== 1 && "d-none"}`}>
            <h5 className="bg-primary-color p-4 text-white rounded-top-3 mb-0 ">
              Total Orders Information
            </h5>
            <div className="p-4 bg-white rounded-bottom-3 table-responsive vstack gap-4">
              <DataTable
                // title={headerComponent}
                customStyles={tableHeaderstyle}
                columns={columnsOrder}
                data={orderList?.data}
                pagination
                paginationServer
                paginationTotalRows={totalRowsOrder}
                paginationDefaultPage={currentPage}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                fixedHeader
                selectableRowsHighlight
                highlightOnHover
              />
            </div>
          </div>
          {/* Package Table */}
          <div className={`${tabNum !== 2 && "d-none"}`}>
            <h5 className="bg-primary-color p-4 text-white rounded-top-3 mb-0 ">
              Total Packages Information
            </h5>
            <DataTable
              // title={headerComponent}
              customStyles={tableHeaderstyle}
              columns={columnsOfProductList}
              data={productList?.data}
              pagination
              paginationServer
              paginationTotalRows={totalRowsProduct}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              fixedHeader
              selectableRowsHighlight
              highlightOnHover
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default InfluencerDetails;
