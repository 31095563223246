import { useLocation, Link } from "react-router-dom";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import useLogout from "../../../../hooks/other-hooks/useLogout";

import DashboardIcon, {
  ArrowIcon,
  LogoutIcon,
  TransactionIcon,
  CloseIcon,
  HamburgerIcon,
  BookingIcon,
  UserManagementIcon,
  BellIcon,
  SettingsNewIcon,
  MessageWhiteIcon,
  OrderIcon,
  OrdersIcon,
  OrdersRoundedIcon,
  LabIcon,
  AnalyticsIcon,
  ReportsIcon,
} from "../../../../utils/MyIcons";
import { useEffect, useState } from "react";
import InfluencerSidenavProfile from "./layout-partials/influencer-sidenav/InfluencerSidenavProfile";
import InfluencerPrefixedRoutes, {
  influencerRoutes,
} from "../../../../routes/all-routes/influencerRoutes";

const InfluencerSideBar = () => {
  const handleLogout = useLogout();
  const location = useLocation();
  const [open, setOpen] = useState("");
  const [sidebarVisible, setSidebarVisible] = useState(true); // State to manage sidebar visibility

  // Function to toggle sidebar visibility
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  // Function to detect screen size changes and show/hide sidebar accordingly
  useEffect(() => {
    const handleResize = () => {
      const isSmallScreen = window.innerWidth < 768; // Adjust breakpoint as needed
      if (isSmallScreen) {
        setSidebarVisible(false); // Hide sidebar on small screens
      } else {
        setSidebarVisible(true); // Show sidebar on large screens
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call the function initially
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleOpenSubMenu = (key) => {
    if (open === key) {
      setOpen("");
    } else {
      setOpen(key);
    }
  };

  return (
    <div className="text-white  d-flex  ">
      <Sidebar
        backgroundColor="rgba(0,0,0,0)"
        rootStyles={{
          background: "var(--primary-dashboard)",
          display: sidebarVisible ? "block" : "none", // Hide or show sidebar based on state
        }}
        className="sidebar-content" // Add a class for event handling
      >
        <div className="">
          <InfluencerSidenavProfile />

          {/* Menu */}
          <div className=" sideBar-menu custom-scroll">
            <Menu
              transitionDuration={800}
              renderExpandIcon={({ open }) => (
                <span className={open ? "arrow-collapsed" : "arrow-expanded"}>
                  <ArrowIcon />
                </span>
              )}
              menuItemStyles={{
                button: ({ level, active }) => {
                  // only apply styles on first level elements of the tree
                  if (level === 0)
                    return {
                      color: active ? "white" : "var(--text-menu-color)",

                      backgroundColor: active
                        ? "var(--focus-color)"
                        : "var(--primary-Dashboard)",
                      transition: "background-color .3s ease-in-out",
                      "&:hover": {
                        backgroundColor: "var(--focus-color)",
                      },
                      marginLeft: 12,
                      marginRight: 12,
                      marginBottom: 6,
                      borderRadius: 6,
                      padding: 6,
                    };
                  // only apply styles on second level elements of the tree
                  if (level === 1)
                    return {
                      color: active ? "white" : "var(--text-menu-color)",

                      backgroundColor: active
                        ? "var(--focus-color)"
                        : "var(--sub-menu-color)",
                      transition: "background-color .3s ease-in-out",
                      "&:hover": {
                        backgroundColor: "var(--focus-color)",
                      },
                      padding: 18,
                    };
                },
              }}
            >
              {/* Dashboard */}
              <MenuItem
                onClick={() => handleOpenSubMenu("")}
                active={location.pathname.includes(influencerRoutes.DASHBOARD)}
                className={` ${
                  location.pathname.includes(influencerRoutes.DASHBOARD)
                    ? ""
                    : "menu-svg"
                }`}
                icon={<DashboardIcon />}
                component={<Link to={InfluencerPrefixedRoutes.DASHBOARD} />}
              >
                Dashboard
              </MenuItem>
              {/* Orders */}
              <MenuItem
                onClick={() => handleOpenSubMenu("")}
                active={location.pathname.includes(influencerRoutes.ORDERS)}
                className={` ${
                  location.pathname.includes(influencerRoutes.ORDERS)
                    ? ""
                    : "menu-svg"
                }`}
                icon={<OrdersRoundedIcon />}
                component={<Link to={InfluencerPrefixedRoutes.ORDERS} />}
              >
                Orders
              </MenuItem>
              {/* Feature */}
              <MenuItem
                onClick={() => handleOpenSubMenu("")}
                active={location.pathname.includes(influencerRoutes.FEATURE)}
                className={` ${
                  location.pathname.includes(influencerRoutes.FEATURE)
                    ? ""
                    : "menu-svg"
                }`}
                icon={<BookingIcon />}
                component={<Link to={InfluencerPrefixedRoutes.FEATURE} />}
              >
                Featured
              </MenuItem>
              {/* Packages */}
              <MenuItem
                onClick={() => handleOpenSubMenu("")}
                active={location.pathname.includes(influencerRoutes.PACKAGES)}
                className={` ${
                  location.pathname.includes(influencerRoutes.PACKAGES)
                    ? ""
                    : "menu-svg"
                }`}
                icon={<UserManagementIcon />}
                component={<Link to={InfluencerPrefixedRoutes.PACKAGES} />}
              >
                Packages
              </MenuItem>
              {/* Social Profiles */}
              <MenuItem
                onClick={() => handleOpenSubMenu("")}
                active={location.pathname.includes(
                  influencerRoutes.SOCIAL_PROFILES
                )}
                className={` ${
                  location.pathname.includes(influencerRoutes.SOCIAL_PROFILES)
                    ? ""
                    : "menu-svg"
                }`}
                icon={<ReportsIcon />}
                component={
                  <Link to={InfluencerPrefixedRoutes.SOCIAL_PROFILES} />
                }
              >
                Social Profiles
              </MenuItem>
              {/*   Message */}
              <MenuItem
                onClick={() => handleOpenSubMenu("")}
                active={location.pathname.includes(
                  InfluencerPrefixedRoutes.MESSAGE
                )}
                className={` ${
                  location.pathname.includes(InfluencerPrefixedRoutes.MESSAGE)
                    ? ""
                    : "menu-svg"
                }`}
                icon={<MessageWhiteIcon />}
                component={<Link to={InfluencerPrefixedRoutes.MESSAGE} />}
              >
                Message
              </MenuItem>
              {/* Transactions */}
              <MenuItem
                onClick={() => handleOpenSubMenu("")}
                active={location.pathname.includes(
                  InfluencerPrefixedRoutes.TRANSACTION
                )}
                className={` ${
                  location.pathname.includes(
                    InfluencerPrefixedRoutes.TRANSACTION
                  )
                    ? ""
                    : "menu-svg"
                }`}
                icon={<TransactionIcon />}
                component={<Link to={InfluencerPrefixedRoutes.TRANSACTION} />}
              >
                Transaction
              </MenuItem>{" "}
              {/* Settings */}
              <MenuItem
                onClick={() => handleOpenSubMenu("")}
                active={location.pathname.includes(
                  influencerRoutes.INFLUENCER_SETTINGS
                )}
                className={` ${
                  location.pathname.includes(
                    influencerRoutes.INFLUENCER_SETTINGS
                  )
                    ? ""
                    : "menu-svg"
                }`}
                icon={<SettingsNewIcon />}
                component={
                  <Link to={InfluencerPrefixedRoutes.INFLUENCER_SETTINGS} />
                }
              >
                Settings
              </MenuItem>
              {/* Ad Service */}
              {/* <MenuItem
                onClick={() => handleOpenSubMenu("")}
                active={location.pathname.includes(influencerRoutes.AD_SERVICE)}
                className={` ${
                  location.pathname.includes(influencerRoutes.AD_SERVICE)
                    ? ""
                    : "menu-svg"
                }`}
                icon={<UserManagementIcon />}
                component={<Link to={InfluencerPrefixedRoutes.AD_SERVICE} />}
              >
                Ad Service
              </MenuItem> */}
              {/* Calender */}
              {/* <MenuItem
                onClick={() => handleOpenSubMenu("")}
                active={location.pathname.includes(influencerRoutes.CALENDER)}
                className={` ${
                  location.pathname.includes(influencerRoutes.CALENDER)
                    ? ""
                    : "menu-svg"
                }`}
                icon={<BookingIcon />}
                component={<Link to={InfluencerPrefixedRoutes.CALENDER} />}
              >
                Calender
              </MenuItem> */}
              {/* Earnings Overview */}
              {/* <MenuItem
                onClick={() => handleOpenSubMenu("")}
                active={location.pathname.includes(
                  influencerRoutes.EARNINGS_OVERVIEW
                )}
                className={` ${
                  location.pathname.includes(influencerRoutes.EARNINGS_OVERVIEW)
                    ? ""
                    : "menu-svg"
                }`}
                icon={<TransactionIcon />}
                component={
                  <Link to={InfluencerPrefixedRoutes.EARNINGS_OVERVIEW} />
                }
              >
                Earnings Overview
              </MenuItem> */}
              {/* Feature Request */}
              {/* <MenuItem
                onClick={() => handleOpenSubMenu("")}
                active={location.pathname.includes(
                  influencerRoutes.FEATURE_REQUEST
                )}
                className={` ${
                  location.pathname.includes(influencerRoutes.FEATURE_REQUEST)
                    ? ""
                    : "menu-svg-3"
                }`}
                icon={<BellIcon />}
                component={
                  <Link to={InfluencerPrefixedRoutes.FEATURE_REQUEST} />
                }
              >
                Feature Request
              </MenuItem> */}
            </Menu>
          </div>

          {/* Logout */}
          <div className=" bg-primary-color-dark mt-120px">
            <hr className="m-0 opacity-100 " />
            <div className="d-flex justify-content-center align-items-end m-5">
              <div
                onClick={handleLogout}
                className="cursor-pointer text-decoration-none text-white d-flex gap-3 align-items-center justify-content-center "
              >
                <LogoutIcon /> <span>Logout</span>
              </div>
            </div>
          </div>
        </div>
      </Sidebar>

      {/* Hamburger icon for small screens */}
      <div
        className="d-block d-md-none ms-1  cursor-pointer   z-index-10  "
        onClick={() => toggleSidebar()}
      >
        {sidebarVisible ? <CloseIcon /> : <HamburgerIcon />}
      </div>
    </div>
  );
};
export default InfluencerSideBar;
