import StatsCard from "./StatsCard";
import useGetTransactionList from "../../../../hooks/data-hooks/admin/transaction/useGetTransactionList";
import { DotLoader } from "react-spinners";
import { useEffect } from "react";
const Stats = () => {
  // Fetch transaction list with params
  const {
    historyForDashBoard,
    isLoadingTransactionList,
    fetchTransactionListWithParams,
  } = useGetTransactionList({});

  useEffect(() => {
    fetchTransactionListWithParams({}, []);
  }, []);

  if (isLoadingTransactionList) {
    return (
      <div className="d-flex align-items-center justify-content-center gap-4 bg-white w-100 h-100">
        <DotLoader size={60} color="#df3459" />
      </div>
    );
  }

  return (
    <div className="d-flex flex-wrap  bg-white rounded-4 border h-100   ">
      <StatsCard
        title="Pending"
        sales={historyForDashBoard?.totalPendingTransactions}
      />
      <StatsCard title="Payout" sales={historyForDashBoard?.totalPayout} />
      <StatsCard
        title="Completed"
        sales={historyForDashBoard?.totalPaymentCompleted}
      />
      <StatsCard
        title="Transactions"
        sales={historyForDashBoard?.totalCompletedTransactions}
      />
    </div>
  );
};
export default Stats;
