import React from "react";

const PageHeader = ({ title, subTitle, header }) => {
  return (
    <div className="mw-100 page-header-container">
      <div
        className={` p-5 max-width mx-auto  color-title-color d-flex align-items-center justify-content-center flex-column`}
      >
        <span className="fw-bold">{title}</span>
        <h2 className="mt-4 mb-4 fw-semibold fs-1 text-center text-white">{header}</h2>
        <span className="text-white">{subTitle}</span>
      </div>
    </div>
  );
};

export default PageHeader;
