import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";
import { useNavigate } from "react-router-dom";

const useLabDetails = (initialLabId, perPage, currentPage) => {
  const token = useSelector((state) => state.auth.user.accessToken);

  const [labDetails, setLabDetails] = useState({});
  const [labDetailsError, setLabDetailsError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [productList, setProductList] = useState();

  const navigate = useNavigate();

  const fetchLabDetails = useQuery({
    queryKey: ["labDetails", initialLabId],
    queryFn: async () => {
      try {
        console.log("initialLabId", initialLabId);
        setIsLoading(true);
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/admin/influencer/${initialLabId}`;
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });


        if (!response.ok) {
          const text = await response.json();
          if (text?.error?.status === 401) {
            navigate(authPrefixedRoutes.LOGIN);
          }
          const text1 = await response.text();
          setLabDetailsError(text1);
          setIsLoading(false);
          throw new Error("Failed to fetch lab details");
        }

        const responseData = await response.json();
        console.log("response", responseData);
        setLabDetails(responseData); // Assuming responseData contains lab details
        setIsLoading(false);

        return responseData;
      } catch (error) {
        setLabDetailsError(error);
        setIsLoading(false);
        throw error;
      }
    },
    onError: (error) => {
      setLabDetailsError(error);
      setIsLoading(false);
      throw error;
    },
  });

  const fetchProductList = useQuery({
    queryKey: ["getproductlist", initialLabId],
    queryFn: async () => {
      try {
        setIsLoading(true);
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/admin/package/all?influencerId=${initialLabId}`;
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          const text = await response.json();
          if (text?.error?.status === 401) {
            navigate(authPrefixedRoutes.LOGIN);
          }
          const text1 = await response.text();
          setLabDetailsError(text1);
          setIsLoading(false);
          throw new Error("Failed to fetch package data");
        }

        const responseData = await response.json();
        // console.log("response", responseData);
        setProductList(responseData); // Assuming responseData contains lab details
        setIsLoading(false);

        return responseData;
      } catch (error) {
        setLabDetailsError(error);
        setIsLoading(false);
        throw error;
      }
    },
    onError: (error) => {
      setLabDetailsError(error);
      setIsLoading(false);
      throw error;
    },
  });

  const fetchLabDetailsWithId = () => {
    fetchLabDetails.refetch();
  };

  const fetchProductListWithId = (id, perPage, currentPage) => {
    fetchProductList.refetch(id, perPage, currentPage);
  };
  useEffect(() => {
    fetchProductListWithId(initialLabId, perPage, currentPage);
  }, []);

  return {
    fetchLabDetailsWithId,
    isLoadingLabDetails: isLoading,
    isError: fetchLabDetails.isError,
    labDetails,
    labDetailsError,
    fetchProductList,
    fetchProductListWithId,
    productList,
  };
};

export default useLabDetails;
