// InfluencerAuthGuard.js
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { authPrefixedRoutes } from "../../routes/all-routes/authRoutes";
import useRefreshToken from "../../hooks/data-hooks/auth/useRefreshToken";

const InfluencerAuthGuard = ({ children }) => {
  const navigate = useNavigate();
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const { refreshToken, refreshSuccess } = useRefreshToken();

  useEffect(() => {
    const checkTokenAndUpdate = async (remainingTime) => {
      if (remainingTime < 10) {
        try {
          refreshToken({ refreshToken: user.refreshToken });
        } catch (error) {
          console.error("Token refresh error:", error);
        }
      }
    };

    const intervalId = setInterval(() => {
      if (isAuthenticated && user) {
        const decodedToken = jwtDecode(user.accessToken);
        const currentTime = Date.now() / 1000;
        const remainingTime = decodedToken.exp - currentTime;
        checkTokenAndUpdate(remainingTime);
      }
    }, 1000); // Check every second

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [isAuthenticated, user, navigate, refreshToken, refreshSuccess]);

  useEffect(() => {
    if (!isAuthenticated) {
      toast.error("You are not logged in");
      navigate(authPrefixedRoutes.LOGIN);
      return;
    }
    if (user?.panel !== "influencer") {
      toast.error("You do not have Influencer privileges");
      navigate(authPrefixedRoutes.LOGIN);
    }
  }, [isAuthenticated, user, navigate, refreshToken, refreshSuccess]);

  return isAuthenticated && user && user.panel === "influencer" ? children : null;
};

export default InfluencerAuthGuard;
