import React from 'react';

const Notifications = () => {
    return (
        <div>
            <h5>notification 1</h5>
            <h5>notification 2</h5>
            <h5>notification 3</h5>
            <h5>notification 4</h5>
        </div>
    );
};

export default Notifications;