import { useState } from "react";
import { topClinicData } from "../../../../dummy-data/admin/pages/dashboard/dummy-data";
import TopClinicItem from "./TopClinicItem";
import { ArrowDownBlackIcon } from "../../../../utils/MyIcons";
import useClinicListLogic from "../../../../hooks/data-hooks/admin/clinic-management/useClinicListLogic";
import { useNavigate } from "react-router";
import adminPrefixedRoutes from "../../../../routes/all-routes/adminRoutes";
import { PuffLoader } from "react-spinners";

const TopClinic = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleViewAll = () => {
    setOpen(!open);
  return navigate(adminPrefixedRoutes.ADVERTISER);
  };

   const {
     register,
     pageSize,
     handleSubmit,
     onSubmit,
     isSubmitting,
     currentPage,
     setCurrentPage,
     handlePageClick,
     searchTerm,
     filterStatus,
     setFilterStatus,
     clinicList,
     isLoading,
     clinicListError,
     clinicListMeta,
     debouncedSubmit,
     clinicStates,
     handleVerifyClinic,
     handleActiveDeactiveClinic,
  } = useClinicListLogic();
  
  if (isLoading) {
    return (
      <div className="d-flex align-items-center justify-content-center gap-4 bg-white w-100 h-100">
        <PuffLoader size={60} color="#df3459" />
      </div>
    );
  }

  return (
    <div className="bg-white border rounded-4 vstack gap-0 h-100">
      <div className="d-flex justify-content-between align-items-center p-3 text-title ">
        <span className="text-title fw-bold">Advertiser List</span>
        <span onClick={() => handleViewAll()} className="fs-12 cursor-pointer">
          View all
          {/* <span
            className={`ms-2 ${open ? "arrow-collapsed" : "arrow-expanded"}`}
          >
            <ArrowDownBlackIcon />
          </span> */}
        </span>
      </div>
      <hr className="m-0 " />
      {/* <div className="d-flex justify-content-between align-items-center px-3 py-2 bg-primary-color text-light fw-900">
        <span className="fs-6">Influencer name</span>
        <span className="fs-6">Total Expense</span>
      </div> */}
      <div className="dashboard-card-list custom-scroll vstack gap-2 p-3 fs-14 fw-lighter">
        {clinicList.map((data) => {
          return <TopClinicItem key={data.id} data={data} />;
        })}
      </div>
    </div>
  );
};
export default TopClinic;
// import { useState } from "react";
// import { topClinicData } from "../../../../dummy-data/admin/pages/dashboard/dummy-data";
// import TopClinicItem from "./TopClinicItem";
// import { ArrowDownBlackIcon } from "../../../../utils/MyIcons";

// const TopClinic = () => {
//   const [open, setOpen] = useState(false);
//   const handleViewAll = () => {
//     setOpen(!open);
//   };
//   return (
//     <div className="bg-white border rounded-4   vstack gap-2 h-100">
//       <div className="d-flex justify-content-between align-items-center p-3 pb-0 text-title">
//         <span>Top Advertiser</span>
//         <span onClick={() => handleViewAll()} className="fs-12 cursor-pointer">
//           View all
//           <span
//             className={`ms-2 ${open ? "arrow-collapsed" : "arrow-expanded"}`}
//           >
//             <ArrowDownBlackIcon />
//           </span>
//         </span>
//       </div>
//       <hr className="m-0 " />
//       <div className="d-flex justify-content-between align-items-center px-3 fs-14 text-title bg-primary-color text-light">
//         <span className="fs-14">Advertiser name</span>
//         <span className="fs-14">Ratings</span>
//       </div>
//       <div className="dashboard-card-list custom-scroll vstack gap-2 p-3 pt-0 fs-14 fw-lighter">
//         {topClinicData.map((data) => {
//           return <TopClinicItem key={data.id} data={data} />;
//         })}
//       </div>
//     </div>
//   );
// };
// export default TopClinic;
