import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import advertiserPrefixedRoutes from "../../../../routes/all-routes/advertiserRoutes";
import { Helmet } from "react-helmet-async";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";
import { getFormattedDate } from "../../../../helpers/getFormattedDate";
import { IoIosArrowDropdown } from "react-icons/io";

const OrderTracking = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.user.accessToken);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [expandedRow, setExpandedRow] = useState(null); // Track which row is expanded

  const getOrder = async (page, size = perPage) => {
    try {
      const req = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/advertiser/order/list?pageSize=${size}&pageNumber=${page}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const res = await req.json();
      if (res?.error?.status === 401) {
        navigate(authPrefixedRoutes.LOGIN);
      }
      setData(res);
      setTotalRows(res.meta.pagination.total_count);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setTotalRows(data?.meta?.pagination?.total_count);
  }, [data]);

  useEffect(() => {
    setLoading(true);
    getOrder(1);
    setLoading(false);
  }, []);

  const handleTrackViewClick = (rowId) => {
    setExpandedRow(expandedRow === rowId ? null : rowId); // Toggle row expansion
  };

  const columns = [
    {
      name: "Order Id",
      selector: (row) => <span>{row?.orderNumber}</span>,
      sortable: true,
    },
    {
      name: "Influencer",
      selector: (row) => {
        return row?.influencerId?.fullName;
      },
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => {
        return getFormattedDate(row?.createdAt);
      },
      sortable: true,
    },
    {
      name: "Order Status",
      selector: (row) => {
        return row?.orderStatus;
      },
      sortable: true,
    },
    {
      name: "Payment Status",
      selector: (row) => {
        return row?.paymentStatus;
      },
      sortable: true,
    },
    {
      name: "Track",
      selector: (row) => {
        const isRowExpanded = expandedRow === row._id; // Check if this row is expanded
        return (
          <span
            className="btn d-flex align-items-center gap-1"
            onClick={() => handleTrackViewClick(row._id)} // Trigger expand/collapse
          >
            <span>Track View</span>
            {<IoIosArrowDropdown />} {/* Show icon only when expanded */}
          </span>
        );
      },
      sortable: true,
    },
  ];

  const handlePageChange = (page) => {
    getOrder(page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    getOrder(page, newPerPage);
    setPerPage(newPerPage);
  };

  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "var(--primary-color)",
        color: "#fff",
      },
    },
  };

  const ExpandedComponent = ({ data }) => (
    <div style={{ padding: "10px", backgroundColor: "#f5f5f5" }}>
      <h4>Order Steps</h4>
      
      <div className="stepper-container d-flex">
        {/* Demo Stepper */}
        <div className="step">
          <div className="step-number">1</div>
          <div className="step-description">Order Created</div>
        </div>
        <div className="step">
          <div className="step-number">2</div>
          <div className="step-description">Order Processed</div>
        </div>
        <div className="step">
          <div className="step-number">3</div>
          <div className="step-description">Order Shipped</div>
        </div>
        <div className="step">
          <div className="step-number">4</div>
          <div className="step-description">Order Delivered</div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="container ">
      <Helmet>
        <title>
          Order Placement | Advertiser | {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <div className="border shadow">
        <DataTable
          title="Order Tracking"
          customStyles={tableHeaderstyle}
          columns={columns}
          data={data.data}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          fixedHeader
          selectableRowsHighlight
          highlightOnHover
          expandableRows
          expandableRowsComponent={({ data }) => (
            <ExpandedComponent data={data} />
          )} // Expanded row content
          expandOnRowClicked
          expandableRowExpanded={(row) => expandedRow === row._id} // Manage row expansion
          expandableIcon={{ collapsed: <></>, expanded: <></> }} // Hide the automatic dropdown icon
        />
      </div>
    </div>
  );
};

export default OrderTracking;

// --------Raw code -------------------
// import React, { useEffect, useState, useMemo } from "react";
// import DataTable from "react-data-table-component";
// import { useSelector } from "react-redux";
// import { Link, useNavigate } from "react-router-dom";
// // import MessageBadge from "../message/MessageBadge";
// import advertiserPrefixedRoutes from "../../../../routes/all-routes/advertiserRoutes";
// import { Helmet } from "react-helmet-async";
// import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";
// import { getFormattedDate } from "../../../../helpers/getFormattedDate";
// import { IoIosArrowDropdown } from "react-icons/io";

// const OrderTracking = () => {
//   const navigate = useNavigate();
//   const token = useSelector((state) => state.auth.user.accessToken);
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [totalRows, setTotalRows] = useState(0);
//   const [perPage, setPerPage] = useState(10);
//   const [currentPage, setCurrentPage] = useState(1);

//   const getOrder = async (page, size = perPage) => {
//     try {
//       const req = await fetch(
//         `${process.env.REACT_APP_API_BASE_URL}/api/advertiser/order/list?pageSize=${size}&pageNumber=${page}`,
//         {
//           method: "GET",
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );
//       const res = await req.json();
//       if (res?.error?.status === 401) {
//         navigate(authPrefixedRoutes.LOGIN);
//       }
//       setData(res);
//       setTotalRows(res.meta.pagination.total_count);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     setTotalRows(data?.meta?.pagination?.total_count);
//   }, [data]);

//   useEffect(() => {
//     setLoading(true);
//     getOrder(1);
//     setLoading(false);
//   }, []);

//   const columns = [
//     // {
//     //   name: "#",
//     //   selector: (row, index) =>
//     //     (data?.meta?.pagination?.current_page - 1) * perPage + index + 1,
//     //   sortable: true,
//     // },
//     {
//       name: "Order Id",
//       selector: (row) => (
//         <span>{row?.orderNumber}</span>
//         // <Link
//         //   to={`${advertiserPrefixedRoutes.ORDER_TRACKING}/${row._id}`}
//         //   className="link-style"
//         // >
//         //   {row?.orderNumber}
//         // </Link>
//       ),
//       sortable: true,
//     },
//     // {
//     //   name: "Ordered By",
//     //   selector: (row) => {
//     //     return row?.advertiserId?.email;
//     //   },

//     //   sortable: true,
//     // },
//     {
//       name: "Advertiser",
//       selector: (row) => {
//         return row?.advertiserId?.fullName;
//       },
//       sortable: true,
//     },
//     {
//       name: "Date",
//       selector: (row) => {
//         return getFormattedDate(row?.createdAt);
//       },
//       sortable: true,
//     },
//     {
//       name: "Order Status",
//       selector: (row) => {
//         return row?.orderStatus;
//       },
//       sortable: true,
//     },
//     //   {
//     //     name: "Payment Status",
//     //     selector: (row) => {
//     //       return row.paymentStatus;
//     //     },
//     //     sortable: true,
//     // },

//     {
//       name: "Track",
//       selector: (row) => {
//         return (
//           <span className="btn d-flex align-items-center gap-1">
//             <span>Track View</span>
//             {/* Add dropdown */}
//             <IoIosArrowDropdown />
//           </span>
//         );
//       },
//       sortable: true,
//     },
//   ];

//   const handlePageChange = (page) => {
//     getOrder(page);
//     setCurrentPage(page);
//   };

//   const handlePerRowsChange = async (newPerPage, page) => {
//     getOrder(page, newPerPage);
//     setPerPage(newPerPage);
//   };

//   const tableHeaderstyle = {
//     headCells: {
//       style: {
//         fontWeight: "bold",
//         fontSize: "14px",
//         backgroundColor: "var(--primary-color)",
//         color: "#fff",
//       },
//     },
//   };

//   console.log(data.data);

//   return (
//     <div className="container ">
//       <Helmet>
//         <title>
//           Order Placement | Advertiser | {process.env.REACT_APP_NAME}
//         </title>
//       </Helmet>
//       <div className="border shadow  ">
//         <DataTable
//           title="Order Tracking"
//           customStyles={tableHeaderstyle}
//           columns={columns}
//           data={data.data}
//           progressPending={loading}
//           pagination
//           paginationServer
//           paginationTotalRows={totalRows}
//           paginationDefaultPage={currentPage}
//           onChangeRowsPerPage={handlePerRowsChange}
//           onChangePage={handlePageChange}
//           fixedHeader
//           selectableRowsHighlight
//           highlightOnHover
//         />
//       </div>
//     </div>
//   );
// };
// export default OrderTracking;
// -------------------------------------
